export enum UserNotificationType {
  USER_NETWORK = "networkRequest",
  TRADE_USER_CONNECTION = "TradeUserConnection",
  USER_REQUEST = "userRequest",
  PROJECT_CHAT = "ProjectChatRequest",
  PROPERTY_USER_CONNECTION = "PropertyUserConnection",
  VERIFY_JOBS = "historicalJob",
  CLIENT_HISTORICAL_PROJECT = "clientHistoricalJob",
  EXTERNAL_TRADE_NETWORK = "ExternalTradeNetwork",
  VIDEO_CALL = "videoCall",
  RAISE_PROJECT_TYPE = "RaiseProjectType",
  REJECTED_RAISE_PROJECT = "RejectRaiseProject",
  RAISE_NOTIFICATION = "RaiseNotification",
  RAISE_ISSUE_BY_OCCUPIER = "issueRaisedByOccupier",
  OUTERNETWORKREQUEST = "outerNetworkRequest",
  CONTRACTORINVITEREQUEST = "contractorInviteRequest",
  INSURANCEEXPIRATION = "insuranceExpiration",
  ASSIGN_TEAM_ESTIMATE = "assignTeamEstimate",
  WA_MEMBER_REQUEST = "wsMemberRequest",
  PHASE_REQUEST = "phaseRequest",
  ACCEPTWORKSTATIONMEMBERREQUEST = "acceptWorkStationMemberRequest",
  FIRSTTIMEWORKSTATIONLAUNCH = "firstTimeLaunchWorkstation",
  ACCEPTTRADENETWORKREQUEST = "acceptTradeNetworkRequest",
  ASSIGNEDCOURSE = "assignedCourse",
  PROJECTDELETED = "projectDeleted",
  UPDATE_PROJECT_ESTIMATE = "updateProjectEstimate",
  START_PROJECT_RIGHT_AWAY = "startProjectRightAway",
  ACCEPT_PROJECT_ESTIMATE = "acceptProjectEstimate",
  INVITECALENDAREVENT = "invitecalendarevent",
  TRADEUSERPROJECTACCEPTEDDIRECT = "TradeUserProjectAcceptedDirect",
  PROJECT_COMPLETED = "projectCompleted",
  ESTIMATEARRIVALS = "estimateArrivals",
  ESTIMATE_ACCEPTED_BY_PO = "estimateAcceptedByPO",
  REJECT_ESTIMATE_BY_PO_USER = "rejectEstimateByPOUser",
  VIDEO_CONSULTATION = "videoconsultation",
  INFORMATIVE = "informative",
  CLIENTORCONTRACTOR = "clientOrContractor",
  ACCOUNT_VERIFIED = "accountVerified",
  UPDATE_PROJECT_ESTIMATE_SUB_CONTRACTOR = "updateProjectEstimateSubContractor",
  KYC_STATUS_UPDATION = "kyc_status_updation",
  INSURANCE_ARCHIVED = "insurance_archived",
  RECOMMENDATION = "recommendation",
  SITE_VISIT_REQUEST = "siteVisitRequest",
  CLIENT_RAISE_ESTIMATE = "clientRaiseEstimate",
  DISPUTE_PROJECT_STATUS = "dispute_project_status",
  PROJECT_REFUNDS = "project_refunds",
  CHANGE_ESTIMATE_REQUEST = "change_estimate_request",
}

export enum UserNetworkRequestStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum ProjectMetaDataStatusEnum {
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  NOT_STARTED = 4,
}

export enum UserWorkstationSubStatusEnum {
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
  AMOUNT_UPDATED = "amount_capturable_updated",
  FAILED = "payment_failed",
}

export enum InvoiceItem {
  WORKSTATION = "workstation",
  MEMBERS = "members",
  STORAGE = "storage",
}
