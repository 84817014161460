<template>
  <div class="jobs_content md:!tw-pb-0 md:!tw-box-border">
    <div
      class="success-floater fixed tw-rounded-[6.03px] tw-border-[1.51px] tw-border-solid tw-border-[#24BD63] tw-h-[36px] tw-px-3 tw-flex tw-gap-2 tw-items-center tw-justify-center"
      :class="{ show: isShowProjectPostSuccessFloater }"
    >
      <CongratsIcon />
      <Text>Congratulations, Your Estimate Was Sent Successfully!</Text>
    </div>
    <v-tabs
      class="tab-list xxl:!tw-pr-4"
      v-model="tab"
      align-tabs="center"
      stacked
    >
      <v-tab
        v-for="tabItem in jobTabList"
        :key="tabItem.name"
        :value="tabItem.name"
        :disabled="!isWorkStationVerified && tabItem?.name !== 'find-jobs'"
      >
        <!-- <v-icon>{{ tabItem.icon }}</v-icon> -->
        <div class="tw-w-[16px] tw-h-[16px]">
          <img :src="tabItem.icon" alt="" />
        </div>
        {{ tabItem.label }}
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" :touch="false">
      <v-window-item
        class="xxs:!tw-pb-0 md:!tw-pb-0 md:!tw-box-border"
        v-for="tabValue in jobTabList"
        :key="tabValue.name"
        :value="tabValue.name"
      >
        <component :is="tabValue.component" v-if="tab === tabValue.name" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onBeforeMount, onMounted } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import JobsListView from "@/modules/jobs/components/JobsListView.vue";
import EstimateTemplatePage from "@/modules/jobs/pages/EstimateTemplatePage.vue";
import MyJobsListView from "@/modules/jobs/components/MyJobsListView.vue";
import DraftJobListView from "@/modules/jobs/components/DraftJobListView.vue";
import {
  JOBS_ROUTE,
  JOBS_ROUTE_WITH_TYPE,
  JOBS_WITH_SUCCESS_ESTIMATE_SENT_ROUTE,
} from "@/modules/jobs/routes";
import { useRoute } from "vue-router";
import Text from "@/core/components/ui/general/Text.vue";
import CongratsIcon from "@/core/components/icons/CongratsIcon.vue";

const store = useStore();
const route = useRoute();

const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const tab = ref();
const TEMPLATE = "estimate-template";
const FIND_JOBS = "find-jobs";
const MY_JOBS = "my-jobs";
const DRAFT_JOBS = "draft-jobs";
const REJECTED_JOBS = "rejected-jobs";

const isShowProjectPostSuccessFloater = ref(false);

const jobTabList = [
  {
    name: FIND_JOBS,
    icon: require("../assets/svg/search.svg"),
    label: "Find Jobs",
    component: JobsListView,
  },
  {
    name: MY_JOBS,
    icon: require("../assets/svg/projects.svg"),
    label: "My Jobs",
    component: MyJobsListView,
  },
  {
    name: DRAFT_JOBS,
    icon: require("../assets/svg/Draft.svg"),
    label: "Draft Jobs",
    component: DraftJobListView,
  },
  {
    name: REJECTED_JOBS,
    icon: require("../assets/svg/rejected_jobs.svg"),
    label: "Rejected Jobs",
    component: RejectedJobListView,
  },
  {
    name: TEMPLATE,
    icon: require("../assets/svg/template.svg"),
    label: "template",
    component: EstimateTemplatePage,
  },
];
const getJobRedirectTabName = computed(
  () => store.getters[`${JOBS_STORE}/getJobRedirectTabName`]
);

watch(
  getJobRedirectTabName,
  async (newValue: any, oldValue: any) => {
    tab.value = newValue;
  },
  { immediate: true }
);

const isWorkStationVerified = computed(() => {
  return (
    store.getters[`${WORKSTATION}/activeUserWorkstation`]
      ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
  );
});

const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

const onShowSuccessPostProjectFloater = async () => {
  isShowProjectPostSuccessFloater.value = true;

  await delay(3000);
  isShowProjectPostSuccessFloater.value = false;
};

onBeforeMount(() => {
  if (workFlowType.value) {
    tab.value = DRAFT_JOBS;
  }
  if (route.name === JOBS_ROUTE_WITH_TYPE) {
    if (route.params.type === "Draft Jobs") {
      tab.value = DRAFT_JOBS;
      return;
    }
    if (route.params.type === "Rejected") {
      tab.value = REJECTED_JOBS;
      return;
    }
    tab.value = MY_JOBS;
  }
});

onMounted(async () => {
  console.log(route, "route in jobs");
  console.log(JOBS_WITH_SUCCESS_ESTIMATE_SENT_ROUTE, "route names");
  if (route.name === JOBS_WITH_SUCCESS_ESTIMATE_SENT_ROUTE) {
    onShowSuccessPostProjectFloater();
  }
  // const routeData = router.resolve({
  //   name: "project-details",
  //   params: { refId: ProjectData.value.refId },
  // });
  // window.open(window.location.href, "_blank");
});
</script>

<script lang="ts">
import { defineComponent } from "vue";

import RejectedJobListView from "@/modules/jobs/components/RejectedJobListView.vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOBS_STORE } from "@/store/modules/jobs";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import { watch } from "vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";

export default defineComponent({
  name: "JobsPage",
});
</script>

<style lang="scss" scoped>
@import "@/modules/jobs/styles/jobs.scss";

.success-floater {
  transition: top 0.3s ease-in-out;
  box-shadow: 0px 3px 15.4px 0px #00000029;
  width: 448px !important;
  top: -41px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 99;

  @media screen and (max-width: 768px) {
    width: 373px !important;
  }
}

.success-floater.show {
  top: 3.5rem !important;
}
</style>
