<template>
  <section
    class="tw-w-full tw-flex tw-flex-col tw-gap-4"
    :class="{ 'md:!tw-pb-16 md:!tw-box-border': tableType === 'files' }"
  >
    <div class="tw-w-full tw-flex tw-items-start">
      <Text variant="p" :isCapitalize="true">{{ tableType }}</Text>
    </div>
    <v-table
      class="tw-pt-8 tw-pb-4 tw-px-5 tw-box-border tw-rounded-lg md:!tw-pt-0"
    >
      <thead>
        <tr>
          <th class="text-left tw-w-[40%] sm:tw-w-full">
            <Text variant="p">Name</Text>
          </th>
          <th class="text-left tw-w-[150px] sm:tw-hidden">
            <Text variant="p" whiteSpace="nowrap">Owner</Text>
          </th>
          <th class="text-left tw-w-[150px] sm:tw-hidden">
            <Text variant="p" whiteSpace="nowrap">Last updated</Text>
          </th>
          <th
            class="text-left tw-w-[150px] sm:tw-hidden"
            v-if="tableType !== 'files'"
          >
            <Text variant="p" whiteSpace="nowrap">Files</Text>
          </th>
          <th class="text-left tw-w-[150px] sm:tw-hidden">
            <Text variant="p" whiteSpace="nowrap">Size</Text>
          </th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in state.list"
          :key="index"
          class="tw-cursor-pointer"
          :class="{
            'tw-bg-blue-100':
              state.selectedRow === item.id && state.selectedRow !== null,
          }"
          @click="onHandleClickRow($event, item)"
        >
          <td class="text-left sm:tw-w-full">
            <div class="tw-flex tw-items-center tw-gap-2">
              <DocumentFolderIcon
                class="md:!tw-min-w-[24px]"
                v-if="item?.isDir"
                :fill="item?.color"
              />
              <v-icon
                icon="mdi-file-video"
                color="#264FD5 md:!tw-min-w-[24px]"
                v-if="!item?.isDir && item?.type === 'video'"
              ></v-icon>
              <PdfIcon
                class="md:!tw-min-w-[24px]"
                v-if="!item?.isDir && item?.type === 'pdf'"
              />
              <ImageIcon
                class="md:!tw-min-w-[24px]"
                v-if="!item?.isDir && item?.type === 'image'"
              />
              <FileIcon
                class="tw-max-h-[23px] tw-max-w-[30px] md:!tw-min-w-[24px]"
                v-if="!item?.isDir && item?.type === 'word'"
              />
              <!-- <MicrosoftExcelIcon v-if="!item.isDir && item.type === 'excel'" /> -->
              <Text
                variant="p"
                class="md:!tw-text-[12px] md:!tw-leading-[16px]"
                >{{
                  item?.originalName ? item?.originalName : item?.name
                }}</Text
              >
            </div>
            <p
              class="tw-text-[10px] tw-font-normal tw-leading-[12px] tw-tracking-[0.25px] tw-text-[#0C0F4A80] tw-ml-8 tw-hidden sm:tw-block"
            >
              {{ item?.updated }}
            </p>
          </td>
          <td class="text-left sm:tw-hidden">
            <Text variant="p">{{
              item?.isSharedWithMe ? item?.user?.firstName : "Me"
            }}</Text>
          </td>
          <td class="text-left sm:tw-hidden">
            <Text variant="p">{{ item?.updated }}</Text>
          </td>
          <td class="text-left sm:tw-hidden" v-if="tableType !== 'files'">
            <Text variant="p">{{ item?.fileCount || "--" }}</Text>
          </td>
          <td class="text-left sm:tw-hidden">
            <Text variant="p">{{ item?.size }}</Text>
          </td>
          <td class="text-right">
            <v-menu :close-on-content-click="false" location="bottom">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-if="getUserWorkStationFieldAccess"
                  v-bind="props"
                  icon="mdi-dots-horizontal"
                  color="rgba(12, 15, 74, 0.5)"
                ></v-icon>
              </template>
              <DocumentActionMenu
                :downloadClickLoader="downloadClickLoader"
                :isPropertyDocument="isPropertyDocument"
                :tableType="tableType"
                @on-handle-action="onHandleAction($event, item)"
              />
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-table>

    <!-- MODALS -->
    <RenameDocumentModal
      v-if="modals.renameDocument"
      :selectedDocument="state.selectedDocument"
      :renameType="tableType"
      @on-close="onCloseRenameDocumentModal"
      @on-success="onSuccess('edit')"
      :isPropertyDocument="isPropertyDocument"
    />
    <DocumentDetailsModal
      v-if="modals.documentDetails"
      :selectedDocument="state.selectedDocument"
      :documentType="tableType"
      @on-close="onCloseDocumentDetailsModal"
    />
    <DocumentDeleteConfirmationModal
      v-if="modals.deleteConfirmation"
      @on-close="onCloseDeleteConfirmationModal"
      :selectedDocument="state.selectedDocument"
      :deleteType="tableType"
      @on-success="onSuccess"
    />

    <ShareDocumentModal
      v-if="modals.shareModal"
      :selectedDocument="state.selectedDocument"
      @on-close="onCloseShareModal"
      :isPropertyDocument="isPropertyDocument"
    />

    <MoveDocumentsModal
      v-if="modals.moveModal"
      @on-close="onCloseMoveModal"
      @on-success-move="onSuccess"
      :selectedDocument="state.selectedDocument"
      :documentType="tableType"
      :isPropertyDocument="isPropertyDocument"
    />
  </section>
</template>
<script setup>
import { computed, onMounted, reactive, watch, nextTick } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import DocumentFolderIcon from "@/core/components/icons/DocumentFolderIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
// import MicrosoftWordIcon from "@/core/components/icons/MicrosoftWordIcon.vue";
// import MicrosoftExcelIcon from "@/core/components/icons/MicrosoftExcelIcon.vue";
import FileIcon from "@/core/components/icons/FileIcon.vue";
import DocumentIcon from "@/core/components/icons/DocumentIcon.vue";
import { formatBytes } from "@/core/helpers/file.helper";
import DocumentActionMenu from "@/modules/documents/components/DocumentActionMenu.vue";
import documentConstants from "@/modules/documents/contants";
import RenameDocumentModal from "@/modules/documents/components/modal/RenameDocumentModal.vue";
import DocumentDetailsModal from "@/modules/documents/components/modal/DocumentDetailsModal.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import ShareDocumentModal from "@/modules/documents/components/modal/ShareDocumentModal.vue";
import MoveDocumentsModal from "@/modules/documents/components/modal/MoveDocumentsModal.vue";
import appFilters from "@/filters";
import { BILLING_STORE } from "@/store/modules/billing";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { WORKSTATION } from "@/store/modules/workstation";
import { ref } from "vue";

const store = useStore();

const emits = defineEmits(["on-success", "on-view-folder"]);
const props = defineProps({
  tableType: {
    type: String,
  },
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
  isPropertyDocument: {
    type: Boolean,
    default: false,
  },
});

const colors = ["#FFA500", "#919191", "#2A52CB", "#615B52", "#68C693"];

const state = reactive({
  selectedRow: null,

  result: [],
  delay: 200,
  clicks: 0,
  timer: null,

  list: [],

  selectedDocument: null,
});

const modals = reactive({
  shareModal: false,
  renameDocument: false,
  documentDetails: false,
  deleteConfirmation: false,
  moveModal: false,
});

const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);

const isTypeFolder = computed(
  () => props.tableType === documentConstants.FOLDERS
);

const isTypeFile = computed(() => props.tableType === documentConstants.FILES);

const onOpenShareModal = (document) => {
  state.selectedDocument = document;
  modals.shareModal = true;
};

const onCloseShareModal = () => {
  state.selectedDocument = null;
  modals.shareModal = false;
};

const onOpenRenameDocumentModal = (document) => {
  state.selectedDocument = document;
  modals.renameDocument = true;
};

const onCloseRenameDocumentModal = () => {
  state.selectedDocument = null;
  modals.renameDocument = false;
};

const onOpenDocumentDetailsModal = (document) => {
  state.selectedDocument = document;
  modals.documentDetails = true;
};

const onCloseDocumentDetailsModal = () => {
  state.selectedDocument = null;
  modals.documentDetails = false;
};

const onOpenDeleteConfirmationModal = (document) => {
  state.selectedDocument = document;
  modals.deleteConfirmation = true;
};

const onCloseDeleteConfirmationModal = () => {
  state.selectedDocument = null;
  modals.deleteConfirmation = false;
};

const onOpenMoveModal = (document) => {
  state.selectedDocument = document;
  modals.moveModal = true;
};

const onCloseMoveModal = () => {
  state.selectedDocument = null;
  modals.moveModal = false;
};
const closeAllModal = () => {
  onCloseShareModal();
  onCloseRenameDocumentModal();
  onCloseDocumentDetailsModal();
  onCloseDeleteConfirmationModal();
  onCloseMoveModal();
};

const downloadClickLoader = ref(false);
const downloadDocument = async (doc) => {
  console.log("download run");
  const { id, name, fileSize, fileType, attachment } = doc;
  const file = fileList.value.find((file) => file.id === id);
  downloadClickLoader.value = true;
  await nextTick();
  if (file && Math.ceil(fileSize / 1024 / 1024) <= 30) {
    const imageUrl = getImageApiUrl(file.attachment, true);
    try {
      console.log("Fetching small file...");
      const response = await $axios.get(imageUrl);
      console.log(response, "response 1...");
      const blob = new Blob([new Uint8Array(response.Body.data)], {
        type: fileType,
      });
      console.log(blob, "blob 1...");

      // ✅ Fix: Ensure download works consistently
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      // a.click();

      // ✅ Fix: Trigger download only when user interacts
      setTimeout(() => {
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log("Download completed!");
        }, 1000);
      }, 100);
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      downloadClickLoader.value = false;
      console.log("finally");
    }
  } else {
    try {
      console.log("Fetching large file...");
      const res = await $axios.post("upload/download/signed-url", {
        filename: attachment,
      });

      const response = await $axios.get(res.url, {
        responseType: "blob",
      });

      const blob = response;
      // ✅ Fix: Ensure the response is a Blob
      // const blob = new Blob([response.data], { type: fileType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      // a.click();

      setTimeout(() => {
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log("Download completed!");
        }, 1000);
      }, 100);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      downloadClickLoader.value = false;
    }
  }
};

const onHandleAction = (name, document) => {
  closeAllModal();
  if (name === documentConstants.SHARE) {
    onOpenShareModal(document);
  }
  if (name === documentConstants.RENAME) {
    onOpenRenameDocumentModal(document);
  }
  if (name === documentConstants.DETAILS) {
    onOpenDocumentDetailsModal(document);
  }
  if (name === documentConstants.DELETE) {
    onOpenDeleteConfirmationModal(document);
  }
  if (name === documentConstants.DOWNLOAD) {
    downloadDocument(document);
  }
  if (name === documentConstants.MOVE) {
    onOpenMoveModal(document);
  }
};

const onHandleClickRow = (event, item) => {
  state.clicks++;
  if (state.clicks === 1) {
    state.timer = setTimeout(() => {
      state.selectedRow = state.selectedRow === item.id ? null : item.id;
      state.result.push(event.type);
      state.clicks = 0;
    }, state.delay);
  } else {
    clearTimeout(state.timer);

    state.selectedDocument = item;
    state.selectedRow = item.id;
    state.clicks = 0;
    if (isTypeFolder.value) {
      emits("on-view-folder", item);
    } else {
      downloadDocument(item);
    }
  }
};

const setAccumulatedSize = async (sum) => {
  await store.dispatch(`${BILLING_STORE}/setAccumulatedSize`, sum);
};

const removeFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // No file extension found
    return filename;
  } else {
    return filename.substring(0, lastDotIndex);
  }
};

const normalizeList = (docList) => {
  const arrayBytes = [];

  const newList = docList.map((folder, index) => {
    const {
      totalFolderByteSize,
      dateUpdated,
      fileType,
      fileSize,
      isDir,
      name,
      originalName,
      totalFileCount,
    } = folder;

    let type = "";

    if (isDir) {
      type = "folder";
    } else {
      type = "file";
    }

    const isFileTypeList = type === "file";

    const modifiedDate = appFilters.formatToDate(dateUpdated, "DD MMM YYYY");
    const byteSize = isFileTypeList
      ? formatBytes(fileSize)
      : formatBytes(totalFolderByteSize);

    const subStringFileTypePdf = "pdf";
    const subStringFileTypeMsWord = "msword";
    const subStringFileTypeVideo = "video";
    // const subStringFileTypeMsExcel = "msexcel";
    // const subStringFileTypeXlsx = "xlsx";
    let modifiedFileType = "";

    if (fileType?.includes(subStringFileTypePdf)) {
      modifiedFileType = "pdf";
    } else if (fileType?.includes(subStringFileTypeMsWord)) {
      modifiedFileType = "word";
    } else if (fileType?.includes(subStringFileTypeVideo)) {
      modifiedFileType = "video";
    }
    //else if (
    //   fileType?.includes(subStringFileTypeMsExcel) ||
    //   fileType?.includes(subStringFileTypeXlsx)
    // ){
    //   modifiedFileType = "excel";
    // }
    else {
      modifiedFileType = "image";
    }

    if (isFileTypeList) {
      arrayBytes.push(fileSize);
    }

    return {
      ...folder,
      fileCount: isFileTypeList
        ? "-"
        : `${totalFileCount ? totalFileCount : "-"} files`,
      name: isDir ? name : removeFileExtension(name),
      updated: modifiedDate,
      files: isFileTypeList ? fileType : "folder",
      size: byteSize,
      type: modifiedFileType,
      color: colors[index % colors.length],
    };
  });

  for (let i = 0; i < docList.length; i++) {
    let type = "";
    if (docList[i].isDir) {
      type = "folder";
    } else {
      type = "file";
    }
    const isFileTypeList = type === "file";

    if (isFileTypeList) {
      const sum = arrayBytes.reduce((accumulator, a) => {
        return accumulator + a;
      }, 0);

      setAccumulatedSize(sum);
    }
  }

  return newList;
};

const onSuccess = (params) => {
  if (params?.parentId) {
    emits("on-success", { event: "move", options: params });
  } else {
    emits("on-success", { event: params });
  }
};

watch(
  () => folderList.value,
  (newValue, oldValue) => {
    if (newValue && isTypeFolder.value) {
      state.list = normalizeList(newValue);
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => fileList.value,
  (newValue, oldValue) => {
    if (newValue && isTypeFile.value) {
      state.list = normalizeList(newValue);
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (isTypeFolder.value) {
    if (folderList.value && folderList.value.length) {
      state.list = normalizeList(folderList.value);
    }
  }
  if (isTypeFile.value) {
    if (fileList.value && fileList.value.length) {
      state.list = normalizeList(fileList.value);
    }
  }
});
</script>
<style lang="scss" scoped></style>
