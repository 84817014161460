<template>
  <GeneralDialog
    @on-close="onCloseModal"
    width="485px"
    :isShowCloseButton="false"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8"
      >
        <div class="tw-w-[277px] tw-h-[156px]">
          <img
            src="@/assets/images/post-project-loading-image.png"
            class="tw-w-full tw-h-full tw-object-contain"
          />
        </div>
        <Text variant="h5">{{ switchingText }}</Text>
        <v-progress-linear
          color="#3B67E9"
          :model-value="props.projectPostProgressLoading"
          :height="12"
          rounded
          striped
        ></v-progress-linear>
        <!-- <Text
          variant="p"
          textWeight="400"
          textColor="#3B67E9"
          class="!tw-underline tw-cursor-pointer"
          >Cancel Posting</Text
        > -->
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const props = defineProps([
  "loading",
  "switchingText",
  "projectPostProgressLoading",
]);
const emits = defineEmits(["on-close", "on-success", "on-delete"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const onCloseModal = () => {
  emits("on-close");
};
</script>
