<template>
  <GeneralDialog
    class="dispute-evidence-modal !tw-gap-0"
    @on-close="onClickBackButton"
    maxWidth="100%"
    width="588px"
    borderRadius="20px"
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerTitle="Certificate Upload"
    headerDescription="Please upload the certificates to meet the entry requirements."
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    gap="0"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <div class="modal-body">
              <div class="modal-statement modal-content">
                <label class="tw-pb-2">Candidate </label>
                <InputField
                  class="!tw-w-full custom-selector"
                  v-model="candidateName"
                  :rules="validation.candidate"
                  placeholder="Candidate name here"
                  :disabled="true"
                ></InputField>
              </div>
              <div class="modal-statement modal-content">
                <label class="tw-pb-2"
                  >Select Certificates You Are Uploading
                </label>
                <!-- <InputSelect
                  class="!tw-w-full"
                  placeholder="Select Certificate here"
                  v-model="selectedCertificateId"
                  :items="eligibilityItems"
                /> -->
                <v-select
                  :items="eligibilityItems"
                  item-title="name"
                  item-value="id"
                  v-model="selectedCertificateId"
                  class="custom_selector"
                  density="compact"
                  placeholder="e.g Plumbing & Heating"
                  :center-affix="true"
                  :single-line="true"
                  :menu-props="{ contentClass: 'text__selector' }"
                  variant="outlined"
                ></v-select>
              </div>
              <!-- evidence image upload -->
              <div class="modal-evidence-upload modal-content">
                <label>Upload Evidence</label>
              </div>
              <div
                class="tw-mt-2 tw-mb-4 tw-w-full tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-rounded-[8px] tw-bg-[#F8FBFD]"
              >
                <div class="upload__section md:!tw-h-[100px]">
                  <div
                    class="uploade-wrapper custom-file-upload !tw-h-[97px] md:!tw-h-full !tw-w-full !tw-mt-4 !tw-rounded-lg tw-border-[rgba(0,0,0,0.4)] tw-border-[1px] tw-border-dashed !tw-mb-4"
                  >
                    <v-file-input
                      class="upload_file !tw-mb-0 !tw-bg-white"
                      label="Drag and drop images & files, or Browse"
                      variant="solo-filled"
                      v-model="selectedDocument"
                      :rules="validation.attachments"
                      :class="{
                        'added-img': selectedDocument,
                      }"
                      @change="onFileChange"
                      prepend-icon="mdi-file-image-box"
                    >
                    </v-file-input>
                  </div>
                </div>

                <div class="attachment-list !tw-mt-0" v-if="selectedDocument">
                  <div
                    class="attachment-item !tw-bg-none !tw-p-0 !tw-border-none"
                  >
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <PdfIcon
                        class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                        v-if="selectedDocument?.mime === 'application/pdf'"
                      />
                      <ImageIcon
                        class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                        v-else
                      />
                      <Text variant="p" textWeight="400">{{
                        selectedDocument?.name || selectedDocument?.originalName
                      }}</Text>
                    </div>
                    <v-icon
                      class="cursor-pointer !tw-text-[16px]"
                      icon="mdi-close"
                      @click="removeAttachment()"
                    ></v-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
                :disabled="isUploadImg"
              >
                Cancel
              </v-btn>
              <v-btn
                class="button button-purple"
                @click="onSubmit"
                :loading="isUploadImg"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
const store = useStore();
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";

const emits = defineEmits(["on-close", "on-submit"]);
const props = defineProps({
  eligibility: {
    type: Object,
    default: null,
  },
  candidate: {
    type: Object,
    default: null,
  },
});

const eligibilityItems = ref([]) as any;

const internalInstance = getCurrentInstance();
const formRef = ref<any>(null);
const isUploadImg = ref<boolean>(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const validation = ref({
  candidate: [(v: string) => !!v || "Candidate name required"],
  attachments: [
    (v: any[]) => v.length > 0 || "At least one attachment is required",
  ],
});

const candidateName = ref<string>("");

const selectedDocument = ref(null);
const selectedCertificateId = ref(null);

const onFileChange = async (event: any) => {
  const file = event.target.files[0];
  if (file) {
    try {
      isUploadImg.value = true;
      selectedDocument.value = file;
      const formData = new FormData();
      formData.append("file", file);
      const uploadedFile = await store.dispatch(
        `${USER_STORE}/uploadProfileImage`,
        formData
      );
      selectedDocument.value = uploadedFile;
      displayToastMessage(
        internalInstance,
        "Document added successfully",
        "success"
      );
    } catch (error: any) {
      displayToastMessage(internalInstance, error.message, "error");
    } finally {
      event.target.value = "";
      isUploadImg.value = false;
    }
  }
};

const setEligibilityData = () => {
  if (props.eligibility.data.length) {
    props?.eligibility?.data.forEach((eligibility: any) => {
      eligibilityItems.value.push(eligibility);
    });
  }
};

const onSubmit = async () => {
  const isFormValid = await formRef.value.validate();

  if (!isFormValid.valid) return;
  let certificateData = {
    userId: user.value.id,
    certificateId: selectedCertificateId.value,
    metadata: {
      url: null,
      registrationNumber: null,
    },
    attachments: [selectedDocument.value],
  };

  try {
    isUploadImg.value = true;
    await store.dispatch(`${USER_STORE}/addNewCertificate`, certificateData);
  } catch (error: any) {
    displayToastMessage(internalInstance, error.message, "error");
  } finally {
    isUploadImg.value = false;
    emits("on-close");
  }
};

// const removeAttachment = (index: number) => {
//   selectedDocument.value.splice(index, 1);
// };
const removeAttachment = () => {
  selectedDocument.value = null;
};

const onClickBackButton = () => {
  return emits("on-close");
};

onMounted(() => {
  setEligibilityData();
  if (props.candidate) {
    candidateName.value = props.candidate.userName;
  }
});
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}
</style>
