import { Commit } from "vuex";
import {
  CourseDetails,
  CreateUserCourse,
  CreateUserCourseSchedules,
} from "@/core/models/courses";
import CoursesService from "@/core/services/courses.service";

export default {
  async getCourses({ commit }: { commit: Commit }) {
    return CoursesService.getCourses().then((response) => {
      commit("setCourseDetails", response?.data);
      return response;
    });
  },

  async checkElegibility({ commit }: { commit: Commit }, payload: any) {
    return CoursesService.getCandidateMembers(payload).then((response) => {
      return response;
    });
  },
  saveCourseDetails(
    { commit }: { commit: Commit },
    courseDetails: CourseDetails
  ): CourseDetails | null {
    if (courseDetails) {
      commit("setSelectedCourseDetails", courseDetails);

      return courseDetails;
    }

    return null;
  },
  async getCourseSchedule({ commit }: { commit: Commit }, params: any) {
    if (!params) return;
    return CoursesService.getCourseSchedule(params).then((response) => {
      return response;
    });
  },

  getCourseScheduleById(
    context: any,
    params: {
      courseScheduleId: any;
      currentFilters: {
        search: any;
        status: any;
      };
    }
  ) {
    return CoursesService.getCourseScheduleById(params);
  },

  getCourseSchedules({ commit }: any, filter: any) {
    return CoursesService.getCourseSchedules(filter).then((response) => {
      return Promise.resolve(response);
    });
  },
  setEnrollmentDialog({ commit }: { commit: Commit }, payload: boolean) {
    commit("setEnrollmentDialog", payload);
  },
  async createUserCourse({ state, commit }: any, payload: CreateUserCourse) {
    const { userId, courseId, forQueue, terms } = payload;
    return CoursesService.createUserCourse(
      userId,
      courseId,
      forQueue,
      terms
    ).then((response) => {
      return response;
    });
  },
  async updateUserCourse({ state, commit }: any, payload: CreateUserCourse) {
    const { userId, courseId, forQueue, terms, enrollmentStatus } = payload;
    return CoursesService.updateUserCourse(
      userId,
      courseId,
      forQueue,
      terms,
      enrollmentStatus
    ).then((response) => {
      return response;
    });
  },
  async createUserCourseSchedules(
    { state, commit }: any,
    payload: CreateUserCourseSchedules
  ) {
    const { courseScheduleId, courseUserId } = payload;
    return CoursesService.createUserCourseSchedules(
      courseScheduleId,
      courseUserId
    ).then((response) => {
      return response;
    });
  },
  async getEnrollCourses({ commit }: { commit: Commit }, payload: any) {
    const { userId } = payload;
    return CoursesService.getEnrollCourses(userId).then((response) => {
      commit("setEnrollCourseDetails", response?.data);
      return response;
    });
  },
  async checkCourseAlreadyEnrolled(
    { commit }: { commit: Commit },
    payload: any
  ) {
    const { userId, scheduleId } = payload;
    return CoursesService.checkCourseAlreadyEnrolled(userId, scheduleId).then(
      (response) => {
        return response;
      }
    );
  },

  setSelectedDates({ commit }: any, payload: any) {
    commit("setSelectedDates", payload);
  },

  setTotalReserveSpotAmount({ commit }: any, payload: any) {
    commit("setTotalReserveSpotAmount", payload);
  },
  setSpotReservedDateStarted({ commit }: any, payload: any) {
    commit("setSpotReservedDateStarted", payload);
  },

  setCourseEventToBeCreated({ commit }: any, payload: any) {
    commit("setCourseEventToBeCreated", payload);
  },

  async reserveSpot({ commit }: { commit: Commit }, payload: any) {
    const { userId, courseReserveData } = payload;

    return CoursesService.reserveSpot(userId, courseReserveData).then(
      (response) => {
        commit("setReservedSpotsData", response);
        return response;
      }
    );
  },

  async fetchReservedSpots({ commit }: { commit: Commit }, userId: number) {
    // console.log("callleddd fetchReservedSpots");
    return CoursesService.fetchReservedSpots(userId).then((response) => {
      commit("setReservedSpotsData", response?.data);
      return response;
    });
  },

  setStoredReservedSpotsData({ commit }: any, payload: any) {
    commit("setStoredReservedSpotsData", payload);
  },

  setCourseUpgradeResponse({ commit }: any, payload: any) {
    commit("setCourseUpgradeResponse", payload);
  },

  async courseUpgradeSubscription(
    { commit }: { commit: Commit },
    request: any
  ) {
    const { userId, payload } = request;
    return CoursesService.courseUpgradeSubscription(userId, payload).then(
      (response) => {
        commit("setCourseUpgradeResponse", response);
        return response;
      }
    );
  },
  async getCoursesWithRemainingSlots({ commit }: any, payload: any) {
    const { userId } = payload;
    return CoursesService.getCoursesWithRemainingSlots(userId).then(
      (response) => {
        commit("setCoursesWithRemainingSlots", response);
        return response;
      }
    );
  },
  async assignUserCourseToMember({ commit }: any, payload: any) {
    const { userId, userCourseId, memberIds } = payload;
    return CoursesService.assignUserCourseToMember(userId, userCourseId, {
      memberIds,
    }).then((response) => {
      return response;
    });
  },
  async getAssignedCourses({ commit }: any, payload: any) {
    const { userId } = payload;
    return CoursesService.getAssignedCourses(userId).then((response) => {
      commit("setAssignedCourses", response);
      return response;
    });
  },
  async setUpdatedMembers({ commit }: any, payload: any) {
    commit("setUpdatedMembers", payload);
  },
};
