<template>
  <GeneralDialog
    headerTitle="Dispute Confirmation"
    :isTitleStrong="true"
    width="622px"
    @on-close="onCloseModal"
  >
    <template #body>
      <div class="tw-flex tw-w-full">
        <Text
          class="tw-max-w-[80%]"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="left"
        >
          Are you sure you want to close the dispute?
        </Text>
      </div>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pt-6 tw-box-border tw-border-t-dashed-custom tw-gap-4"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          label="cancel"
          @click="onCloseModal"
        />
        <Button
          class="!tw-w-auto"
          variant="accent"
          label="confirm"
          @click="onConfirmModal"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "./modals/GeneralDialog.vue";
import Button from "./ui/general/Button.vue";
import Text from "./ui/general/Text.vue";
import { ref } from "vue";

const emits = defineEmits(["on-close", "on-proceedCloseDispute"]);

const onCloseModal = () => {
  emits("on-close");
};
const onConfirmModal = () => {
  emits("on-proceedCloseDispute");
};
</script>
