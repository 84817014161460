<template>
  <GeneralDialog
    class="dispute-evidence-modal !tw-gap-0"
    @on-close="onClickBackButton"
    maxWidth="100%"
    width="588px"
    borderRadius="20px"
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerTitle="Request Extension"
    headerDescription="You have the option to request an extension to submit your evidence. Please choose an extension time"
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent>
            <div class="modal-body">
              <div class="tw-flex tw-flex-col tw-gap-3 tw-w-full">
                <Text variant="h6" textWeight="400"
                  >Select the length of extension you are seeking</Text
                >
                <div class="tw-flex tw-items-center tw-gap-3 tw-w-full">
                  <div
                    @click="toggleDayCard(day.id)"
                    v-for="(day, index) in days"
                    :key="index"
                    class="tw-rounded-[8px] tw-border-[1px] tw-w-auto tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-p-2 tw-h-[44px] tw-box-border tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                    :class="{
                      '!tw-border-[#3366FF] !tw-bg-[rgba(51,102,255,0.06)]':
                        selectedDay === day.id,
                    }"
                  >
                    <Text variant="h6" textWeight="400"
                      >{{ day.hours }} Hours</Text
                    >
                  </div>
                </div>
              </div>
              <div class="modal-statement modal-content !tw-mb-[20px]">
                <label for=""
                  >Please provide a reason for your extension request
                  (Optional)</label
                >
                <v-textarea
                  class="input_field"
                  placeholder="Please type your statement here"
                  variant="solo"
                  rows="5"
                  bg-color="#f8fbfd"
                  autogrow
                  v-model="description"
                  :disabled="extensionRequested"
                />
              </div>
            </div>

            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="!extensionRequested"
                class="button button-purple"
                @click="onRequestForExtension"
              >
                Submit Extension Request
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";

const store = useStore();
const emits = defineEmits(["on-close", "submit"]);
const props = defineProps({
  disputeProject: {
    type: Object,
  },
  extensionRequested: {
    type: Boolean,
    default: false,
  },
});

const description = ref<string>("");

// const validation = ref({
//   description: [(v: string) => !!v || "Enter Evidence description"],
// });

const selectedDay = ref<number>(1);
const days = ref([
  {
    id: 1,
    hours: "24",
  },
  {
    id: 2,
    hours: "48",
  },
  {
    id: 3,
    hours: "72",
  },
]);

const internalInstance = getCurrentInstance();

const disputeProject = computed(() => {
  return store.getters[`${PROJECT_STORE}/getDisputeProject`];
});

const toggleDayCard = (id: number) => {
  selectedDay.value = id;
};

const onRequestForExtension = async () => {
  try {
    const disputeIssueId = disputeProject.value.id;

    const payload = {
      days: selectedDay.value,
      description: description.value,
    };
    const request = {
      disputeIssueId,
      payload,
    };

    const response = await store.dispatch(
      `${PROJECT_STORE}/requestForExtension`,
      request
    );
    store.commit(`${PROJECT_STORE}/updateProjectDispute`, {
      extensionRequest: response.data ? [response.data] : [],
    });
    displayToastMessage(
      internalInstance,
      "Request Extension Successfully",
      "success"
    );
  } catch (error: any) {
    if (error.response) {
      if (error.response.status) {
        const errorMessage =
          error.response.data.message || "An error occurred. Please try again.";
        displayToastMessage(internalInstance, errorMessage, "error");
      } else {
        // Handle other status codes or errors
        displayToastMessage(
          internalInstance,
          `Error: ${error.response.statusText}`,
          "error"
        );
      }
    } else {
      console.log("error :>> ", error);
      displayToastMessage(
        internalInstance,
        "The request took too long and timed out. Please try again later.",
        "error"
      );
    }
  } finally {
    onClickBackButton();
  }
};

const onClickBackButton = () => {
  return emits("on-close");
};

onMounted(() => {
  const extensionRequest = disputeProject.value.extension_request.find(
    (request: any) => request.status === "pending"
  );

  if (extensionRequest) {
    description.value = extensionRequest.description;
    selectedDay.value = extensionRequest.days;
  }
});
</script>
<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
</style>
