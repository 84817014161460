<template>
  <GeneralDialog
    v-if="
      !courseCertificateUploadModal &&
      !confirmDeleteCandidateModal &&
      !addCandidateModal
    "
    class="dispute-evidence-modal !tw-gap-0"
    @on-close="onClickBackButton"
    maxWidth="100%"
    width="700px"
    borderRadius="20px"
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    gap="0"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <!-- v-if="eligibility" -->
            <div
              class="modal-body !tw-py-3 tw-box-border tw-w-full tw-flex tw-flex-col tw-gap-3"
            >
              <!-- success candidate requirements -->

              <div
                class="tw-bg-[#F8FBFD] tw-w-full tw-flex tw-items-center tw-p-4 tw-box-border tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#DFDFDF]"
              >
                <div
                  class="tw-flex tw-items-start tw-justify-bentween tw-gap-4 tw-w-full"
                >
                  <Text
                    v-if="membersEligible"
                    class="rmd:!tw-text-base"
                    variant="xsmall"
                    textWeight="600"
                    lineHeight="18px"
                    >🥳 You are eligible to enroll the course.</Text
                  >
                  <div v-else class="tw-flex tw-items-center tw-gap-2">
                    <v-icon
                      icon="mdi-close-circle"
                      class="!tw-text-[20px]"
                      color="#FE2727"
                    ></v-icon>
                    <Text
                      class="rmd:!tw-text-base rmd:!tw-leading-[18px]"
                      variant="xsmall"
                      textWeight="600"
                      lineHeight="18px"
                      >😕 1 or more candidates doesn’t meet the entry
                      requirements
                    </Text>
                  </div>
                </div>
              </div>
              <!-- canditates list -->
              <div
                class="tw-w-full tw-p-3 tw-box-border tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#DDE2E7] tw-bg-[#F1F6FB] tw-flex tw-flex-col tw-gap-3"
              >
                <div
                  class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-border-b-dashed-custom"
                >
                  <Text variant="p">Candidates</Text>
                  <Button
                    v-if="isBusinessTypeLtdCompany"
                    @click="onOpenAddCandidateModal"
                    label="+Add Candidates"
                    variant="secondary"
                    class="!tw-min-h-[unset] ! !tw-py-0 !tw-px-[.5rem] !tw-w-auto"
                  ></Button>
                </div>
                <div
                  class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-2 tw-py-[.2rem] tw-box-border"
                >
                  <!-- candidate item -->
                  <div
                    v-for="(candidate, index) in modifiedMembers"
                    :key="index"
                    class="tw-w-full tw-flex tw-items-center tw-justify-between"
                  >
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <div class="tw-w-[30px] tw-h-[30px] tw-relative">
                        <div
                          class="tw-w-full tw-h-full tw-rounded-full tw-relative tw-overflow-hidden"
                        >
                          <img
                            :src="candidate?.userProfileImageUrl"
                            v-if="candidate?.userProfileImageUrl"
                            class="tw-w-full tw-h-full tw-object-cover"
                          />
                          <div
                            v-else
                            class="tw-rounded-full tw-relative tw-w-full tw-h-full"
                          >
                            <UserProfileLogo
                              :userName="
                                candidate?.toUser
                                  ? `${candidate?.toUser?.firstName} ${candidate?.toUser?.lastName}`
                                  : candidate?.email?.split('@')[0]
                              "
                              :mobileFontSizeBig="true"
                            />
                          </div>
                        </div>

                        <v-icon
                          v-if="candidate?.certificateEligible?.isEligible"
                          icon="mdi-check-circle"
                          class="!tw-text-[14px] tw-absolute tw-top-[-3px] tw-right-[-3px] online"
                        ></v-icon>
                        <v-icon
                          v-else
                          icon="mdi-close-circle"
                          class="!tw-text-[14px] tw-absolute tw-top-[-3px] tw-right-[-3px] offline"
                        ></v-icon>
                      </div>

                      <Text variant="p" textWeight="400">
                        {{
                          candidate?.toUser
                            ? `${candidate?.toUser?.firstName} ${candidate?.toUser?.lastName}`
                            : candidate?.email?.split("@")[0]
                        }}
                      </Text>
                      <div
                        class="tw-h-[23px] tw-px-[.5rem] tw-py-[.5rem] tw-box-border tw-border-solid tw-border-[1.27px] tw-border-[#DFDFDF] tw-rounded-[5.22px] tw-flex tw-items-center tw-justify-center"
                        v-if="candidate?.isWorkStationAdmin"
                      >
                        <Text variant="xsmall" textWeight="400">Admin</Text>
                      </div>
                    </div>
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <div
                        v-if="candidate?.isWorkStationAdmin"
                        @click="onOpenCertificateUploadModal(candidate)"
                        class="tw-h-[23px] tw-px-[.5rem] tw-py-[.5rem] tw-bg-[#3366FF] tw-cursor-pointer tw-box-border tw-border-solid tw-border-[1.27px] tw-border-[#DFDFDF] tw-rounded-[5.22px] tw-flex tw-items-center tw-justify-center"
                      >
                        <Text
                          variant="xsmall"
                          textWeight="400"
                          textColor="#fff"
                          >{{
                            !candidate?.certificateEligible?.isEligible
                              ? "Upload"
                              : "Manage"
                          }}</Text
                        >
                      </div>
                      <div
                        @click="selectCandidateManageCertificate(candidate)"
                        v-else
                        class="tw-h-[23px] tw-px-[.5rem] tw-py-[.5rem] tw-bg-[#3366FF] tw-cursor-pointer tw-box-border tw-border-solid tw-border-[1.27px] tw-border-[#DFDFDF] tw-rounded-[5.22px] tw-flex tw-items-center tw-justify-center"
                      >
                        <Text
                          variant="xsmall"
                          textWeight="400"
                          textColor="#fff"
                          >{{
                            !candidate?.certificateEligible?.isEligible
                              ? "Assign"
                              : "Manage"
                          }}</Text
                        >
                      </div>
                      <div
                        v-if="!candidate?.isWorkStationAdmin"
                        @click="onOpenConfirmDeleteModal(candidate)"
                        class="!tw-h-[18px] !tw-w-[18px] !tw-min-w-[18px] tw-cursor-pointer tw-bg-[#80829F] tw-rounded-full tw-flex tw-items-center tw-justify-center"
                      >
                        <v-icon
                          icon="mdi-delete"
                          color="#fff"
                          class="!tw-text-[10px]"
                        ></v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- eligibility list -->
              <div
                class="tw-bg-[#F8FBFD] tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#DFDFDF]"
              >
                <div
                  class="tw-bg-white tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-0 tw-p-3 tw-box-border tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-rounded-lg"
                >
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <v-icon
                      icon="mdi-check-circle"
                      color="#1FB27A"
                      class="!tw-text-[18px]"
                    ></v-icon>
                    <Text
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >Certificates that are approved by the admin.</Text
                    >
                  </div>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <v-icon
                      icon="mdi-close-circle"
                      class="!tw-text-[18px]"
                      color="#FE2727"
                    ></v-icon>
                    <Text
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >Certificates that are not approved / uploaded.</Text
                    >
                  </div>
                </div>

                <div
                  class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-2 rmd:!tw-gap-3 tw-items-start"
                >
                  <Text
                    variant="p"
                    textWeight="400"
                    class="rmd:!tw-text-[16px] tw-pb-2"
                    lineHeight="18px"
                    >To qualify for the course you need a valid 18th Edition & 1
                    of the 3 Qualifications below the dotted line.</Text
                  >

                  <div
                    v-for="(eligibility, index) in admin?.certificateEligible
                      ?.data"
                    :key="index"
                    class="tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-2"
                    :class="{
                      'tw-pb-3 tw-border-b-dashed-custom':
                        index === 0 && route?.params?.courseId == 2,
                    }"
                  >
                    <div class="tw-flex tw-gap-2 tw-items-center">
                      <ListIcon
                        class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:!tw-min-w-6 rmd:!tw-w-6 rmd:!tw-h-6"
                      />
                      <Text
                        class="rmd:!tw-text-[14px]"
                        variant="xsmall"
                        whiteSpace="wrap"
                        lineHeight="18px"
                        >{{ eligibility.name }}</Text
                      >
                    </div>
                    <CheckGreen
                      class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:!tw-min-w-4 rmd:!tw-w-4 rmd:!tw-h-4"
                      v-if="eligibility.isEligible"
                    />
                    <CancelIcon
                      class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:!tw-min-w-4 rmd:!tw-w-4 rmd:!tw-h-4"
                      v-else
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
              >
                Cancel
              </v-btn>
              <v-btn class="button button-purple" @click="onSubmitEligibility">
                Submit
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>

  <CourseCertificateUploadModal
    v-if="isCourseCertificateUploadModal"
    :eligibility="admin?.certificateEligible"
    :candidate="selectedCandidateData"
    @on-close="onCloseCertificateUploadModal"
  />
  <ConfirmDeleteCandidateModal
    :candidate="selectedCandidateData"
    v-if="confirmDeleteCandidateModal"
    @on-close="onCloseConfirmDeleteModal"
    @on-confirm="onConfirmDeleteModal"
  />

  <AddCandidateModal
    v-if="addCandidateModal"
    @on-close="onCloseAddCandidateModal"
    @team-member-added="handleTeamMemberAdded"
  />

  <MemberCertificateManageModal
    v-if="isOpenManageCertificateModal"
    :userCandidateData="selectedCandidateData"
    @onClickCloseTab="toggleManageCertificateModal"
    @on-submit="onSubmitManageCertificateModal"
  />
</template>
<script setup>
import { ref, onBeforeMount, computed } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import CourseCertificateUploadModal from "./CourseCertificateUploadModal.vue";
import ConfirmDeleteCandidateModal from "./ConfirmDeleteCandidateModal.vue";
import AddCandidateModal from "./AddCandidateModal.vue";
import { USER_STORE } from "@/store/modules/user";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import { getImageStringToImageURL, parseJSON } from "@/core/utils/common";
import MemberCertificateManageModal from "@/modules/certification/components/MemberCertificateManageModal.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const eligibility = ref(null);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);
const teamMemberList = ref();

const isCourseCertificateUploadModal = ref(false);
const confirmDeleteCandidateModal = ref(false);
const addCandidateModal = ref(false);
const loadingImages = ref(false);
const modifiedMembers = ref([]);
const selectedCandidateData = ref(null);
const isOpenManageCertificateModal = ref(false);

const admin = computed(() => {
  const members = modifiedMembers?.value || [];
  return members.find((member) => member?.isWorkStationAdmin);
});

const emits = defineEmits(["on-close", "on-enroll"]);

const infoMessages = ref([
  {
    courseId: 1,
    message:
      "For the user to be eligible for the course to enroll must have at least 1 of <b>below six certificates</b>",
  },
  {
    courseId: 2,
    message:
      "For the user to be eligible for the course to enroll must have <b>18th editions</b> certificate and also need any 1 of <b>below remain three</b>",
  },
]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const isBusinessTypeLtdCompany = computed(() => {
  return (
    activeUserWorkstation.value?.user?.userBusinessDetails?.type ===
    BusinessDetailTypeEnum.LIMITED_COMPANY
  );
});
const messageBasedOnCourse = computed(() => {
  const courseId = parseInt(route?.params?.courseId, 10);
  return infoMessages.value.find((message) => message.courseId == courseId)
    .message;
});

const toggleManageCertificateModal = () => {
  isOpenManageCertificateModal.value = !isOpenManageCertificateModal.value;

  if (!isOpenManageCertificateModal.value) {
    selectedCandidateData.value = null;
  }
};
const onSubmitManageCertificateModal = () => {
  getCandidateTeamMember();
  toggleManageCertificateModal();
};
const selectCandidateManageCertificate = (candidateData) => {
  selectedCandidateData.value = candidateData;
  toggleManageCertificateModal();
};

const onClickBackButton = () => {
  return emits("on-close", modifiedMembers.value);
};

const onOpenCertificateUploadModal = (candidate) => {
  isCourseCertificateUploadModal.value = true;
  selectedCandidateData.value = candidate;
};

const onCloseCertificateUploadModal = () => {
  isCourseCertificateUploadModal.value = false;
  selectedCandidateData.value = null;
};

const onOpenConfirmDeleteModal = async (candidate) => {
  confirmDeleteCandidateModal.value = true;

  selectedCandidateData.value = candidate;
};

const onConfirmDeleteModal = async () => {
  if (selectedCandidateData.value !== null) {
    modifiedMembers.value = modifiedMembers.value.filter(
      (member) => member.toUser?.id !== selectedCandidateData.value?.toUser?.id
    );

    await store.dispatch(
      `${COURSES_STORE}/setUpdatedMembers`,
      modifiedMembers.value
    );
  }
  confirmDeleteCandidateModal.value = false;
  selectedCandidateData.value = null;
};
const onCloseConfirmDeleteModal = () => {
  confirmDeleteCandidateModal.value = !confirmDeleteCandidateModal.value;
  if (!confirmDeleteCandidateModal.value) {
    selectedCandidateData.value = null;
  }
};

const onOpenAddCandidateModal = () => {
  addCandidateModal.value = true;
};

const onCloseAddCandidateModal = () => {
  addCandidateModal.value = false;
};

const onButtonClick = (isEligible) => {
  if (isEligible) {
    onClickBackButton();
  } else {
    onClickBackButton();
    router.push({ name: TRADE_PASSPOSRT_ROUTE });
  }
};

const getCandidateTeamMember = async () => {
  try {
    loadingImages.value = true;
    const courseId = parseInt(route?.params?.courseId, 10);
    if (!courseId) return;
    const response = await store.dispatch(`${COURSES_STORE}/checkElegibility`, {
      courseId,
      workstationId: activeUserWorkstation.value?.id,
    });

    teamMemberList.value = response;
    if (!teamMemberList.value && !teamMemberList.value.length) {
      loadingImages.value = false;
      return;
    }

    modifiedMembers.value = [];
    for (const member of teamMemberList.value) {
      if (member?.toUserId) {
        if (member?.toUser?.userPublicProfile) {
          const imageUrl = await getImageStringToImageURL(
            member?.toUser?.userPublicProfile?.profileImage
          );
          modifiedMembers.value.push({
            ...member,
            userProfileImageUrl: imageUrl,
          });
        } else {
          modifiedMembers.value.push({
            ...member,
            userProfileImageUrl: null,
          });
        }
      } else {
        modifiedMembers.value.push({
          ...member,
          userProfileImageUrl: null,
        });
      }
    }

    if (
      activeUserWorkstation?.value?.user?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.SOLETRADER
    ) {
      modifiedMembers.value = modifiedMembers.value.filter((member) => {
        return member?.isWorkStationAdmin;
      });
    }
  } catch (err) {
    console.log();
  } finally {
    loadingImages.value = false;
  }
};

const handleTeamMemberAdded = () => {
  getCandidateTeamMember();
  onCloseAddCandidateModal();
};

const membersEligible = computed(() => {
  const members = modifiedMembers?.value.value || [];
  return members.every((member) => member.certificateEligible.isEligible);
});

const onSubmitEligibility = () => {
  emits("on-enroll");
};
onBeforeMount(() => {
  getCandidateTeamMember();
});
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}
.online {
  color: #1fb27a;
}
.offline {
  color: rgba($errorRed, 1);
}
</style>
