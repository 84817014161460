<template>
  <v-expansion-panel :class="getEscrowStatus.className">
    <v-expansion-panel-title :color="`${phaseDetails?.color}1A`">
      <div class="estimate_summery_item_header_left">
        <div
          class="checkbox"
          v-if="getEscrowStatus.status === status.IN_PROGRESS_POCK"
        >
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="bottom"
            class="estimate_tooltip"
          >
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.
          </v-tooltip>

          <v-icon icon="mdi-check-circle" color="#0C0F4A"> </v-icon>
        </div>
        <div
          v-else-if="getEscrowStatus.status === status.COMPLETED"
          class="checkbox"
        >
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="bottom"
            class="estimate_tooltip"
          >
            Property owner release the payment.
          </v-tooltip>

          <v-icon icon="mdi-check-circle" color="#40AE42"> </v-icon>
        </div>
        <div
          :style="{ color: phaseDetails?.color || 'blue' }"
          class="estimate_summery_item_title"
        >
          {{
            phaseDetails?.phaseType === EstimatePhaseType.LABOUR
              ? phaseDetails?.name
              : "Materials"
          }}
        </div>
      </div>
      <div class="estimate_summery_item_header_right">
        <div class="estimate_summery_status">
          <v-icon
            v-if="getEscrowStatus.status === status.COMPLETED"
            icon="mdi-check-circle"
            color="#40AE42"
          >
          </v-icon>
          <img
            v-if="getEscrowStatus.status === status.IN_PROGRESS"
            src="@/assets/images/inprogress.svg"
            alt="InProgress"
            width="18"
            height="18"
          />
          <img
            v-if="getEscrowStatus.status === status.NOT_STARTED"
            src="@/assets/images/clock.svg"
            alt="Clock"
            width="18"
            height="18"
          />
          <p>{{ getEscrowStatus.label }}</p>
        </div>
        <v-btn
          variant="text"
          :disabled="isDisablePaymentButton"
          class="estimate_summery_tag hide-mobile button-orange"
          @click.stop="onClickPaymentStatusButton"
        >
          {{ getEscrowStatus.paymentStatus }}
        </v-btn>

        <v-btn
          v-if="
            getEscrowStatus.status === status.COMPLETED &&
            isShowProjectRatingButton
          "
          variant="text"
          class="estimate_summery_tag hide-mobile button-orange"
          @click.stop="toggleRatingModal(null)"
        >
          Rate your Experience
        </v-btn>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div class="estimate_summery_item_body">
        <div class="estimate_summery_item_info_list">
          <div class="estimate_summery_item_info_item">
            <div class="estimate_summery_item_info_label">Tasks</div>
            <div class="estimate_summery_item_info_text">
              {{ phaseDetails?.estimationPhaseTasks?.length }}
            </div>
          </div>
          <div class="estimate_summery_item_info_item">
            <div class="estimate_summery_item_info_label">Days</div>
            <div class="estimate_summery_item_info_text">
              {{
                getTotalDays(
                  phaseDetails?.phaseStartDate,
                  phaseDetails?.phaseEndDate
                )
              }}
            </div>
          </div>
          <div class="estimate_summery_item_info_item">
            <div class="estimate_summery_item_info_label">Phase Cost</div>
            <div class="estimate_summery_item_info_text">
              {{
                totalPhaseAmount(
                  phaseDetails?.subTotal,
                  phaseDetails?.vat,
                  phaseDetails?.isOld
                )
              }}
            </div>
          </div>
          <span
            v-if="phaseDetails.escrowStatus.name === status.COMPLETED"
            :class="[
              isDisputePhase
                ? 'tw-text-gray-400 tw-cursor-not-allowed'
                : 'tw-text-blue-500 tw-underline tw-cursor-pointer hover:tw-text-blue-700',
            ]"
            @click="!isDisputePhase && onClickRaisePhaseDispute(phaseDetails)"
          >
            Raise Dispute
          </span>
        </div>

        <div class="estimate_summery_tag display-mobile">
          <p>Paid</p>
        </div>

        <div
          class="estimate_summery_notes_list"
          v-if="
            phaseDetails?.estimationPhaseTasks?.length &&
            getEscrowStatus.status === status.IN_PROGRESS_POCK
          "
        >
          <div
            class="estimate_summery_notes"
            v-for="phaseTask in phaseDetails.estimationPhaseTasks"
            :key="phaseTask.id"
          >
            <div class="estimate_summery_notes_header">
              <div class="estimate_summery_notes_header_left">
                <!-- <v-checkbox
                  :disabled="isDisableCheckBox"
                  @click="onChangeCheckBox('subTask', index)"
                  :model-value="
                    phaseCompletedDetail?.subTask[index]?.isCompleted
                  "
                ></v-checkbox> -->
                <div class="estimate_summery_notes_title">
                  <p>{{ phaseTask?.name }}</p>
                </div>
                <div class="estimate_summery_notes_author">
                  <div class="task_images">
                    <div class="task_images_list">
                      <img
                        src="@/assets/images/estimate-summery-person.png"
                        alt=""
                      />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar.jpg" alt="" />
                    </div>
                    <div class="task_images_list">
                      <img src="@/assets/images/sample-avatar-2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="estimate_summery_notes_header_right">
                <div class="estimate_summery_notes_info">
                  <div class="estimate_summery_notes_info_item">
                    <p>
                      <strong>{{
                        getTotalDays(
                          phaseTask?.phaseTaskStartDate,
                          phaseTask?.phaseTaskEndDate
                        )
                      }}</strong>
                      Days
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p>
                      <strong>{{
                        formattedCurrency(phaseTask?.taskCost)
                      }}</strong>
                      Unit Cost
                    </p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <!-- <p>
                      <strong>{{
                        getCostInFormate(phaseTask?.taskCost)
                      }}</strong>
                      Task Cost
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="estimate_summery_notes_body">
              <p>Description</p>
            </div>
          </div>

          <!-- <div class="estimate_summery_notes">
            <div class="estimate_summery_notes_header">
              <div class="estimate_summery_notes_header_left">
                <v-checkbox :model-value="true"></v-checkbox>
                <div class="estimate_summery_notes_title">
                  <p>Task 1</p>
                </div>
                <div class="estimate_summery_notes_author">
                  <div class="task_images">
                    <div class="task_images_list">
                      <img src="@/assets/images/person.png" alt="" />
                    </div>
                    <div class="task_images_list">
                      <img
                        src="@/assets/images/estimate-summery-person.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="estimate_summery_notes_header_right">
                <div class="estimate_summery_notes_info">
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>2</strong> Days</p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>£1,500.00</strong> Unit Cost</p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>£3,000.00</strong> Task Cost</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="estimate_summery_notes_body">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>

          <div class="estimate_summery_notes">
            <div class="estimate_summery_notes_header">
              <div class="estimate_summery_notes_header_left">
                <v-checkbox :model-value="true"></v-checkbox>
                <div class="estimate_summery_notes_title">
                  <p>Task 1</p>
                </div>
                <div class="estimate_summery_notes_author">
                  <div class="estimate_summery_notes_author">
                    <div class="task_images">
                      <div class="task_images_list">
                        <img src="@/assets/images/sample-avatar-2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="estimate_summery_notes_header_right">
                <div class="estimate_summery_notes_info">
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>2</strong> Days</p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>£1,500.00</strong> Unit Cost</p>
                  </div>
                  <div class="estimate_summery_notes_info_item">
                    <p><strong>£3,000.00</strong> Task Cost</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="estimate_summery_notes_body">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <div
        class="estimate_summery_item_footer"
        v-if="getEscrowStatus.status === status.IN_PROGRESS_POCK"
      >
        <div class="estimate_summery_item_controls">
          <div
            class="estimate_summery_item_control tw-cursor-pointer"
            @click="toggleShowPhaseNoteModal(false)"
          >
            <img
              src="@/assets/images/sticky-note.svg"
              alt="Notes"
              width="18"
              height="18"
            />
            <span>Phase notes</span>
            <span>{{ getNoteLength }}</span>
          </div>
          <div
            class="estimate_summery_item_control tw-cursor-pointer"
            @click="openShowPhaseProofOfWorkModal"
          >
            <img
              src="@/assets/images/image.svg"
              alt="Image"
              width="18"
              height="18"
            />
            <span>Proof of work</span>
            <span>{{ phaseDetails?.proofOfWork?.length }}</span>
          </div>
          <div class="estimate_summery_item_control">
            <img
              src="@/assets/images/person.svg"
              alt="Image"
              width="18"
              height="18"
            />
            <span>Members</span>
          </div>
        </div>
        <div class="estimate_summery_item_author">
          <img
            src="@/assets/images/estimate-summery-person.png"
            alt="Notes"
            width="18"
            height="18"
          />
        </div>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>

  <ShowPhaseNoteModal
    v-if="isShowPhaseNoteModal && getNoteLength"
    :phaseDetails="phaseDetails"
    @on-close="toggleShowPhaseNoteModal"
  />
  <ShowPhaseProofOfWorkModal
    v-if="isShowPhaseProofOfWorkModal && phaseDetails?.proofOfWork?.length"
    :phaseDetails="phaseDetails"
    :estimateId="estimateDetails?.id"
    @on-close="togglePhaseProofOfWorkModal"
  />
  <ReleasePhasePayment
    :phaseDetails="phaseDetails"
    v-if="isOpenReleasePaymentModal"
    :Loadingvalue="loading"
    @on-close="toggleReleasePaymentModal"
    @on-confirm="onConfirmReleasePaymentModal"
  />
  <PaymentSuccessModal
    v-if="isOpenPaymentSuccess"
    @on-close="onClosePaymentSuccessModal"
  />
  <PaymentFailureModal
    v-if="isOpenPaymentFail"
    @on-close="onClosePaymentFailureModal"
  />
  <ProjectRateModal
    v-if="isShowReviewModal"
    :type="ProjectRatingTypeEnum.PROPERTY_OWNER"
    :ratingData="projectRatingData"
    @on-close="toggleRatingModal"
    :projectId="projectDetails?.id"
    :estimatePhaseDetails="phaseDetails"
    reviewType="phase"
  />
</template>
<script setup>
import { computed, getCurrentInstance } from "vue";
import moment from "moment";
import { ref } from "vue";
import { onMounted } from "vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { EscrowStatusEnum, EstimatePhaseType } from "@/core/enums/estimateEnum";
import ShowPhaseNoteModal from "@/modules/project/modal/ShowPhaseNoteModal.vue";
import ShowPhaseProofOfWorkModal from "@/modules/project/modal/ShowPhaseProofOfWorkModal.vue";
import CurrencyFilter from "@/filters/currency.filter";
import ReleasePhasePayment from "@/modules/project/modal/ReleasePhasePayment.vue";
import PaymentSuccessModal from "@/modules/project/modal/PaymentSuccessModal.vue";
import PaymentFailureModal from "@/modules/project/modal/PaymentFailureModal.vue";
import ProjectRateModal from "@/core/components/modals/ProjectRateModal.vue";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import projectRatingService from "@/core/services/project-rating.service";
import { parseJSON } from "@/core/utils/common";
import { PROJECT_STORE } from "@/store/modules/project";
import { defineEmits } from "vue";

const props = defineProps({
  phaseDetails: { type: Object, default: null },
  estimateDetails: { type: Object, default: null },
});
const phaseCompletedDetail = ref();
const store = useStore();
const isOpenPhaseNoteModal = ref(false);
const isOpenPhaseProofModal = ref(false);
const isOpenReleasePaymentModal = ref(false);
const isOpenPaymentSuccess = ref(false);
const isOpenPaymentFail = ref(false);
const internalInstance = getCurrentInstance();
const emit = defineEmits(["on-click-raise-phase-dispute"]);

const status = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  IN_PROGRESS_POCK: "In Progress POC",
  COMPLETED: "Stage Complete",
};
const escrowStatus = [
  {
    status: "Not Started",
    className: "",
    label: "not started",
    paymentStatus: "Not Started",
  },
  {
    status: "In Progress",
    className: "in-progress",
    label: "in progress",
    paymentStatus: "Release Payment",
  },

  {
    status: "In Progress POC",
    className: "paid-item",
    label: "completed",
    paymentStatus: "Release Payment",
  },
  {
    status: "Stage Complete",
    className: "paid-item",
    label: "Paid",
    paymentStatus: "Paid",
  },
];
const escrowAccount = ref();
const isShowPhaseNoteModal = ref(false);
const isShowPhaseProofOfWorkModal = ref(false);
const getEscrowStatus = computed(
  () =>
    escrowStatus.find((escrow) => {
      if (escrowAccount.value?.id === EscrowStatusEnum.IN_PROGRESS_POKED) {
        return escrow.status === "In Progress POC";
      }

      return escrow.status === escrowAccount.value?.name;
    }) || escrowStatus[1]
);

const isDisablePaymentButton = computed(() => {
  !phaseCompletedDetail.value?.isCompletedPhase &&
    !getEscrowStatus.value.status === status.IN_PROGRESS_POCK;

  if (phaseCompletedDetail.value?.isCompletedPhase) {
    if (getEscrowStatus.value.status === status.IN_PROGRESS_POCK) return false;
    return true;
  } else {
    return true;
  }
});

const disputeProject = computed(() => {
  return store.getters[`${PROJECT_STORE}/getDisputeProject`];
});

const isDisputePhase = computed(() => {
  if (disputeProject.value?.estimatePhases?.length) {
    return disputeProject.value.estimatePhases.some(
      (phase) => phase.id === props.phaseDetails.id
    );
  }
  return false;
});

const getTotalDays = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), "days") || 0;
};

const toggleShowPhaseNoteModal = (value) => {
  isShowPhaseNoteModal.value = !isShowPhaseNoteModal.value;
};
const togglePhaseProofOfWorkModal = (status) => {
  isShowPhaseProofOfWorkModal.value = !isShowPhaseProofOfWorkModal.value;
};
const openShowPhaseProofOfWorkModal = () => {
  if (props.phaseDetails?.proofOfWork?.length) {
    isShowPhaseProofOfWorkModal.value = true;
  } else {
    displayToast("No Proof of Work Available right now", "info");
  }
};
const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const onClosePaymentSuccessModal = async () => {
  isOpenPaymentSuccess.value = false;
};
const onReleasePayment = async (escrowStatusId) => {
  try {
    const res = await tradeEstimateService.updateProjectEstimatePhaseStatus(
      props.phaseDetails?.id,
      {
        escrowStatusId,
      }
    );
    escrowAccount.value = res?.escrowStatus;
  } catch (error) {
    console.log("error", error);
  }
};

const onRefundTransactions = async (projectEstimateId) => {
  if (projectEstimateId) {
    await tradeEstimateService.refundProjectAmount(projectEstimateId);
  }
};

const onClickPaymentStatusButton = () => {
  if (getEscrowStatus.value.status === status.IN_PROGRESS_POCK) {
    isOpenReleasePaymentModal.value = true;
  }
};
const getTradeEstimateDataWithEscrowStatus = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getTradeEstimateDataWithEscrowStatus`
    ]
);
const updatePhaseCompletionStatus = async (data) => {
  try {
    const res = await tradeEstimateService.updatePhaseCompletionStatus({
      estimateId: getTradeEstimateDataWithEscrowStatus.value?.id,
      phaseId: props.phaseDetails?.id,
      data,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const onChangeCheckBox = (type, index) => {
  let updatedData = null;
  if (type === "phaseTask") {
    phaseCompletedDetail.value.isCompletedPhase =
      !phaseCompletedDetail.value.isCompletedPhase;
    if (phaseCompletedDetail.value.isCompletedPhase) {
      phaseCompletedDetail.value.subTask.forEach(
        (task) => (task.isCompleted = true)
      );
      updatedData = {
        phaseStatus: true,
        phaseTask: phaseCompletedDetail.value.subTask.map((task) => ({
          phaseTaskId: task?.id,
          taskStatus: true,
        })),
      };
    } else {
      phaseCompletedDetail.value.subTask.forEach(
        (task) => (task.isCompleted = false)
      );
      updatedData = {
        phaseStatus: false,
        phaseTask: phaseCompletedDetail.value.subTask.map((task) => ({
          phaseTaskId: task?.id,
          taskStatus: false,
        })),
      };
    }
  } else {
    phaseCompletedDetail.value.subTask[index].isCompleted =
      !phaseCompletedDetail.value.subTask[index].isCompleted;
    if (phaseCompletedDetail.value.subTask.every((task) => task.isCompleted)) {
      phaseCompletedDetail.value.isCompletedPhase = true;
      updatedData = {
        phaseStatus: true,
        phaseTask: [
          {
            phaseTaskId: phaseCompletedDetail.value.subTask[index]?.id,
            taskStatus: phaseCompletedDetail.value.subTask[index].isCompleted,
          },
        ],
      };
    } else {
      phaseCompletedDetail.value.isCompletedPhase = false;
      updatedData = {
        phaseStatus: false,
        phaseTask: [
          {
            phaseTaskId: phaseCompletedDetail.value.subTask[index]?.id,
            taskStatus: phaseCompletedDetail.value.subTask[index].isCompleted,
          },
        ],
      };
    }
  }
  updatePhaseCompletionStatus(updatedData);
};

const toggleReleasePaymentModal = () => {
  isOpenReleasePaymentModal.value = !isOpenReleasePaymentModal.value;
};

const onClosePaymentFailureModal = () => {
  isOpenPaymentFail.value = false;
};

const loading = ref(false);
const onConfirmReleasePaymentModal = async () => {
  try {
    loading.value = true;
    const payload = {
      phaseIds: [props.phaseDetails?.id],
    };
    const response = await tradeEstimateService.phaseReleaseApprove(payload);
    console.log("here", response?.projectEstimateId);

    if (response) {
      if (response?.isLastPhaseAndRemainingBalance === true) {
        await onRefundTransactions(response?.projectEstimateId);
      }
      await onReleasePayment(EscrowStatusEnum.STAGE_COMPLETE);
      isOpenPaymentSuccess.value = true;
    } else {
      isOpenPaymentFail.value = true;
    }
  } catch (error) {
    console.log("error", error);
    displayToast(
      error?.response?.data?.message || "Internal Server Error",
      "error"
    );
  } finally {
    isOpenReleasePaymentModal.value = false;
    loading.value = false;
  }
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};
const getNoteLength = computed(() =>
  props.phaseDetails?.estimationPhaseTasks?.reduce((noteLength, subTask) => {
    return (noteLength += subTask?.phaseNotes?.length || 0);
  }, 0)
);

const SERVICE_CHARGE = 0.03;
const PHASE_FIRST_CHARGE = 5;
const totalPhaseAmount = (amount, vat, isFirstPhase) => {
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return CurrencyFilter.formatToCurrency(sum);
};

const projectRatingData = ref();
const isShowReviewModal = ref(false);
const toggleRatingModal = (ratingData) => {
  isShowReviewModal.value = !isShowReviewModal.value;
  if (ratingData) {
    projectRatingData.value = ratingData;
  }
};

const projectDetails = computed(
  () => store.getters[`${PROJECT_STORE}/projectDetails`]
);

const isShowProjectRatingButton = computed(
  () =>
    projectRatingData.value &&
    !parseJSON(projectRatingData.value.ratingStatusList)?.length &&
    escrowAccount.value?.id == EscrowStatusEnum.STAGE_COMPLETE
);

const checkIsUserRatedProject = async () => {
  try {
    projectRatingData.value = await projectRatingService.getProjectPhaseRating(
      props.phaseDetails?.id
    );
    if (!projectRatingData.value) {
      toggleRatingModal(null);
    }
  } catch (error) {
    console.log("get project rating", error);
  }
};

const onClickRaisePhaseDispute = (phaseTask) => {
  emit("on-click-raise-phase-dispute", phaseTask);
};

onMounted(async () => {
  if (props.phaseDetails?.escrowStatus?.id == EscrowStatusEnum.STAGE_COMPLETE) {
    await checkIsUserRatedProject();
  }
  phaseCompletedDetail.value = {
    isCompletedPhase: props.phaseDetails?.phaseCompletionStatus,
    subTask: props.phaseDetails?.estimationPhaseTasks.map((task) => ({
      isCompleted: task?.taskCompletionStatus,
      id: task?.id,
    })),
  };
  escrowAccount.value = props.phaseDetails?.escrowStatus || {
    id: 1,
    name: "Not Started",
  };
});
</script>

<style lang="scss" scoped>
.task_images {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;

  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: -5px;

    &:last-child {
      margin-right: 0;
    }

    $a: 1;

    @for $i from 1 through 5 {
      &:nth-child(3n + #{$i}) {
        z-index: -$a;
        $a: $a + 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.estimate_summery {
  display: grid;
  grid-template-columns: 388px 1fr;
  grid-gap: 16px;
  align-items: flex-start;
  text-align: left;
  color: $blueDark;

  // @media (max-width: 1439px) {
  //   grid-template-columns: 1fr;
  //   display: flex;
  //   flex-direction: column;
  //   grid-gap: 0;
  //   gap: 16px;

  // }
  @include respond(lg) {
    grid-template-columns: 1fr;
  }

  &_left {
    width: 100%;

    .estimate_card_title {
      margin-bottom: 22px;
    }
  }

  &_amount {
    font-weight: 700;
    @include fluidFont(38, 38, 25px);
    letter-spacing: 0.25px;

    &_label {
      @include fluidFont(14, 14, 18px);
      font-weight: 500;
      letter-spacing: 1px;
      color: rgba($blueDark, 0.5);
      margin-top: 18px;
    }
  }

  &_header {
    box-shadow: none;
    font-weight: 600;
    margin-bottom: 30px;

    @include respond(s720) {
      display: none;
    }

    h2 {
      font-weight: 600;
      @include fluidFont(25, 25, 25px);
      letter-spacing: 0.64px;
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 42px;
      flex-wrap: wrap;
      margin-top: 15px;
    }

    &_label {
      display: grid;
      grid-template-columns: 18px 1fr;
      grid-gap: 5px;
      font-weight: 700;
      @include fluidFont(14, 14, 1);
      color: $info;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &_id {
      @include fluidFont(14, 14, 1);

      span {
        color: rgba($blueDark, 0.5);
      }
    }
  }

  &_list {
    &_title {
      margin-bottom: 24px;

      h3 {
        @include fluidFont(18, 20, 22px);
        letter-spacing: 0.25px;
      }
    }

    &_inner {
      padding: 16px;

      @include respond(s720) {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  &_info_list {
    padding-bottom: 12px;
    border-bottom: 1px solid #c5d2f2;
    margin-bottom: 30px;

    @include respond(s720) {
      padding: 16px 14px;
      background-color: $white;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border: 0;
      border-radius: 6px;
      margin-bottom: 22px;
    }

    .estimate_card_title {
      font-weight: 700;
      margin-bottom: 12px;

      @include respond(s720) {
        margin-bottom: 0;
      }
    }

    &_inner {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 8px;

      @include respond(s720) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    &_box {
      background-color: #f3f3f6;
      border-radius: 8px;
      padding: 8px;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include fluidFont(10, 10, 12px);
      letter-spacing: 0.25px;
      color: rgba($blueDark, 0.7);

      &_title {
        @include fluidFont(12, 12, 25px);
        color: $blueDark;
        font-weight: 700;
      }
    }
  }

  &_item {
    &_header {
      &_left {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
      }

      &_right {
        position: relative;
        flex: auto;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    &_title {
      @include fluidFont(14, 10, 17px);
      font-weight: 700;

      .paid-item & {
        color: #eb8c49;
      }

      .in-progress & {
        color: #d84f50;
      }
    }

    &_body {
      padding: 20px 24px;

      @include respond(s720) {
        padding: 28px 20px;
      }
    }

    &_info_list {
      display: flex;
      align-items: flex-start;
      gap: 32px 84px;
      letter-spacing: 0.25px;
      flex-wrap: wrap;

      @include respond(s720) {
        gap: 32px 62px;
      }
    }

    &_info_item {
      position: relative;

      @include respond(s720) {
        display: flex;
        flex-direction: column-reverse;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -54px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.1);

          @include respond(s720) {
            right: -30px;
          }
        }
      }
    }

    &_info_label {
      @include fluidFont(10, 12, 1);
      color: rgba($blueDark, 0.8);
      margin-bottom: 2px;
    }

    &_info_text {
      @include fluidFont(16, 16, 25px);
      font-weight: 700;
    }

    &_footer {
      background-color: rgba($blueDark, 0.1);
      padding: 10px;
      @include fluidFont(12, 10, 12px);
      color: #6c6d8e;
      border-radius: 0 0 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @include respond(s720) {
        border-radius: 0 0 10px 10px;
      }
    }

    &_controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;

      @include respond(s720) {
        gap: 10px;
      }
    }

    &_control {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;

      @include respond(s720) {
        gap: 8px;
      }

      img {
        width: auto;
        height: auto;
        max-height: 18px;
        object-fit: contain;
        flex-shrink: 0;

        @include respond(s720) {
          max-height: 14px;
        }
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -15px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: rgba($blueDark, 0.2);

          @include respond(s720) {
            right: -4px;
          }
        }
      }
    }

    &_author {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &_status {
    display: flex;
    align-items: center;
    gap: 6px;
    @include fluidFont(10, 10, 1);
    text-transform: uppercase;
    color: rgba($blueDark, 0.5);
    background-color: rgba($blueDark, 0.05);
    border-radius: 5px;
    min-height: 20px;
    padding: 2px 10px;
    font-weight: 600;

    .in-progress & {
      background-color: rgba($blueDark, 0.05);
      color: $blueDark;
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }

    .mdi-check-circle {
      flex-shrink: 0;
      font-size: 16px;
    }

    img {
      flex-shrink: 0;
      max-width: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .v-btn {
    min-height: 32px;
    border-radius: 5px;
    color: $white;
    background-color: #ffa500;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 1;
    &:disabled {
      background-color: #e7e7ed;
      color: rgba($blueDark, 0.54);
    }

    @include respond(s720) {
      margin-top: 20px;
    }

    .in-progress & {
      background-color: #e7e7ed;
      color: rgba($blueDark, 0.54);
    }

    .paid-item & {
      background-color: rgba($PrimaryGreen, 0.1);
      color: $PrimaryGreen;
    }
  }

  .v-expansion-panel {
    border-radius: 8px;

    @include respond(s720) {
      border-radius: 10px;
    }

    &-title {
      min-height: 56px;
      display: flex;
      align-items: center;
      gap: 34px;
      justify-content: space-between;
      padding: 12px 24px;

      @include respond(s720) {
        gap: 10px;
        min-height: 46px;
      }
    }
  }

  &_notes {
    background-color: rgba(#f3f3f6, 0.6);
    border-radius: 6px;
    padding: 10px;

    &_list {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(#c5d2f2, 0.3);
      display: grid;
      grid-gap: 10px;

      @include respond(s720) {
        border-top: 0;
        padding-top: 0;
        margin-top: 12px;
      }
    }

    &_title {
      background-color: rgba(#4a92e5, 0.1);
      @include fluidFont(12, 10, 1);
      border-radius: 5px;
      min-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 600;
      color: #4a92e5;
      padding: 4px 10px;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      @include respond(s720) {
        flex-direction: column;
        gap: 14px;
        align-items: flex-start;
      }

      &_left {
        display: flex;
        align-items: center;
        gap: 6px;
        flex: auto;

        @include respond(s720) {
          width: 100%;
        }
      }

      &_right {
        display: flex;
        align-items: center;
        gap: 13px;
      }
    }

    &_author {
      margin-left: auto;
    }

    &_body {
      @include fluidFont(12, 12, 12px);
      padding-top: 18px;
      font-weight: 500;

      @include respond(s720) {
        padding-top: 14px;
      }
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 26px;

      &_item {
        position: relative;
        @include fluidFont(10, 10, 12px);
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.25px;

        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            right: -14px;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: rgba($blueDark, 0.1);
          }
        }
      }
    }
  }

  .display-mobile {
    display: none;

    @include respond(s720) {
      display: flex;
    }
  }

  .hide-mobile {
    @include respond(s720) {
      display: none;
    }
  }

  .checkbox {
    .mdi-check-circle {
      font-size: 18px;
    }
  }

  --v-selection-control-size: 16px !important;
}

.estimate_tooltip {
  :deep(.v-overlay__content) {
    background-color: rgba($blueDark, 0.88);
    @include fluidFont(10, 10, 1);
    letter-spacing: 0.25px;
  }
}

:deep {
  .v-selection-control--density-default {
    --v-input-control-height: 16px;
    --v-selection-control-size: 16px !important;
  }

  .v-expansion-panel__shadow {
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
  }

  .v-expansion-panel-title__overlay {
    background-color: rgba($info, 0.1);
    opacity: 1;

    .paid-item & {
      background-color: #fdf4ed;
    }

    .in-progress & {
      background-color: rgba(#d84f50, 0.1);
    }
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
}
</style>
