<template>
  <div
    class="tw-w-full tw-flex tw-items-center tw-justify-start tw-flex-col tw-pb-16"
  >
    <TradePassportInsurance v-if="route?.params?.type === 'insurance'" />
    <TradePassportCertification v-if="route?.params?.type === 'certificate'" />
    <TradePassportSpecialism v-if="route?.params?.type === 'services'" />
  </div>
</template>
<script setup>
import { useRoute } from "vue-router";

import TradePassportInsurance from "../components/TradePassportInsurance.vue";
import TradePassportCertification from "../components/TradePassportCertification.vue";
import TradePassportSpecialism from "../components/TradePassportSpecialism.vue";

const route = useRoute();
</script>
<style lang="scss" scoped></style>
