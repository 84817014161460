<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    v-if="!isLoading && userSpecialismList?.length"
    class="tw-max-w-[1300px] tw-w-full"
  >
    <PendingVerifyNotice
      v-if="
        userSkillVerificationStatus?.userVerificationStatus ===
          UserVerificationStatusEnum.PENDING_VERIFICATION &&
        isWorkStationVerified &&
        !isUserProprtyOwner
      "
      content="New services are being verified. Use existing ones."
    />
    <!-- 
    <TradeWelcomeBoard
      v-if="!isUserProprtyOwner"
      title="Trades Services"
      description="Explore and offer a range of trade services to connect with property owners."
      actionTitle="Add New Trade Services "
      @onClickAction="onClickToggleSpecialismForm"
    /> -->

    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >{{ isUserProprtyOwner ? "Services" : "Your Services" }}</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add Services"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onClickToggleSpecialismForm"
        />
      </div>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-4 md:!tw-gap-3">
        <div
          class="tw-w-full tw-flex tw-items-start tw-gap-4 md:!tw-gap-2"
          v-for="(skill, index) in userSpecialismList"
          :key="index"
        >
          <div
            class="tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-lg tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-bg-white tw-p-4 tw-box-border"
            :class="{ '!tw-items-start': collapsedItems.includes(index) }"
          >
            <div
              class="tw-rounded-[4px] tw-border-[1px] tw-border-solid tw-border-[#DFDFDF] tw-min-w-[48px] tw-w-[48px] tw-h-[48px] tw-flex tw-items-center tw-justify-center tw-bg-white"
            >
              <div
                class="tw-rounded-full tw-w-[34px] tw-min-w-[34px] tw-h-[34px] tw-bg-[#E7F4FE] tw-flex tw-items-center tw-justify-center"
              >
                <img
                  :src="
                    require(`@/assets/category-icons/${skill?.category?.iconName}.svg`)
                  "
                  class="tw-w-[15px] tw-min-w-[15px] tw-h-[15px] tw-object-contain"
                />
              </div>
            </div>
            <!-- if not collapsed -->
            <div
              class="tw-w-full tw-items-center tw-flex tw-justify-start"
              v-if="!collapsedItems.includes(index)"
            >
              <Text variant="h6" textAlign="left">{{ skill.label }}</Text>
            </div>
            <!-- if collapsed loop here -->
            <div
              v-else
              class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-1"
            >
              <Text variant="h6" textAlign="left" class="md:!tw-text-[.8rem]">{{
                skill.label
              }}</Text>
              <div class="tw-w-full tw-flex tw-flex-wrap tw-h-auto tw-gap-2">
                <div
                  v-for="(subCategory, index) in skill.subCategories"
                  :key="index"
                  class="tw-w-auto tw-py-[.1rem] tw-px-2 tw-rounded-[5.33px] tw-box-border tw-bg-[#E7F4FE] tw-flex tw-items-center tw-justify-center"
                >
                  <Text variant="xsmall" textAlign="left">
                    {{ subCategory.label }}</Text
                  >
                </div>
              </div>
            </div>
            <div
              @click="onToggleCollapsedState(index)"
              class="tw-min-w-[26px] tw-w-[26px] tw-h-[26px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-[1.3px] tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-cursor-pointer"
            >
              <v-icon
                icon="mdi-chevron-right"
                class="!tw-text-[14px]"
                v-if="!collapsedItems.includes(index)"
              ></v-icon>
              <v-icon
                icon="mdi-chevron-down"
                class="!tw-text-[14px]"
                v-else
              ></v-icon>
            </div>
          </div>
          <div
            class="tw-flex tw-items-center tw-gap-2 md:!tw-gap-1"
            v-if="getUserWorkStationFieldAccess"
          >
            <BlueCircularPencilIcon
              class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
              @click="editSpecialism(skill)"
              v-if="!isUserProprtyOwner"
            />
            <GrayCircularTrashIcon
              class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
              @click="onDeleteItem(skill.id)"
              v-if="!isUserProprtyOwner"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !userSpecialismList?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade-services-empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any services yet!</h4>
        <p>
          It looks like you haven't added any services yet. Add your offerings
          to connect with property owners and showcase your skills.
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any services yet!</h4>
        <p>It looks like you haven't added any services to your profile yet.</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickToggleSpecialismForm"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >add new trade Services</v-btn
        >
      </div>
    </div>
  </div>
  <SpecialismEditModal
    v-if="isShowEditSpecialismModal"
    @onClickCancel="onClickCloseEditModal"
    :userSpecialismData="userSpecialismData"
  />
  <SpecialismMultiSelectModel
    v-if="isShowSpecialismModal"
    @onClickAddNewTradeSpecialism="onClickAddNewTradeSpecialism"
    @onClickCancel="closeSpecialismMultiSelectModel"
  />
  <AddNewTradeSpecialism
    v-if="isShowAddNewTradeSpecialism"
    @onClickCancelButton="closeAddNewTradeSpecialism"
  />
  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    label="You're about to delete your service."
    @on-close="onCloseConfirmDelete"
    @on-delete="onClickDeleteSpecialism"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import { computed, ComputedRef, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import SpecialismEditModal from "@/modules/Specialism/components/SpecialismEditModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import AddNewTradeSpecialism from "@/modules/Specialism/components/AddNewTradeSpecialism.vue";
// import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { getImageStringToImageURL } from "@/core/utils/common";
import userService from "@/core/services/user.service";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRoute, useRouter } from "vue-router";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import Loader from "@/core/components/common/Loader.vue";
// import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";

export default {
  components: {
    SpecialismMultiSelectModel,
    AddNewTradeSpecialism,
    // TradeWelcomeBoard,
    SpecialismEditModal,
    // UserProfileLogo,
    ConfirmDeleteModal,
    Loader,
    PendingVerifyNotice,
    Button,
    Text,
    BlueCircularPencilIcon,
    GrayCircularTrashIcon,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isShowSpecialismModal = ref(false);
    const isShowAddNewTradeSpecialism = ref(false);
    const isShowEditSpecialismModal = ref(false);
    const userSpecialismData = ref(null) as any;
    const userSpecialismList = ref([]) as any;
    const deleteLoader = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isOpenConfirmDelete = ref(false);
    const isLoading = ref(false);

    const collapsedItems = ref([]) as any;

    const getIsOpenTradePassportasViewMode = computed(
      () =>
        store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
    );
    const isUserProprtyOwner = computed(
      () =>
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
        getIsOpenTradePassportasViewMode.value
    );

    const onRouteToTradePassport = async () => {
      await router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const onToggleCollapsedState = (index: any) => {
      if (collapsedItems.value.includes(index)) {
        // Remove the index from the array
        collapsedItems.value = collapsedItems.value.filter(
          (item: any) => item !== index
        );
      } else {
        // Add the index to the array
        collapsedItems.value.push(index);
      }
    };

    const setMemberProfile = async () => {
      userSpecialismList.value = userSpecialisms.value;
      for (const specialism of userSpecialismList.value) {
        specialism["label"] = specialism?.category.poLabel
          ? specialism?.category.tpLabel
          : "";
        for (const user of specialism.assignedUsers) {
          user.profileImageURL = await getImageStringToImageURL(
            user?.userPublicProfile?.profileImage
          );
        }
      }
    };

    const onClickDeleteSpecialism = async () => {
      try {
        deleteLoader.value = true;
        const res = await userService.deleteSkill(
          user.value.id,
          confirmDeleteService.value as number
        );
        await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        setMemberProfile();
      } catch (error) {
        console.log("delete specialism error", error);
      } finally {
        deleteLoader.value = false;
        onCloseConfirmDelete();
      }
    };

    const onClickToggleSpecialismForm = async () => {
      isShowSpecialismModal.value = !isShowSpecialismModal.value;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onClickAddNewTradeSpecialism = async () => {
      isShowSpecialismModal.value = false;
      isShowAddNewTradeSpecialism.value = true;
    };

    const closeSpecialismMultiSelectModel = async (
      isNewServiceAdded: any = false
    ) => {
      if (isNewServiceAdded) {
        userSkillVerificationStatus.value = await store.dispatch(
          `${USER_STORE}/getUserSkillVerificationStatus`,
          user.value.id
        );
      }
      isShowSpecialismModal.value = false;
      setMemberProfile();
    };

    const closeAddNewTradeSpecialism = () => {
      isShowSpecialismModal.value = true;
      isShowAddNewTradeSpecialism.value = false;
    };

    const editSpecialism = (specialism: any) => {
      userSpecialismData.value = specialism;
      isShowEditSpecialismModal.value = true;
    };
    const onClickCloseEditModal = () => {
      isShowEditSpecialismModal.value = false;
      setMemberProfile();
    };
    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const confirmDeleteService = ref<null | number>(null);
    const onDeleteItem = (specialismId: number) => {
      confirmDeleteService.value = specialismId;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteService.value = null;
      isOpenConfirmDelete.value = false;
    };

    const isWorkStationVerified = computed(() => {
      return (
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
      );
    });

    const userSkillVerificationStatus = ref();
    onMounted(async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          await store.dispatch(`${USER_STORE}/getContractorSkills`, {
            userId: user.value.id,
            workStationId: contractorTradePassportWorkstationId.value,
          });
        } else {
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        }
        setMemberProfile();
        userSkillVerificationStatus.value = await store.dispatch(
          `${USER_STORE}/getUserSkillVerificationStatus`,
          user.value.id
        );
      } catch (error) {
        console.log("error while loading trade services", error);
      } finally {
        isLoading.value = false;
        console.log(userSpecialismList.value, "userSpecialismList");
      }
    });
    return {
      dashBoardWelcomeButton,
      onClickToggleSpecialismForm,
      isShowSpecialismModal,
      formatDate,
      onClickAddNewTradeSpecialism,
      isShowAddNewTradeSpecialism,
      closeSpecialismMultiSelectModel,
      userSpecialisms,
      closeAddNewTradeSpecialism,
      onClickDeleteSpecialism,
      isShowEditSpecialismModal,
      onClickCloseEditModal,
      editSpecialism,
      userSpecialismData,
      getUserWorkStationFieldAccess,
      userSpecialismList,
      isOpenConfirmDelete,
      onDeleteItem,
      onCloseConfirmDelete,
      deleteLoader,
      isLoading,
      isUserProprtyOwner,
      userSkillVerificationStatus,
      UserVerificationStatusEnum,
      isWorkStationVerified,
      getIsOpenTradePassportasViewMode,
      onRouteToTradePassport,
      collapsedItems,
      onToggleCollapsedState,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/onboarding/styles/onboarding.scss";
</style>
