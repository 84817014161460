import $axios from "../utils/axios-api-config";

export default {
  getWorkStationLink(token: string) {
    return $axios.get(`/workstation-link-tree/${token}`);
  },
  createWorkStationLink(payload: any) {
    return $axios.post(`/workstation-link-tree/`, payload);
  },
  updateWorkStationLink(linkId: number, payload: any) {
    return $axios.put(`/workstation-link-tree/${linkId}`, payload);
  },
  //wallet payments
  getGooglePaymentWallet(payload: any) {
    return $axios.post(`/payment/google-wallet/`, payload);
  },
  getPaymentWallet(workStationLinkId: number) {
    return $axios.get(`/workstation-link-tree/${workStationLinkId}`);
  },
  getWorkStationLinkTreeBannerStatus(workStationId: number) {
    return $axios.get(`/user/network/${workStationId}/banner-status`);
  },
  createCustomerAccount(payload: { userId?: number; userCustomerId: number }) {
    const { userId, userCustomerId } = payload;
    delete payload.userId;
    return $axios.post(
      `/users/${userId}/accounts/${userCustomerId}/accounts`,
      payload
    );
  },
};
