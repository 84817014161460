<template>
  <div
    class="tw-min-w-[36px] tw-w-[36px] tw-h-[36px] tw-bg-[#4A92E5] tw-rounded-full tw-flex tw-items-center tw-justify-center"
  >
    <v-icon
      icon="mdi-pencil"
      color="#fff"
      class="!tw-text-[17px] md:!tw-text-[12px]"
    ></v-icon>
  </div>
</template>
