<template>
  <div>
    <CommonDialog
      :isShowHeader="false"
      :isShowBackButton="false"
      width="570px"
      max-width="100%"
      height="auto"
      :className="['verifiy_identity__modal']"
    >
      <template v-slot:body>
        <div class="modal__content__wrapper">
          <div class="modal_close" @click="onClickCloseButton">
            <v-icon icon="mdi-close" />
          </div>
          <div class="modal_content">
            <h5>We need to verify your identity</h5>
            <p>
              We will require your passport or driver’s license. Verified ID is
              required to ensure trust, security, and compliance on the Iknowa
              platform.
            </p>
          </div>
          <div class="modal_image">
            <img src="@/assets/images/verify-id.png" alt="" />
          </div>

          <div class="modal__footer">
            <v-btn
              class="button button-purple-border"
              @click="onClickCloseButton"
              >Cancel</v-btn
            >
            <v-btn class="button button-purple" @click="onClickContinueButton"
              >Start ID Verification</v-btn
            >
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup lang="ts">
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
const store = useStore();

const emit = defineEmits(["close", "onClickStartIDVerification"]);

const onClickCloseButton = (event: any) => {
  emit("close");
};
const onClickContinueButton = () => {
  emit("onClickStartIDVerification");
};
</script>

<style lang="scss" scoped>
.modal__content__wrapper {
  .modal_close {
    text-align: right;
  }
  .modal_content {
    h5 {
      @include fluidFont(20, 20, 24px);
      font-weight: 400;
      color: rgba($blueDark, 1);
    }
    p {
      margin-top: 8px;
      @include fluidFont(12, 12, 15.06px);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      letter-spacing: 0.25px;
    }
  }
  .modal_image {
    width: 250px;
    height: 250px;
    max-width: 100%;
    margin: 24px auto;
  }
  .modal__footer {
    padding-top: 24px;
    border-top: 1px dashed rgba(134, 135, 165, 1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    .button {
      text-transform: capitalize;
    }
  }
}
</style>
