import ClientRaiseEstimatePage from "@/modules/client-raise-estimate/pages/ClientRaiseEstimatePage.vue";

export const RAISE_ESTIMATE_DETAIL_ROUTE = "raiseEstimateDetail";

export default [
  {
    path: "/raise-estimate-detail/:token",
    name: RAISE_ESTIMATE_DETAIL_ROUTE,
    component: ClientRaiseEstimatePage,
    meta: {
      layout: "AuthLayout",
    },
  },
];
