<template>
  <div class="dashboard-welcome remove-padding md:!tw-gap-4">
    <!-- <div class="dashboard-welcome__close">
      <v-icon icon="mdi-close"></v-icon>
    </div> -->
    <div class="dashboard-welcome__text">
      <h3>{{ title }}</h3>
      <p class="body-text">
        {{ text }}
      </p>
    </div>
    <div class="dashboard-welcome__img md:!tw-w-full">
      <div class="img-wrapper">
        <img
          class="md:!tw-w-full"
          src="../../../assets/images/courses-welcome.png"
          width="364"
          height="144"
        />
      </div>
    </div>
  </div>
  <!-- <div class="courses__list">
    <div class="courses__title">
      <h3>Recommended for you</h3>
    </div>
    <div class="courses__card__list">
      <v-card
        class="courses__card"
        v-for="items in coursesWithExtraInfo"
        :key="items?.id"
      >
        <div class="courses__card__subtitle">
          <label
            ><v-icon icon="mdi-clock-time-four-outline"></v-icon>22:42
          </label>
          <span>Time left to sign up to next cohort</span>
        </div>
        <div class="courses__card__img">
          <img
            class=""
            cover
            :src="require('@/assets/images/' + items.thumbnail)"
          />
        </div>
        <div class="courses__card__prefix">
          <label class="left__prefix"
            ><v-icon icon="mdi-currency-gbp"></v-icon><span> &#163;950 </span
            >{{ items.price }}</label
          >
          <label class="right__prefix"
            ><v-icon icon="mdi-map-marker"></v-icon
            >{{ items.courseMode }}</label
          >
        </div>
        <h5 class="courses__card__title">{{ items?.name }}</h5>
        <p class="courses__card__description">
          {{ items?.about }}
        </p>

        <div class="courses__card__users">
          <ul class="courses__card__users__img">
            <li>
              <img src="../../../assets/images/sample-avatar.jpg" alt="" />
            </li>
            <li>
              <img src="../../../assets/images/sample-avatar.jpg" alt="" />
            </li>
            <li>
              <img src="../../../assets/images/sample-avatar.jpg" alt="" />
            </li>
            <li>
              <img src="../../../assets/images/sample-avatar.jpg" alt="" />
            </li>
            <li>
              <img src="../../../assets/images/sample-avatar.jpg" alt="" />
            </li>
          </ul>
          <p>+ 34 More Enrolled</p>
        </div>

        <v-btn
          class="button button-purple-border w-full"
          @click="viewCourseDetails(items?.id)"
          >view full course details
        </v-btn>
      </v-card>
    </div>
  </div> -->

  <div class="courses__list all__courses">
    <div class="courses__title">
      <h3>All Courses</h3>
    </div>
    <div v-if="!loading" class="courses__card__list !tw-px-2 tw-box-border">
      <v-card
        class="courses__card"
        v-for="items in coursesWithExtraInfo"
        :key="items?.id"
      >
        <div class="tw-h-full">
          <!-- <TimeRemaining
            class="!tw-w-[300px]"
            label="Time left to sign up to next cohort"
          /> -->
          <div class="courses__card__img !tw-mb-[0]">
            <img
              class=""
              cover
              :src="require('@/assets/images/course/' + items?.thumbnail)"
            />
          </div>
          <div class="courses__card__prefix !tw-justify-between tw-mt-4">
            <label class="left__prefix"
              ><v-icon icon="mdi-currency-gbp"></v-icon
              ><span
                v-if="
                  items?.title ===
                  'Solar PV, Battery Storage & EV Charging Level 3'
                "
              >
                &#163;1,837.00</span
              ><span v-else> &#163;1,558.00</span>FREE</label
            >
            <label class="right__prefix"
              ><v-icon icon="mdi-map-marker"></v-icon>Online & In-Person</label
            >
          </div>
          <div class="courses__text">
            <h5 class="courses__card__title !tw-leading-6 tw-mt-4">
              {{ items?.name }}
            </h5>
            <p class="courses__card__subTitle tw-mt-2">{{ items?.about }}</p>
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
          <!-- <div class="tw-flex tw-items-center tw-gap-4">
            <div class="tw-flex tw-items-center tw-gap-0">
              <ProfileThumbnail
                class="tw-mr-[-.5rem]"
                v-for="item in 5"
                :key="item"
                size="sm"
              />
            </div>
            <Text>+ {{ items?.totalEnrolled }} Enrolled</Text>
          </div> -->
          <v-btn
            v-if="isCurrentUserWorkstationOwner"
            class="button button-purple-border w-full !tw-mt-0"
            @click="viewCourseDetails(items?.id)"
            >view full course details
          </v-btn>
        </div>

        <!-- <label class="yellow-text" @click="viewCourseDetails(items?.id)"
          >VIEW FULL COURSE DETAILS<v-icon icon="mdi-chevron-right"></v-icon
        ></label> -->
      </v-card>
    </div>
    <!-- LOADER -->
    <div
      v-if="loading"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
    >
      <Loader :show="loading" />
    </div>
    <!-- <v-dialog
      width="auto"
      v-model="isShowEnrollmentDialog"
      persistent
      class="modal_box action-modal"
    >
      <div class="modal_wrapper">
        <main class="main">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-w-full"
          >
            <div
              class="tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
            >
              <CheckGreen />
              <Text variant="h4" textAlign="center" lineHeight="28px"
                >Hold onto your hard hat, enrollment complete! 🚀</Text
              >
            </div>

            <Text
              variant="h6"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.5)"
              textAlign="center"
              >iknowa courses are the ultimate power-up for green building pros.
              Get ready to sharpen your skills, unlock new abilities, and
              dominate any retrofit project.</Text
            >
            <v-btn class="button button-orange !tw-w-full" @click="onContinue"
              >continue</v-btn
            >
          </div>
        </main>
      </div>
    </v-dialog> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter } from "vue-router";
// import Text from "../../../core/components/ui/general/Text.vue";
// import TimeRemaining from "../../../core/components/common/TimeRemaining.vue";
// import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import { airSourceHeatPumpData, greenSkillsData } from "../data/courseDetails";
// import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import Loader from "@/core/components/common/Loader.vue";
import { computed } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";

export default defineComponent({
  components: {
    Loader,
    // Text,
    // TimeRemaining,
    // ProfileThumbnail,
    // CheckGreen,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const listOfCourse = ref([]) as any;
    const coursesWithExtraInfo = ref([]) as any;
    const detailsItems = ref();
    const title = "Welcome to your Courses";
    const text =
      "Level up to retrofit legend! Master skills, unlock new business with green courses, and land lucrative clients. Plus, learn alongside a league of legends and build your trade network!";

    const isShowEnrollmentDialog = ref(
      localStorage.getItem("isShowEnrollmentDialog") === "true"
    );
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    onMounted(async () => {
      getCourseData();
      detailsItems.value = await store.getters[
        `${COURSES_STORE}/courseDetails`
      ];

      if (!userCertificates.value?.length) {
        await store.dispatch(
          `${USER_STORE}/setUserCertificates`,
          user.value?.id
        );
      }
    });
    const getCourseData = async () => {
      try {
        loading.value = true;
        let response = await store.dispatch(`${COURSES_STORE}/getCourses`);
        listOfCourse.value = response.data;
        coursesWithExtraInfo.value = response.data.map((course: any) => {
          if (course.id === 1) {
            return {
              ...course,
              ...airSourceHeatPumpData,
            };
          }
          if (course.id === 2) {
            return {
              ...course,
              ...greenSkillsData,
            };
          }
        });
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const viewCourseDetails = async (courseID: number) => {
      const course = coursesWithExtraInfo.value.find(
        (item: any) => item.id === courseID
      );
      if (course) {
        await store.dispatch(`${COURSES_STORE}/saveCourseDetails`, course);
        router.push({
          name: "courseDetails",
          params: { courseId: courseID, variableName: "course" },
        });
      } else {
        console.error(`Course with ID ${courseID} not found.`);
      }
    };

    const onContinue = () => {
      localStorage.removeItem("isShowEnrollmentDialog");
      isShowEnrollmentDialog.value = false;
      const url = new URL(window.location.href);
      router.push("/courses");

      // url.search = "";
      // history.replaceState(null, "", url.toString());
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const isCurrentUserWorkstationOwner = computed(
      () => user.value?.id === activeUserWorkstation.value?.user?.id
    );

    return {
      text,
      title,
      listOfCourse,
      getCourseData,
      // courseDetails,
      viewCourseDetails,
      coursesWithExtraInfo,
      loading,
      onContinue,
      isShowEnrollmentDialog,
      detailsItems,
      isCurrentUserWorkstationOwner,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";

.course-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(1.2em * 3);
  line-height: 1.2em;
  max-height: calc(1.2em * 3);
}
.dashboard-welcome__text {
  justify-content: flex-start !important;
}
</style>
