<template>
  <GeneralDialog
    class="dispute-evidence-modal !tw-gap-0"
    @on-close="onClickBackButton"
    maxWidth="100%"
    width="588px"
    borderRadius="20px"
    :isShowCloseButton="false"
    :isShowHeader="false"
    :isTitleStrong="false"
    headerTitle="Provide evidence"
    headerDescription="Please upload any evidence you may have and provide a statement to support your dispute."
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    gap="0"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <div class="modal-header">
              <div class="title">
                <h2 class="modal-title">Provide Evidence</h2>
                <v-icon
                  class="cursor-pointer"
                  icon="mdi-close"
                  @click="onClickBackButton"
                  :size="15"
                ></v-icon>
              </div>
              <p>
                Please upload any evidence you may have and provide a statement
                to support your dispute.
              </p>
            </div>
            <div class="modal-body">
              <div class="modal-statement modal-content">
                <label for="">Statement</label>
                <v-textarea
                  class="input_field"
                  placeholder="Please type your statement here"
                  variant="solo"
                  rows="5"
                  bg-color="#f8fbfd"
                  autogrow
                  v-model="description"
                  :rules="validation.description"
                />
              </div>
              <!-- evidence image upload -->
              <div class="modal-evidence-upload modal-content">
                <label>Upload Evidence</label>
              </div>
              <div
                class="tw-mt-2 tw-w-full tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-p-3 tw-box-border tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-rounded-[8px] tw-bg-[#F8FBFD]"
              >
                <div class="upload__section md:!tw-h-[100px]">
                  <div
                    class="uploade-wrapper custom-file-upload !tw-h-[97px] md:!tw-h-full !tw-w-full !tw-mt-4 !tw-rounded-lg tw-border-[rgba(0,0,0,0.4)] tw-border-[1px] tw-border-dashed !tw-mb-4"
                  >
                    <v-file-input
                      class="upload_file !tw-mb-0 !tw-bg-white"
                      label="Drag and drop images & files, or Browse"
                      variant="solo-filled"
                      v-model="selectedDocuments"
                      :rules="validation.attachments"
                      multiple
                      :class="{
                        'added-img':
                          selectedDocuments && selectedDocuments?.length,
                      }"
                      @change="onFileChange"
                      prepend-icon="mdi-file-image-box"
                    >
                    </v-file-input>
                  </div>
                </div>
                <div class="attachment-list !tw-mt-0">
                  <div
                    v-for="(file, index) in selectedDocuments"
                    :key="index"
                    class="attachment-item !tw-bg-none !tw-p-0 !tw-border-none"
                  >
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <PdfIcon
                        class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                        v-if="file.type === 'application/pdf'"
                      />
                      <ImageIcon
                        class="!tw-w-[14px] !tw-h-[12px] !tw-min-w-[14px]"
                        v-else
                      />
                      <Text variant="p" textWeight="400">{{ file.name }}</Text>
                    </div>
                    <v-icon
                      class="cursor-pointer !tw-text-[16px]"
                      icon="mdi-close"
                      @click="removeAttachment(index)"
                    ></v-icon>
                  </div>
                </div>
              </div>

              <div class="modal-content dispute-terms-wrapper">
                <v-radio-group
                  v-model="selectTerms"
                  :rules="validation.disputeTerm"
                  mandatory
                >
                  <v-radio
                    value="agree"
                    label="I have read & agree to the dispute terms and process"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="modal-content signature-wrapper !tw-mb-[20px]">
                <label>Digital Signature</label>
                <v-text-field
                  class="input_field"
                  variant="solo"
                  bg-color="#f8fbfd"
                  :rules="validation.signature"
                  v-modal="signature"
                />
              </div>
            </div>

            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
                :disabled="isUploadImg"
              >
                Cancel
              </v-btn>
              <v-btn
                class="button button-purple"
                @click="onSubmit"
                :loading="isUploadImg"
              >
                Submit Dispute
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";
import PdfIcon from "@/core/components/icons/PdfIcon.vue";
import ImageIcon from "@/core/components/icons/ImageIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";

const store = useStore();
const emits = defineEmits(["close", "submit"]);

const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
  disputeReasons: {
    type: Array,
    default: null,
  },
});
const internalInstance = getCurrentInstance();
const formRef = ref<any>(null);
const isUploadImg = ref<boolean>(false);

const validation = ref({
  description: [(v: string) => !!v || "Enter Evidence description"],
  attachments: [
    (v: any[]) => v.length > 0 || "At least one attachment is required",
  ],
  disputeTerm: [(v: string) => !!v || "You must select an disputeTerm"],
  signature: [(v: string) => !!v || "Signature cannot be empty"],
});

const description = ref<string>("");
const signature = ref<string>("");
const attachments = ref<any[]>([]);

const selectTerms = ref<any>(null);

const selectedDocuments = ref<any[]>([]);

const onFileChange = (event: any) => {
  const files = event.target.files;
  if (files && files.length > 0) {
    Array.from(files).forEach((file: any) => {
      if (!selectedDocuments.value.some((doc) => doc.name === file.name)) {
        selectedDocuments.value.push(file);
      }
    });
  }

  event.target.value = "";
};

// const isValidForm = computed(() => {
//   return description.value.trim() !== "" && signature.value.trim() !== "";
// });
const onSubmit = async () => {
  const isFormValid = await formRef.value.validate();

  if (!isFormValid.valid) return;

  try {
    isUploadImg.value = true;
    for (const attachment of selectedDocuments.value) {
      const formData = new FormData();
      formData.append("file", attachment);

      const uploadImg = await store.dispatch(
        `${USER_STORE}/uploadProfileImage`,
        formData
      );

      if (uploadImg?.publicUrl) {
        attachments.value.push(uploadImg.attachment);
      } else {
        displayToastMessage(
          internalInstance,
          "Failed to upload an attachment.",
          "error"
        );
      }
    }

    emits("submit", {
      description: description.value,
      attachments: attachments.value,
    });
  } catch (error) {
    displayToastMessage(
      internalInstance,
      "Failed to upload one or more attachments. Please try again.",
      "error"
    );
  } finally {
    isUploadImg.value = false;
  }
};

const removeAttachment = (index: number) => {
  selectedDocuments.value.splice(index, 1);
};

const onClickBackButton = () => {
  return emits("close");
};
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.upload_file {
  :deep(.v-label) {
    margin-top: 0 !important;
  }
}

.upload_file {
  :deep(.v-field__input) {
    display: none;
  }
  :deep(.v-field__clearable) {
    display: none;
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}
</style>
