<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    class="tradepassport_certificate__dashboard tw-w-full tw-max-w-[1300px] md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading && userCertificates?.length"
  >
    <PendingVerifyNotice
      v-if="isShowPendingBoard"
      content="New certificates are being verified. Use old ones."
    />
    <TradeWelcomeBoard
      class="tw-mb-4 tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] !tw-bg-[#F8FBFD]"
      v-if="hasVerifiedCertificate"
      title="Learn more about iknowa by exploring the Courses"
      actionTitle="Explore iknowa Courses"
      @onClickAction="onClickOpenCoursePreviewModal"
    />
    <!-- <TradeWelcomeBoard
      v-if="!isUserProprtyOwner"
      title="Professional Certifications"
      description=" Showcase your professional certifications to build trust and credibility."
      actionTitle="add new certification"
      @onClickAction="onClickAddNewCertification"
    /> -->

    <!-- new trade certification design -->
    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >{{
              isUserProprtyOwner ? "Certifications" : "Your Certifications"
            }}</Text
          >
          <Text
            variant="p"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.8)"
            lineHeight="18px"
            textAlign="left"
            class="md:!tw-hidden"
            >{{ userCertificates?.length }} Results</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add Certifications"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onClickAddNewCertification"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-w-full">
        <!-- items loop here -->
        <div
          v-for="(certificate, index) in userCertificates"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom': index !== userCertificates.length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full md:!tw-gap-2">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden md:!tw-min-w-[55px] md:!tw-w-[55px] md:!tw-h-[55px]"
            >
              <img
                src="@/assets/images/tradepassport-empty-thumbnail.png"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-1"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
              >
                <div class="tw-flex tw-items-start tw-gap-2">
                  <Text
                    variant="h6"
                    textAlign="left"
                    lineHeight="18px"
                    class="tw-w-[70%] md:!tw-text-[.8rem]"
                    >{{
                      certificate.metadata.name
                        ? certificate.metadata.name
                        : certificate.certificate.name
                    }}</Text
                  >
                  <PendingChip
                    class="tw-w-[auto]"
                    label="Pending Verification"
                    v-if="
                      certificate?.userVerificationStatus ===
                      UserVerificationStatusEnum.PENDING_VERIFICATION
                    "
                  />

                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Accepted"
                    v-if="
                      certificate?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      !isUserProprtyOwner
                    "
                  />
                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Verified"
                    v-if="
                      certificate?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      isUserProprtyOwner
                    "
                  />
                  <RejectChip
                    class="tw-w-[auto]"
                    label="Rejected"
                    v-if="
                      certificate?.userVerificationStatus ===
                      UserVerificationStatusEnum.REJECTED
                    "
                  />
                </div>
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                  textAlign="left"
                >
                  Membership No:
                  {{ certificate?.metadata?.registrationNumber }}
                </Text>
                <div
                  class="tw-flex tw-gap-1 tw-flex-col tw-items-start"
                  v-if="certificate?.assignedUsers"
                >
                  <Text
                    variant="p"
                    textColor="rgba(12, 15, 74, 0.8)"
                    lineHeight="18px"
                    class="md:!tw-text-[.7rem]"
                    textAlign="left"
                  >
                    Member(s):
                  </Text>
                  <!-- members item here -->
                  <div class="tw-flex tw-items-center">
                    <div
                      v-for="(data, index) in certificate.assignedUsers"
                      :key="index"
                      class="tw-min-w-[26px] tw-w-[26px] tw-h-[26px] tw-overflow-hidden tw-rounded-[4px] tw-border-solid tw-border-[#FFFFFF] tw-border-[1px]"
                      :class="{ 'tw-ml-[-.5rem]': index !== 0 }"
                    >
                      <img
                        v-if="data?.userPublicProfile?.publicUrl"
                        :src="
                          data.userPublicProfile.publicUrl ||
                          '../../../assets/images/avatar-info-image.png'
                        "
                        class="tw-w-full tw-h-full tw-object-cover"
                      />
                      <UserProfileLogo
                        v-else
                        :isRounded="false"
                        :userName="`${data?.firstName} ${data?.lastName}`"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tw-flex tw-items-center tw-gap-2 md:!tw-gap-1"
                v-if="getUserWorkStationFieldAccess"
              >
                <BlueCircularPencilIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="editCertificate(certificate)"
                  v-if="!isUserProprtyOwner"
                />
                <GrayCircularTrashIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="onDeleteItem(certificate)"
                  v-if="!isUserProprtyOwner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !userCertificates?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade-certificate-empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any certification yet!</h4>
        <p>
          You haven't added any certifications yet. Add them to build trust and
          showcase your qualifications to property owners.
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any certification yet!</h4>
        <p>haven't added any certifications to this profile yet.</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickAddNewCertification"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >add new Certification</v-btn
        >
      </div>
    </div>
  </div>
  <!-- <CertificationModel
    v-if="isShowCertificateModal"
    @onClickCloseTab="onClickCloseModel"
    :userCertificateData="userCertificateData"
    @clearUserCertificateData="clearData"
  /> -->

  <AddCertificateModal
    v-if="isShowCertificateModal"
    @onClickCloseTab="onClickCloseModel"
    :userCertificateData="userCertificateData"
    @clearUserCertificateData="clearData"
  />

  <CertificateEditModal
    v-if="isEditCertificateModal"
    @onSaveData="onClickUpdateModal"
    @onClickCloseTab="onClickCloseEditModal"
    :userCertificateData="userCertificateData"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteCertificate.name"
    label="You're about to delete your certification."
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>

  <IknowaCoursePreviewModal
    v-if="isShowCoursePreviewModal"
    @onClickClosePreviewModalButton="onClickCloseCoursePreviewModal"
  />
</template>

<script lang="ts">
import { computed, onMounted, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { USER_STORE } from "@/store/modules/user";
// import CertificationModel from "@/modules/certification/components/CertificationModel.vue";
import CertificateEditModal from "@/modules/certification/components/CertificateEditModal.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { getImageStringToImageURL } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import Loader from "@/core/components/common/Loader.vue";
import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import AddCertificateModal from "@/modules/certification/components/AddCertificateModal.vue";
import IknowaCoursePreviewModal from "./modals/IknowaCoursePreviewModal.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    // CertificationModel,
    CertificateEditModal,
    ConfirmDeleteModal,
    UserProfileLogo,
    Loader,
    TradeWelcomeBoard,
    PendingVerifyNotice,
    AddCertificateModal,
    IknowaCoursePreviewModal,
    AcceptChip,
    RejectChip,
    PendingChip,

    BlueCircularPencilIcon,
    GrayCircularTrashIcon,
    Text,
    Button,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const router = useRouter();

    const isShowCertificateModal = ref(false);
    const isEditCertificateModal = ref(false);

    const isShowCoursePreviewModal = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userCertificates = ref([]) as any;
    const deleteLoader = ref(false);
    const isLoading = ref(false);
    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );
    const getIsOpenTradePassportasViewMode = computed(
      () =>
        store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
    );

    const isUserProprtyOwner = computed(
      () =>
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
        getIsOpenTradePassportasViewMode.value
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    watch(
      () => store.getters[`${USER_STORE}/userCertificates`],
      async (certificates) => {
        userCertificates.value = certificates;
        await setProfileImageUrl();
      },
      {
        immediate: true,
      }
    );

    async function setProfileImageUrl() {
      for (const certificate of userCertificates.value) {
        for (const member of certificate.assignedUsers) {
          if (member.userPublicProfile.profileImage !== null) {
            member.userPublicProfile.publicUrl = await getImageStringToImageURL(
              member.userPublicProfile.profileImage
            );
          }
        }
      }
    }

    const userCertificateData = ref(null) as any;

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteCertificate = ref(null) as any;
    const confirmDeleteAssignedMembers = ref(null) as any;

    const onRouteToTradePassport = async () => {
      await router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const onClickAddNewCertification = () => {
      isShowCertificateModal.value = true;
    };

    const onClickOpenCoursePreviewModal = () => {
      isShowCoursePreviewModal.value = true;
    };

    const onClickCloseCoursePreviewModal = () => {
      isShowCoursePreviewModal.value = false;
    };

    const onClickCloseModel = () => {
      isShowCertificateModal.value = false;
      clearData();
    };

    const onClickUpdateModal = async () => {
      await store.getters[`${USER_STORE}/userCertificates`];
      isEditCertificateModal.value = false;
      clearData();
    };

    const onClickCloseEditModal = () => {
      isEditCertificateModal.value = false;
      clearData();
    };

    const onDeleteItem = (certificate: any) => {
      confirmDeleteCertificate.value = certificate;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteCertificate.value = null;
      isOpenConfirmDelete.value = false;
    };
    const deleteCertificate = async (certificate: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificate.id,
        });

        // Delete related Members
        // let payload = {
        //   teamMemberIds: members,
        // };

        // if (members?.length) {
        //   await store.dispatch(
        //     `${USER_STORE}/removeCertificateRelatedMembers`,
        //     {
        //       userId: user?.value?.id,
        //       teamMemberIds: payload,
        //     }
        //   );
        // }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async () => {
      deleteLoader.value = true;
      await deleteCertificate(confirmDeleteCertificate.value);
      deleteLoader.value = false;
      onCloseConfirmDelete();
    };

    const editCertificate = (certificate: any) => {
      isEditCertificateModal.value = true;
      userCertificateData.value = certificate;
    };

    const clearData = () => {
      userCertificateData.value = null;
    };

    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const isWorkStationVerified = computed(() => {
      return (
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
      );
    });
    const isShowPendingBoard = computed(
      () =>
        isWorkStationVerified.value &&
        userCertificates.value?.some(
          (certificates: any) =>
            certificates?.userVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
        ) &&
        userCertificates.value?.some(
          (certificates: any) =>
            certificates?.userVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED
        )
    );
    const hasVerifiedCertificate = computed(
      () =>
        isWorkStationVerified.value &&
        userCertificates.value?.some(
          (certificates: any) =>
            certificates?.userVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED
        )
    );

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        if (user.value?.id && !userCertificates.value?.length) {
          if (isUserProprtyOwner.value) {
            await store.dispatch(`${USER_STORE}/setContractorCertificates`, {
              userId: user.value.id,
              workStationId: contractorTradePassportWorkstationId.value,
            });
          } else {
            await store.dispatch(
              `${USER_STORE}/setUserCertificates`,
              user.value.id
            );
          }
        }
      } catch (error) {
        console.log();
      } finally {
        isLoading.value = false;
      }
    });
    return {
      dashBoardWelcomeButton,
      onClickAddNewCertification,
      userCertificates,
      deleteCertificate,
      isShowCertificateModal,
      isEditCertificateModal,
      onClickCloseModel,
      onClickCloseEditModal,
      editCertificate,
      userCertificateData,
      chipLabel,
      chipColor,
      UserVerificationStatusEnum,
      clearData,
      getUserWorkStationFieldAccess,
      isOpenConfirmDelete,
      deleteItem,
      onDeleteItem,
      onCloseConfirmDelete,
      onClickUpdateModal,
      confirmDeleteCertificate,
      deleteLoader,
      isLoading,
      isUserProprtyOwner,
      isShowPendingBoard,
      getIsOpenTradePassportasViewMode,
      hasVerifiedCertificate,
      onClickOpenCoursePreviewModal,
      isShowCoursePreviewModal,
      onClickCloseCoursePreviewModal,
      onRouteToTradePassport,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
