<template>
  <div v-if="isLoading">
    <Loader :show="isLoading" class="tw-z-10" />
  </div>
  <div
    @click="onRouteToTradePassport"
    class="tw-flex tw-items-center tw-gap-[.1rem] tw-cursor-pointer tw-pb-4 tw-w-full"
  >
    <v-icon icon="mdi-chevron-left"></v-icon>
    <Text variant="p" textWeight="600">Back</Text>
  </div>
  <div
    class="trade__insurance__wrapper tw-max-w-[1300px] tw-w-full md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading && userInsurances?.length"
  >
    <PendingVerifyNotice
      v-if="isShowPendingBoard"
      content="New insurance under review. Existing coverage valid."
    />
    <!-- <TradeWelcomeBoard
      title="Insurances"
      v-if="!isUserProprtyOwner"
      description="Upload your insurance details to ensure compliance and build trust"
      actionTitle="add new insurance"
      @onClickAction="onClickAddNewInsurance"
    /> -->
    <!-- new insurance design -->
    <div
      class="tw-w-full tw-rounded-lg tw-bg-[#F8FBFD] tw-border-solid tw-border-[1px] tw-border-[#DFDFDF] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-3"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-box-border tw-border-b-dashed-custom"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-justify-start">
          <Text
            variant="h6"
            class="rmd:!tw-text-[22px]"
            lineHeight="18px"
            textAlign="left"
            >{{ isUserProprtyOwner ? "Insurances" : "Your Insurances" }}</Text
          >
          <Text
            variant="p"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.8)"
            lineHeight="18px"
            textAlign="left"
            class="md:!tw-hidden"
            >{{ userInsurances?.length }} Results</Text
          >
        </div>
        <Button
          v-if="!isUserProprtyOwner"
          variant="secondary"
          label="+Add Insurance"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onClickAddNewInsurance"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-w-full">
        <!-- items loop here -->
        <div
          v-for="(insurance, index) in userInsurances"
          :key="index"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-box-border"
          :class="{
            'tw-border-b-dashed-custom': index !== userInsurances.length - 1,
            '!tw-pt-0': index === 0,
          }"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-w-full md:!tw-gap-2">
            <div
              class="tw-min-w-[68px] tw-w-[68px] tw-h-[68px] tw-rounded-[4.85px] tw-bg-[rgba(12,15,74,0.04)] tw-border-solid tw-border-[#DFDFDF] tw-border-[1px] tw-overflow-hidden md:!tw-min-w-[55px] md:!tw-w-[55px] md:!tw-h-[55px]"
            >
              <img
                src="@/assets/images/tradepassport-empty-thumbnail.png"
                class="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div
              class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-1"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-[.1rem] tw-items-start"
              >
                <div class="tw-flex tw-items-start tw-gap-2">
                  <Text
                    variant="h6"
                    class="md:!tw-text-[.8rem]"
                    lineHeight="18px"
                    textAlign="left"
                    >{{
                      insurance?.metadata?.insuranceName ||
                      insurance?.insurance?.name
                    }}</Text
                  >
                  <PendingChip
                    class="tw-w-[auto]"
                    label="Pending Verification"
                    v-if="
                      insurance?.userVerificationStatus ===
                        UserVerificationStatusEnum.PENDING_VERIFICATION &&
                      !isExpiredDate(insurance?.metadata?.expiryDate)
                    "
                  />

                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Accepted"
                    v-if="
                      insurance?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      !isExpiredDate(insurance?.metadata?.expiryDate) &&
                      !isUserProprtyOwner
                    "
                  />
                  <AcceptChip
                    class="tw-w-[auto]"
                    label="Verified"
                    v-if="
                      insurance?.userVerificationStatus ===
                        UserVerificationStatusEnum.VERIFIED &&
                      !isExpiredDate(insurance?.metadata?.expiryDate) &&
                      isUserProprtyOwner
                    "
                  />
                  <RejectChip
                    class="tw-w-[auto]"
                    label="Rejected"
                    v-if="
                      insurance?.userVerificationStatus ===
                        UserVerificationStatusEnum.REJECTED &&
                      !isExpiredDate(insurance?.metadata?.expiryDate)
                    "
                  />
                  <RejectChip
                    class="tw-w-[auto]"
                    label="Expired"
                    v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                  />
                </div>
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                  textAlign="left"
                >
                  Coverage:
                  {{ appFilters.formatToCurrency(insurance?.metadata?.sum) }}
                </Text>
                <Text
                  variant="p"
                  textColor="rgba(12, 15, 74, 0.8)"
                  lineHeight="18px"
                  class="md:!tw-text-[.7rem]"
                  textAlign="left"
                >
                  Expiry:
                  {{ appFilters.formatToDate(insurance?.metadata?.expiryDate) }}
                </Text>
              </div>
              <div
                class="tw-flex tw-items-center tw-gap-2 md:!tw-gap-1"
                v-if="getUserWorkStationFieldAccess"
              >
                <BlueCircularPencilIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="editInsurance(insurance)"
                  v-if="!isUserProprtyOwner"
                />
                <GrayCircularTrashIcon
                  class="md:!tw-min-w-[21px] md:!tw-w-[21px] md:!tw-h-[21px]"
                  @click="onDeleteItem(insurance.id)"
                  v-if="!isUserProprtyOwner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !userInsurances?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto tw-w-full"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img md:!tw-w-[50%]">
        <img
          :src="require('../../../assets/images/trade_insurance_empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>

      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any Insurance yet!</h4>
        <p>
          You haven't added any insurance details yet. Upload your insurance to
          build trust and meet industry standards."
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any Insurance yet!</h4>
        <p>haven't added any insurance details to this profile yet</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickAddNewInsurance"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >add new insurance</v-btn
        >
      </div>
    </div>
  </div>
  <!-- <InsuranceModel
    v-if="isShowInsuranceModal"
    @onClickCloseTab="onClickCloseModel"
    :userInsuranceData="userInsuranceData"
    :isOuterEditInsurance="isOuterEditInsurance"
    @clearUserInsuranceData="clearData"
  /> -->

  <AddInsuranceModal
    v-if="isShowInsuranceModal"
    @onClickCloseTab="onClickCloseModel"
    :userInsuranceData="userInsuranceData"
    :isOuterEditInsurance="isOuterEditInsurance"
    @clearUserInsuranceData="clearData"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    type="insurance"
    label="You're about to delete your insurance."
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import ItemListCard from "@/core/components/ItemListCard.vue";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { isExpiredDate } from "@/core/utils/common";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import insurancesService from "@/core/services/insurances.service";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import Loader from "@/core/components/common/Loader.vue";
import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import AddInsuranceModal from "@/modules/insurance/components/AddInsuranceModal.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import appFilters from "@/filters";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import GrayCircularTrashIcon from "@/core/components/icons/GrayCircularTrashIcon.vue";
import { TRADE_PASSPOSRT_ROUTE } from "../routes";

const store = useStore();
const router = useRouter();

const isShowInsuranceModal = ref(false);
const isOpenConfirmDelete = ref(false);
const confirmDeleteId = ref(null) as any;
const deleteLoader = ref(false);
const isLoading = ref(false);
const userInsuranceData = ref(null) as any;

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const userInsurances = computed(
  () => store.getters[`${USER_STORE}/userInsurances`]
);
const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);
const contractorTradePassportWorkstationId = computed(
  () =>
    store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
    ]
);
const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);
const formatSum = (sum: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(sum);
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isWorkStationVerified = computed(() => {
  return (
    store.getters[`${WORKSTATION}/activeUserWorkstation`]
      ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
  );
});
const isOuterEditInsurance = ref(false);
const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);

const onRouteToTradePassport = async () => {
  await router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

const onClickAddNewInsurance = () => {
  isShowInsuranceModal.value = true;
};
const onClickCloseModel = () => {
  isShowInsuranceModal.value = false;
};
const onDeleteItem = (id: any) => {
  confirmDeleteId.value = id;
  isOpenConfirmDelete.value = true;
};

const onCloseConfirmDelete = () => {
  confirmDeleteId.value = null;
  isOpenConfirmDelete.value = false;
};
const onDeleteInsurance = async (insuranceId: string) => {
  try {
    await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
      userId: user.value.id,
      userInsuranceId: insuranceId,
    });
    if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
      const responce: any = await insurancesService.isInsuranceExpired(
        user.value.id
      );

      store.commit(
        `${INSURANCE_STORE}/setInsuranceExpired`,
        responce.isFreezed
      );
    }
    await store.dispatch(`${USER_STORE}/getAccountStatuses`);
  } catch (error) {
    console.log();
  }
};
const deleteItem = async () => {
  deleteLoader.value = true;
  await onDeleteInsurance(confirmDeleteId.value);
  deleteLoader.value = false;
  onCloseConfirmDelete();
};
const editInsurance = (insurance: object) => {
  isShowInsuranceModal.value = true;
  userInsuranceData.value = insurance;
  isOuterEditInsurance.value = true;
};
const clearData = () => {
  userInsuranceData.value = null;
};
const chipLabel = (status: any) => {
  if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
    return "Pending";
  else if (UserVerificationStatusEnum.REJECTED === status) return "Rejected";
  else if (UserVerificationStatusEnum.VERIFIED === status) return "Verified";
  else if (UserVerificationStatusEnum.NOT_ADDED === status) return "Not added";
};
const chipColor = (status: any) => {
  if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
    return "#FAA500";
  else if (UserVerificationStatusEnum.REJECTED === status) return "red";
  else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
  else if (UserVerificationStatusEnum.NOT_ADDED === status) return "#7467B7";
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}/${formattedMonth}/${year}`;
};

const isShowPendingBoard = computed(
  () =>
    isWorkStationVerified.value &&
    userInsurances.value?.some(
      (insurance: any) =>
        insurance?.userVerificationStatus ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
    ) &&
    userInsurances.value?.some(
      (insurance: any) =>
        insurance?.userVerificationStatus ===
        UserVerificationStatusEnum.VERIFIED
    )
);

onBeforeMount(async () => {
  try {
    isLoading.value = true;
    if (user.value?.id && !userInsurances.value?.length) {
      if (isUserProprtyOwner.value) {
        await store.dispatch(`${USER_STORE}/setContractorInsurances`, {
          userId: user.value.id,
          workStationId: contractorTradePassportWorkstationId.value,
        });
      } else {
        await store.dispatch(`${USER_STORE}/setUserInsurances`, user.value.id);
      }
    }
  } catch (error) {
    console.log();
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
