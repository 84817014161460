<template>
  <GeneralDialog
    class="dispute-process-modal"
    :isShowCloseButton="true"
    :isShowHeader="true"
    descriptionColor="#FAA100"
    :isTitleStrong="true"
    headerTitle="Dispute Process Overview"
    width="570px"
    maxWidth="100%"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <!-- <div class="modal-header">
            <h2 class="modal-title">Dispute Process Overview</h2>
            <v-icon
              class="cursor-pointer"
              icon="mdi-close"
              :size="15"
              @click="onClickCloseButton"
            ></v-icon>
          </div> -->
          <div class="modal-body !tw-pt-0">
            <p class="modal-body-text">
              At iknowa, we are committed to resolving disputes efficiently and
              fairly. Our process promotes early resolution between parties
              while ensuring a transparent and structured approach to achieve a
              fair outcome when required.
            </p>
            <div class="steps-list">
              <div
                v-for="(step, index) in steps"
                :key="index"
                class="step-item"
              >
                <div class="step-icon">{{ step.number }}</div>
                <div class="step-details">
                  <h3 class="step-title">{{ step.title }}</h3>
                  <div class="step-description">
                    <span></span>
                    <p>{{ step.description }}</p>
                  </div>
                  <div v-if="step.extra" class="step-description">
                    <span></span>
                    <p>{{ step.extra }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tw-mb-4 tw-rounded-[8px] tw-border-[1px] tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-flex-col tw-gap-2"
            >
              <Text class="!tw-text-[20px]" textWeight="600"
                >Evidence Guide</Text
              >
              <Text variant="span" textWeight="600" lineHeight="18px"
                >Please do not submit more than 5-10 images and files and be
                clear in your statement.<br />
                Please view the example statement & evidence.
                <Text
                  class="!tw-underline !tw-cursor-pointer"
                  variant="span"
                  textWeight="600"
                  textColor="#3B67E9"
                  lineHeight="18px"
                  @click="onOpenGuidanceModal"
                  >Click Here</Text
                ></Text
              >
            </div>
            <p class="next-steps">
              If the above aligns with your concerns, please proceed with a
              dispute. For more information for each phase please
              <a href="#" class="details-link">View Details</a>.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="button button-purple-border"
              @click="onClickCloseButton"
            >
              Cancel
            </button>
            <button class="button button-purple" @click="onProceedWithDispute">
              Proceed With A Dispute
            </button>
          </div>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";

import { ref } from "vue";
export default {
  components: {
    GeneralDialog,
    Text,
  },
  setup(props: any, ctx: any) {
    const steps = ref([
      {
        number: 1,
        title: "Raise a Dispute",
        description:
          "If a disagreement arises during a project, parties can raise a dispute by providing a reason and submitting a statement and supporting evidence.",
      },
      {
        number: 2,
        title: "Early Reconciliation (3 Business Days)",
        description:
          "Both parties are encouraged to resolve the dispute independently within 3 business days.",
        extra:
          "If no agreement is reached, the case will proceed to iknowa for review.",
      },
      {
        number: 3,
        title: "iknowa Review (Up to 7 Working Days)",
        description:
          "iknowa will review the submitted evidence and decide whether to proceed with the case or reject it.",
        extra:
          "Decisions are typically made within 48 hours but may take up to 7 working days for complex cases.",
      },
      {
        number: 4,
        title: "ADR Decision & Award",
        description:
          "If iknowa decides to proceed, our ADR (Alternative Dispute Resolution) team will review the evidence & issue a legally binding decision.",
        extra: "The award concludes the dispute process.",
      },
    ]);
    const onProceedWithDispute = () => {
      ctx.emit("onProceedContinue");
    };

    const onClickCloseButton = () => {
      ctx.emit("close");
    };

    const onOpenGuidanceModal = () => {
      ctx.emit("onOpenGuidanceModal");
    };

    return {
      onClickCloseButton,
      steps,
      onProceedWithDispute,
      onOpenGuidanceModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.dispute-process-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .modal-title {
              @include fluidFont(24, 24, 30px);
              font-weight: 600;
              color: rgba($blueDark, 1);
            }
          }
          .modal-body {
            padding: 26px 0 36px;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
            .modal-body-text {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
            .steps-list {
              margin: 26px 0 36px;
              display: flex;
              flex-direction: column;
              gap: 26px;
              .step-item {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                .step-icon {
                  width: 28px;
                  height: 28px;
                  max-width: 100%;
                  background-color: rgba($blueDark, 1);
                  border-radius: 100%;
                  text-align: center;
                  color: rgba($white, 1);
                  @include fluidFont(16, 16, 24px);
                  font-weight: 600;
                }
                .step-details {
                  width: calc(100% - 36px);
                  max-width: 100%;
                  .step-title {
                    @include fluidFont(20, 20, 30px);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                  }
                  .step-description {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    span {
                      display: inline-block;
                      width: 10px;
                      max-width: 100%;
                      height: 10px;
                      border-radius: 100%;
                      background-color: rgba($blueDark, 1);
                    }
                    p {
                      @include fluidFont(12, 12, 15.06px);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      width: calc(100% - 18px);
                      max-width: 100%;
                    }
                  }
                }
              }
            }
            .next-steps {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
              a {
                @include fluidFont(14, 14, 22px);
                font-weight: 700;
                color: rgba(51, 102, 255, 1);
                text-decoration: underline;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
</style>
