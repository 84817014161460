export enum DisputeTypeEnums {
  RAISED = "raise",
  UNDER_REVIEW = "review",
  RESOLVED = "resolved",
  REJECTED = "rejected",
  CLOSED = "closed",
  ESCALATED = "escalated",
  ADR_REVIEW = "adr_review",
}

export enum DisputeStatus {
  STARTED = "Dispute Started",
  FINISHED = "Dispute Finished",
}
