<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px]">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-7 tw-w-full"
        >
          <TrashIcon
            fillOpacity="1"
            fill="#F45454"
            class="tw-w-[27px] tw-h-[35px]"
          />
          <Text variant="h4" textAlign="center">Delete Confirmation</Text>
          <Text variant="p" textWeight="400" textAlign="center">
            Are you sure you want to delete the Candidate<br />
            <strong>
              {{
                candidate.toUser
                  ? candidate.toUser?.firstName +
                    " " +
                    candidate.toUser?.lastName
                  : candidate.email
              }}?</strong
            >
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3 tw-pt-4 tw-border-t-dashed-custom"
      >
        <Button
          variant="secondary"
          label="cancel"
          :isCapitalize="true"
          @click="onCloseModal"
          class="!tw-w-auto"
        ></Button>
        <Button
          variant="accent"
          label="submit"
          :isCapitalize="true"
          @click="onDelete"
          class="!tw-w-auto"
        ></Button>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";

const emits = defineEmits(["on-close", "on-confirm"]);
const props = defineProps({
  candidate: {
    type: Object,
    default: null,
  },
});
const internalInstance = getCurrentInstance();

const router = useRouter();

const loading = ref(false);

const onCloseModal = () => {
  emits("on-close");
};

const onDelete = async () => {
  emits("on-confirm");
};
</script>
