<template>
  <div
    class="profile-logo"
    :class="{
      '!tw-rounded-lg': !isRounded,
      'tw-rounded-[50%]': isRounded,
      'rlg:!tw-text-[16px]': desktopFontSizeMid,
      'rlg:!tw-text-[24px]': desktopFontSizeBig,
      'rlg:!tw-text-[10px]': !desktopFontSizeBig,
      'md:!tw-text-[16px]': mobileFontSizeBig,
      'md:!tw-text-[10px]': !mobileFontSizeBig,
    }"
    :style="{ backgroundColor: logoBackgroundColor }"
  >
    {{ userNameLogoChar }}
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  props: {
    userName: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#4a92ef",
    },
    isRounded: {
      type: Boolean,
      default: true,
    },
    desktopFontSizeBig: {
      type: Boolean,
      default: false,
    },
    desktopFontSizeMid: {
      type: Boolean,
      default: false,
    },
    mobileFontSizeBig: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const userNameLogoChar = computed(() => {
      if (activeUserWorkstation.value?.name === "Default") {
        return (
          (user.value?.firstName ? user.value?.firstName[0] : "") +
          (user.value?.lastName ? user.value?.lastName[0] : "")
        );
      }
      if (props?.userName !== "") {
        return getInitials(props?.userName);
      }
      return getInitials(activeUserWorkstation.value?.name);
    });

    const getInitials = (fullName: string) => {
      if (fullName !== "" && fullName !== null && fullName !== undefined) {
        const nameParts = fullName.split(" ").filter((part) => part !== "");

        if (nameParts.length === 1) {
          return nameParts[0][0].toUpperCase();
        } else if (nameParts.length >= 2) {
          const firstInitial = nameParts[0][0].toUpperCase();
          const lastInitial = nameParts[nameParts.length - 1][0].toUpperCase();
          return firstInitial + lastInitial;
        }
      }

      return "IK";
    };

    const logoBackgroundColor = computed(() => props.color || "#4a92ef");

    return { userNameLogoChar, logoBackgroundColor };
  },
};
</script>
<style lang="scss" scoped>
.profile-logo {
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;

  @include respond(sm) {
    font-size: 10px;
  }
}
</style>
