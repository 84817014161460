<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="655px"
    max-width="100%"
    :className="['trade_passport_preview_modal ', ' md:!tw-overflow-[unset]']"
  >
    <template v-slot:body>
      <div
        class="header md:!tw-right-[-13px] tw-z-[1] tw-flex tw-items-center tw-justify-center"
      >
        <v-icon
          class="cursor-pointer"
          icon="mdi-close"
          :size="15"
          @click="onClickCloseButton"
        ></v-icon>
      </div>
      <v-carousel
        height="auto"
        class=""
        style="overflow: initial"
        hide-delimiter-background
        delimiter-icon="mdi-square"
        :show-arrows="false"
        :continuous="false"
        v-model="model"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-sheet>
            <div class="md:!tw-flex md:!tw-flex-col md:!tw-gap-[.5rem]">
              <div class="carousel__img tw-m-auto tw-relative !tw-w-4/5">
                <img
                  width="90"
                  class="tw-relative tw-z-10 !tw-object-contain"
                  :src="item.src"
                />
                <!-- <img
                  class="tw-w-full !tw-h-auto tw-absolute tw-z-0 tw-top-[-16px] tw-right-0 tw-object-contain rmd:!tw-top-[-27px]"
                  :src="require('@/assets/images/benefit-blured-bg-1.png')"
                /> -->
              </div>
              <div class="trade_passport_preview_modal_title">
                {{ item?.title }}
              </div>
              <div class="trade_passport_preview_modal_text">
                {{ item?.description }}
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="carousel-controls">
        <v-btn
          variant="plain"
          class="skip_btn"
          @click="skipcontinue"
          v-if="model !== 3"
        >
          Skip
        </v-btn>

        <div class="right_btns">
          <Button
            class="!tw-w-auto"
            variant="secondary"
            label="Cancel"
            :disabled="model === 0"
            @click="onClickCloseButton"
          />
          <Button
            class="!tw-w-auto"
            variant="accent"
            :label="model === items.length - 1 ? 'Continue' : 'Next'"
            @click="handleNextClick"
          />
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { ref } from "vue";
import Button from "@/core/components/ui/general/Button.vue";

export default {
  components: {
    CommonDialog,
    Button,
  },
  setup(props: any, ctx: any) {
    const model = ref(0);

    const items = ref([
      {
        src: require("@/assets/images/raising.png"),
        title: "Raising a Dispute",
        description:
          "If a disagreement arises during a project, parties can raise a dispute by providing a reason and submitting a statement and supporting evidence.",
      },
      {
        src: require("@/assets/images/reconciliation.png"),
        title: "Early Reconciliation",
        description:
          "Both parties are encouraged to resolve the dispute independently within 3 business days, failing which the case will proceed to iknowa for review.",
      },
      {
        src: require("@/assets/images/review.png"),
        title: "iknowa Review",
        description:
          "iknowa will review the submitted evidence and decide whether to proceed with or reject the case, with decisions typically made within 48 hours, though complex cases may take up to 7 working days.",
      },
      {
        src: require("@/assets/images/adr-decision.png"),
        title: "ADR Decision & Award",
        description:
          "If iknowa decides to proceed, our ADR team will review the evidence and issue a legally binding decision, which will conclude the dispute process.",
      },
    ]);

    const handleNextClick = () => {
      if (model.value === items.value.length - 1) {
        ctx.emit("showDspOverviewModal");
      } else {
        model.value++;
      }
    };
    const skipcontinue = () => {
      ctx.emit("showDspOverviewModal");
    };
    const prevSlide = () => {
      if (model.value > 0) {
        model.value--;
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("close");
    };

    return {
      onClickCloseButton,
      items,
      model,
      handleNextClick,
      prevSlide,
      skipcontinue,
    };
  },
};
</script>
<style lang="scss" scoped>
.trade_passport_preview_modal .carousel-controls:has(.skip_btn) {
  justify-content: space-between !important;
}
</style>
