import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c5698bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-py-8 tw-box-border llg:!tw-py-2" }
const _hoisted_2 = { class: "tw-flex tw-items-center tw-flex-col tw-justify-start tw-gap-2 tw-mr-2" }
const _hoisted_3 = {
  key: 0,
  class: "tw-min-w-[2px] tw-w-[2px] tw-h-[112px] tw-bg-[rgba(12,15,74,.20)]"
}
const _hoisted_4 = { class: "tw-w-full tw-flex tw-items-start tw-gap-3 llg:!tw-flex-col" }
const _hoisted_5 = { class: "tw-min-w-[146px] tw-w-[146px] tw-h-[130px] tw-overflow-hidden llg:!tw-min-w-[100px] llg:!tw-w-[100px] llg:!tw-h-[70px]" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Button = _resolveComponent("Button")!
  const _component_WizardModal = _resolveComponent("WizardModal")!
  const _component_KYCStatusModal = _resolveComponent("KYCStatusModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.isShowKYBInfoModal)
      ? (_openBlock(), _createBlock(_component_WizardModal, {
          key: 0,
          hideFooter: true,
          hideBackButton: true,
          singleData: $setup.getModalData,
          hideTitleAndDescription: true,
          onOnClose: $props.onClickCloseTab,
          width: "968px",
          height: "1000px"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tw-w-full tw-flex tw-items-start tw-gap-3",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_Text, {
                      variant: "h6",
                      whiteSpace: "nowrap",
                      class: "llg:!tw-text-[.8rem]"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Step " + _toDisplayString(step.step), 1)
                      ]),
                      _: 2
                    }, 1024),
                    (index !== $setup.steps.length - 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        src: require(`@/assets/images/kyc-steps/${step.image}.png`),
                        class: "tw-w-full tw-h-full tw-object-contain"
                      }, null, 8, _hoisted_6)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_Text, {
                        variant: "h3",
                        textWeight: "400",
                        class: "llg:!tw-text-[20px]"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(step.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Text, {
                        variant: "h6",
                        textWeight: "400",
                        class: "llg:!tw-text-[12px] llg:!tw-leading-[18px]"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(step.description), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]))
              }), 128)),
              ($setup.typeOfVerification === 'KYC')
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    variant: "accent",
                    label: "Start ID verification",
                    isLoading: $setup.isLoading,
                    onClick: $setup.onClickKycTypeIdVerification
                  }, null, 8, ["isLoading", "onClick"]))
                : _createCommentVNode("", true),
              ($setup.typeOfVerification === 'KYB')
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    variant: "accent",
                    label: "Start ID verification",
                    isLoading: $setup.isLoading,
                    onClick: $setup.onClickIdVerification
                  }, null, 8, ["isLoading", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["singleData", "onOnClose"]))
      : _createCommentVNode("", true),
    ($setup.isShowKYBInfoModal)
      ? (_openBlock(), _createBlock(_component_KYCStatusModal, {
          key: 1,
          onContinue: $props.onClickCloseTab,
          kycStatus: "KYBINFO"
        }, null, 8, ["onContinue"]))
      : _createCommentVNode("", true)
  ], 64))
}