<template>
  <GeneralDialog
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerTitle="Phases & Contractors"
    headerDescription="Please help us understand which part of the project & which trade contractor are you referring to by selecting all that apply."
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    width="655px"
    maxWidth="100%"
  >
    <template #body>
      <section
        class="tw-w-full tw-flex tw-flex-col tw-pt-6 tw-border-t-dashed-custom"
      >
        <v-form
          ref="disputeForm"
          @submit.prevent
          :rules="disputeProjectFormRules"
          class="tw-pb-4"
        >
          <Text variant="h5" textWeight="18px">The dispute is related to:</Text>
          <v-radio-group
            v-model="disputeType"
            :rules="disputeProjectFormRules.disputeType"
          >
            <v-radio label="The Whole Project" value="project"></v-radio>
            <v-radio label="Phases" value="phases"></v-radio>

            <div v-if="disputeType === 'phases'">
              <v-select
                :rules="disputeProjectFormRules.validatePhases"
                density="compact"
                variant="outlined"
                v-model="selectedPhases"
                :items="estimatePhases"
                :item-title="formatPhaseTitle"
                item-value="id"
                label="Select Phases"
                multiple
                dense
                hide-details
                no-data-text="No completed phases available."
              ></v-select>
            </div>
          </v-radio-group>
        </v-form>
        <div
          v-if="isPropertyOwner"
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-pt-6 tw-border-t-dashed-custom"
        >
          <Text variant="h5" textWeight="18px"
            >I have concerns with the following trade contractor:</Text
          >
          <div
            class="contractor-list tw-w-full tw-grid tw-gap-3"
            v-if="contractorList.length"
          >
            <div
              @click="toggleSelection(contractor?.id)"
              v-for="(contractor, index) in contractorList"
              :key="index"
              class="tw-rounded-[8px] tw-border-[1px] tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-w-full tw-p-2 tw-h-[55px] tw-box-border tw-flex tw-items-center tw-gap-2"
              :class="{
                '!tw-border-[#3366FF] !tw-bg-[rgba(51,102,255,0.06)]':
                  isSelected(contractor?.id),
              }"
            >
              <div
                class="member_img tw-w-[35px] tw-max-w-full tw-h-[35px] tw-rounded-full"
              >
                <img
                  src="@/assets/images/profile-dashboard-image.png"
                  class="tw-w-full tw-h-full tw-object-cover"
                  alt=""
                />
              </div>
              <Text variant="span" textWeight="400">{{ contractor.name }}</Text>
            </div>
          </div>
          <div
            v-else
            class="tw-w-full tw-flex tw-items-center tw-justify-center"
          >
            <Text>No Available Contractors</Text>
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pt-6 tw-box-border tw-border-t-dashed-custom tw-gap-4"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          label="Cancel"
          @click="onClickCloseButton"
        />
        <Button
          class="!tw-w-auto"
          variant="accent"
          label="Continue"
          @click="onContinueDisputeProject"
        />
      </section>
    </template>
  </GeneralDialog>
  <!-- <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="655px"
    max-width="100%"
    class="dispute-process-modal"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <div class="title">
              <h2 class="modal-title">Phases & Contractors</h2>
              <v-icon
                class="cursor-pointer"
                icon="mdi-close"
                :size="15"
                @click="onClickCloseButton"
              ></v-icon>
            </div>
            <p>
              Please help us understand which part of the project & which trade
              contractor are you referring to by selecting all that apply.
            </p>
          </div>
          <div class="modal-body">
            <div>
              <v-form
                ref="disputeForm"
                @submit.prevent
                :rules="disputeProjectFormRules"
              >
                <p>The dispute is related to:</p>
                <v-radio-group
                  v-model="disputeType"
                  :rules="disputeProjectFormRules.disputeType"
                >
                  <v-radio label="The Whole Project" value="project"></v-radio>
                  <v-radio label="Phases" value="phases"></v-radio>
                  <div v-if="disputeType === 'phases'">
                    <v-select
                      :rules="disputeProjectFormRules.validatePhases"
                      density="compact"
                      variant="outlined"
                      v-model="selectedPhases"
                      :items="estimatePhases"
                      item-title="name"
                      item-value="id"
                      label="Select Phases"
                      multiple
                      dense
                      hide-details
                    ></v-select>
                  </div>
                </v-radio-group>
              </v-form>
            </div>

            <div>
              <h3>I have concerns with the following trade contractor:</h3>

              <div class="contractor_card_list">
                <v-card
                  class="courses__card"
                  @click="toggleSelection(contractor?.id)"
                  v-for="(contractor, index) in contractorList"
                  :key="index"
                  :class="{ 'selected-card': isSelected(contractor.id) }"
                >
                  <div>
                    <p>
                      {{ contractor.name }}
                    </p>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="button button-purple-border"
              @click="onClickCloseButton"
            >
              Cancel
            </button>
            <button
              class="button button-purple"
              @click="onContinueDisputeProject"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog> -->
</template>

<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";

const emits = defineEmits(["close", "continue", "clearphaseData"]);
const props = defineProps({
  estimateData: {
    type: Object,
    default: null,
  },
  phaseData: {
    type: Object,
    default: null,
  },
});
const store = useStore();
const disputeForm = ref(null) as any;
const selectedPhases = ref([]) as any;
const disputeType = ref("project");
const selectedContractorIds = ref([]) as any;
const escrowAccount = ref();

const toggleSelection = (id: number) => {
  const selectedIndex = selectedContractorIds.value.indexOf(id);
  if (selectedContractorIds.value.includes(id)) {
    selectedContractorIds.value.splice(selectedIndex, 1);
  } else {
    selectedContractorIds.value.push(id);
  }
  console.log(selectedContractorIds.value, "selectedContractorIds");
  // const selectedIndex = selectedContractorIds.value.indexOf(id);
  // if (selectedIndex === -1) {
  //   selectedContractorIds.value.push(id);
  // } else {
  //   selectedContractorIds.value.splice(selectedIndex, 1);
  // }
};
const isSelected = (id: number) => {
  return selectedContractorIds.value.includes(id);
};
const disputeProjectFormRules = ref({
  disputeType: [
    (value: string) => !!value || "Please select the dispute type.",
  ],
  validatePhases: [
    (value: any[]) =>
      disputeType.value !== "phases" ||
      (value && value.length > 0) ||
      "Please select at least one phase.",
  ],
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const estimatePhases = computed(() => {
  let phases = [] as any;

  const raiseDisputePhase = props?.phaseData;

  if (raiseDisputePhase) {
    if (
      raiseDisputePhase.escrowStatus &&
      raiseDisputePhase.escrowStatus.name === "Stage Complete"
    ) {
      phases.push(raiseDisputePhase);
    }
  } else {
    props.estimateData?.estimationPhase.forEach((phase: any) => {
      if (phase.phaseType === 1) {
        console.log("Escrow Sttaus", phase);
        if (
          phase.escrowStatus &&
          phase.escrowStatus.name === "Stage Complete"
        ) {
          phases.push(phase);
        }
      }
    });
  }

  return phases;
});

const isPropertyOwner = computed(() => {
  return user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER;
});

const contractorList = computed(() => {
  const initialContractors = props.estimateData?.inviteContractors.map(
    (contractor: any) => contractor?.toContractorWorkstation
  );

  const mainContractor = props.estimateData?.workStation;

  if (
    mainContractor &&
    !initialContractors.some(
      (contractor: any) => contractor.id === mainContractor.id
    )
  ) {
    initialContractors.push(mainContractor);
    selectedContractorIds.value.push(mainContractor.id);
  }

  return initialContractors;
});

const onClickCloseButton = () => {
  emits("close");
};
const formatPhaseTitle = (phase: any) => {
  return `Phase-${phase.phaseOrder + 1}: ${phase.name}`;
};

const onContinueDisputeProject = async () => {
  const isFormValid = await disputeForm.value.validate();

  if (!isFormValid.valid) return;

  emits("continue", {
    phases: selectedPhases.value,
    contractorIds: selectedContractorIds.value,
  });
};

onMounted(() => {
  const raiseDisputePhase = props?.phaseData;

  if (raiseDisputePhase) {
    disputeType.value = "phases";
    selectedPhases.value.push(raiseDisputePhase.id);
  }
});
onUnmounted(() => {
  emits("clearphaseData");
});
</script>
<style lang="scss" scoped>
.contractor-list {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
</style>
