<template>
  <commonDialog
    :isShowBackButton="false"
    :isShowHeader="false"
    :className="['des_editor_modal']"
    width="600px"
    max-width="100%"
    height="auto"
  >
    <template v-slot:body>
      <div
        class="dashboard-welcome__close !tw-top-8 !tw-right-8"
        @click="onClickCloseTab"
      >
        <v-icon icon="mdi-close"></v-icon>
      </div>
      <div class="title !tw-flex tw-flex-col tw-gap-2">
        <Text class="!tw-text-[20px]" textWeight="400"
          >{{ HeaderTittle }} About</Text
        >
        <Text
          variant="span"
          textWeight="400"
          textColor="rgba(12, 15, 74, 0.5)"
          lineHeight="18px"
        >
          Describe your work, achievements and little bit about yourself
        </Text>
      </div>
      <Text variant="span" textWeight="400">Description</Text>
      <div class="custom_text_editor">
        <div
          v-show="showButtonForGeminiModel"
          id="quill-cursor-box"
          class="temp-box desktop__cursor"
        ></div>

        <v-menu
          v-model="geminiModalMenu"
          activator="#quill-cursor-box"
          transition="scale-transition"
          target="cursor"
          location="bottom"
          :close-on-content-click="false"
          :close-on-back="false"
          class="desktop__cursor"
        >
          <GeminiModel
            @click.stop
            @appendJobDescription="appendJobDescription"
            @onClose="onCloseHandler"
          />
        </v-menu>
        <quill-editor
          v-model:value="state.content"
          :options="state.editorOption"
          :disabled="state.disabled"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
          @change="onEditorChange($event)"
          class="editor_ql ql-editor"
        />
        <!-- <div id="counter" class="counter_text">0/1000</div> -->
      </div>
      <div
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3 tw-pt-4 tw-box-border tw-border-t-dashed-custom"
      >
        <Button
          class="!tw-w-auto"
          label="Cancel"
          variant="secondary"
          :isCapitalize="true"
          @click="onClickCloseTab"
        ></Button>
        <Button
          class="!tw-w-auto"
          label="Save"
          variant="accent"
          :isCapitalize="true"
          @click="onClickSaveDescription"
        ></Button>
      </div>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { reactive, onBeforeMount, ref } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { quillEditor } from "vue3-quill";
import GeminiModel from "@/modules/project/components/common/GeminiModel.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";

export default {
  components: {
    CommonDialog,
    quillEditor,
    GeminiModel,
    Button,
    Text,
  },
  props: {
    HeaderTittle: {
      type: String,
    },
    userDescriptionData: {
      type: String,
    },
  },
  setup(props: any, ctx: any) {
    const geminiModalMenu = ref(false);
    const showGeminiModel = ref(false);
    const showGeminiModelForMobile = ref(false);

    onBeforeMount(() => {
      state.content = props.userDescriptionData;
    });

    const onClickCloseTab = () => {
      ctx.emit("onClickCloseTab");
    };
    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        wordCounter: {
          container: "#counter",
          unit: "word",
        },
        // more options
      },
      disabled: false,
    });

    const onEditorBlur = (quill: any) => {
      console.log("editor blur!", quill);
    };
    const showButtonForGeminiModel = ref(false);
    const quillInstance = ref();
    const quillCursorIndex = ref(0);
    const onEditorFocus = (quill: any) => {
      try {
        showButtonForGeminiModel.value = true;
        quillInstance.value = quill;
        const range = quill.getSelection();
        quill.insertText(range?.index, "");
      } catch (error) {
        console.log(error);
      }
    };
    const onEditorReady = (quill: any) => {
      console.log("editor ready!", quill);
    };
    const onEditorChange = ({
      quill,
      html,
      text,
    }: {
      quill: any;
      html: string;
      text: string;
    }) => {
      try {
        quillInstance.value = quill;
        showButtonForGeminiModel.value = false;

        const range = quill.getSelection();
        const [clientX, clientY]: any = getCaretCoordinates(
          quill,
          range?.index
        );

        const toolbarElement = document.querySelector(
          ".ql-toolbar"
        ) as HTMLElement;
        const topPadding = toolbarElement.offsetHeight;

        const cursorBox: any = document.getElementById("quill-cursor-box");
        cursorBox.style.top = `${clientY + topPadding + 20}px`;
        cursorBox.style.left = `${clientX + 10}px`;

        state._content = html;
        quillCursorIndex.value = range?.index;
        showButtonForGeminiModel.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    function getCaretCoordinates(quill: any, index: any) {
      try {
        const editorBounds = quill?.container.getBoundingClientRect();
        const range = document.createRange();
        const leaf = quill?.getLeaf(index)[0];
        const node = leaf?.domNode;
        const offset = index - leaf?.offset(quill.root);

        range.setStart(node, offset);
        range.setEnd(node, offset);

        const rect = range.getBoundingClientRect();
        return [rect.left - editorBounds?.left, rect.top - editorBounds?.top];
      } catch (error) {
        console.log(error);
      }
    }

    const onClickSaveDescription = () => {
      ctx.emit("userDescriptionData", state.content);
      ctx.emit("onClickCloseTab");
    };

    const appendJobDescription = (description: string) => {
      quillInstance.value.focus();
      quillInstance.value?.insertText(
        quillCursorIndex.value,
        description.replaceAll("\n", " ")
      );
      onCloseHandler();
    };
    const onCloseHandler = () => {
      geminiModalMenu.value = false;
      showGeminiModel.value = false;
      showGeminiModelForMobile.value = false;
      quillInstance.value.focus();
    };

    return {
      onClickCloseTab,
      onEditorBlur,
      onEditorFocus,
      onEditorReady,
      onEditorChange,
      state,
      showButtonForGeminiModel,
      onClickSaveDescription,
      geminiModalMenu,
      appendJobDescription,
      onCloseHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/dashboard/styles/DescriptionEditorComponent.scss";
</style>
