<template>
  <div
    class="tw-text-left tw-max-w-full rsm:tw-max-w-[370px] tw-fw-full tw-mx-auto"
  >
    <div class="tw-text-[24px] tw-font-semibold tw-mb-4">
      <p>Time to check your email for the code we have just sent to you.</p>
    </div>
    <Text
      variant="span"
      textWeight="500"
      class="!tw-text-[14px] tw-block tw-mb-4 tw-text-[#0C0F4A80]"
      lineHeight="16px"
      >iknowa has sent a code to
      <strong class="tw-text-[#0C0F4A]">{{ user?.email }}</strong>
    </Text>

    <a
      href="#"
      @click="resendEmail"
      class="tw-text-[14px] tw-items-center tw-font-bold tw-inline-flex tw-text-[#FFA500] tw-underline"
    >
      Resend email
      <div
        class="tw-transition-all tw-duration-300 tw-ease-in-out tw-text-[#FFA500]"
      >
        <v-icon icon="mdi-chevron-right"></v-icon>
      </div>
    </a>

    <div class="tw-mt-12">
      <v-otp-input
        v-model="otpValue"
        length="4"
        variant="solo-filled"
        class=""
        bg-color="#ffffff"
      ></v-otp-input>
      <p class="error_msg" v-if="otpErrorMessage">
        {{ otpErrorMessage }}
      </p>
    </div>
    <div class="tw-mt-12">
      <Button
        class="w-full"
        label="verify email & continue"
        variant="accent"
        :disabled="otpValue?.length !== 4"
        @click="verifyEmailWithOTP"
      ></Button>
    </div>
  </div>
</template>

<script setup>
import { displayToastMessage } from "@/core/utils/common";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { USER_STORE } from "@/store/modules/user";
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import Button from "@/core/components/ui/general/Button.vue";

const otpValue = ref();
const emits = defineEmits(["onNext"]);
const store = useStore();
const otpErrorMessage = ref();
const internalInstance = getCurrentInstance();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const resendEmail = async () => {
  try {
    await store.dispatch(
      `${AUTHENTICATION_STORE}/resendEmailOTP`,
      user.value.email
    );
    displayToastMessage(
      internalInstance,
      "Resend Mail Successfully !",
      "success"
    );
  } catch (error) {
    console.log("error", error);
  }
};

const verifyEmailWithOTP = async () => {
  const data = {
    email: user.value.email,
    code: Object.values(otpValue.value).join().replaceAll(",", ""),
  };
  try {
    await store.dispatch(`${AUTHENTICATION_STORE}/verifyCode`, data);
    nextStep();
  } catch (error) {
    otpErrorMessage.value = "Invalid Code";
    console.log("error", error);
  }
};
const nextStep = () => {
  emits("onNext");
};
</script>

<style scoped lang="scss">
.v-btn {
  width: 100%;
}

:deep(.v-otp-input) {
  padding: 0;
  justify-content: flex-start;

  .v-otp-input__content {
    height: 56px;
    gap: 16px;
    padding: 0;
    max-width: 272px;
  }

  .v-field {
    height: 100%;
    box-shadow: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    font-weight: 500;

    .v-field__overlay {
      display: none;
    }
  }
}
.error_msg {
  @include fluidFont(14, 14, 1.2);
  font-weight: $fontMedium;
  letter-spacing: 0.4px;
  color: rgba(244, 84, 84, 1);
  margin: 18px 0;
}
</style>
