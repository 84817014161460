<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['assign_member_update_certificate_modal']"
    width="650px"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="title">
          <h4>Certificates</h4>
          <v-btn
            icon="mdi-close"
            @click="onClickCloseButton"
            variant="text"
          ></v-btn>
        </div>
        <div class="modal_form">
          <div class="certificate__content" v-if="!loader">
            <h5>Assign Certificate Members</h5>
            <div class="members__list">
              <ul class="members__list__status">
                <li v-for="data in filterCertificateData" :key="data.id">
                  <v-checkbox
                    color="indigo"
                    v-model="checkedMemberCertificates"
                    :value="data?.id"
                  />

                  <p>
                    {{ data.name }}
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="footer">
            <v-btn
              class="button button-purple-border"
              @click="onClickCloseButton"
              >cancel</v-btn
            >
            <v-btn
              class="button button-purple"
              :disabled="loader"
              @click="saveMemberCertificates"
              >save</v-btn
            >
          </div>
        </div>
        <div class="loader">
          <CommonLoader v-if="loader" :loading="loader" />
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { onMounted } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";

const emits = defineEmits(["onClickCloseTab", "on-submit"]);
const props = defineProps({
  userCandidateData: { type: Object, default: null },
});

const store = useStore();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const checkedMemberCertificates = ref([]) as any;
const filterCertificateData = ref([]) as any;
const loader = ref(false);
const userCertificates = computed(
  () => store.getters[`${USER_STORE}/userCertificates`]
);

const saveMemberCertificates = async () => {
  const certificates = props.userCandidateData?.certificateEligible?.data || [];

  const newAssignedUserIds = checkedMemberCertificates.value.filter((id: any) =>
    certificates.some((cert: any) => cert.id === id && !cert.isEligible)
  );
  const deletedAssignedUserId = certificates
    .filter(
      (crt: any) =>
        !checkedMemberCertificates.value.includes(crt.id) && crt.isEligible
    )
    .map((crt: any) => crt.id);
  if (newAssignedUserIds.length || deletedAssignedUserId.length) {
    for (const crt of props.userCandidateData.certificateEligible
      .userCertificate) {
      if (newAssignedUserIds.includes(crt?.certificate?.id)) {
        try {
          await store.dispatch(`${USER_STORE}/addCertificateRelatedMembers`, {
            userId: user?.value?.id,
            certificateRecordId: crt.id,
            teamMemberIds: {
              teamMemberIds: props.userCandidateData.toUser.id
                ? [props.userCandidateData.toUser.id]
                : [],
            },
          });
        } catch (error) {
          console.error(`Error assigning certificate ${crt.id}:`, error);
        }
      }
    }

    if (deletedAssignedUserId.length) {
      for (const crt of props.userCandidateData.certificateEligible
        .userCertificate) {
        if (deletedAssignedUserId.includes(crt?.certificate?.id)) {
          try {
            await store.dispatch(
              `${USER_STORE}/removeCertificateRelatedMembers`,
              {
                userId: user?.value?.id,
                certificateRecordId: crt.id,
                teamMemberIds: {
                  teamMemberIds: props.userCandidateData.toUser.id
                    ? [props.userCandidateData.toUser.id]
                    : [],
                },
              }
            );
          } catch (error) {
            console.error(`Error deleting certificate ${crt.id}:`, error);
          }
        }
      }
    }

    await store.dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id);
  }
  emits("on-submit");
};

const onClickCloseButton = () => {
  emits("onClickCloseTab");
};

onMounted(() => {
  if (props.userCandidateData)
    checkedMemberCertificates.value =
      props.userCandidateData.certificateEligible.data
        .filter((cert: any) => cert.isEligible)
        .map((cert: any) => cert.id);
  if (userCertificates.value) {
    const filterData = [] as any;

    props.userCandidateData.certificateEligible.data.forEach((crt: any) => {
      const isCert = userCertificates.value.find(
        (cert: any) => cert?.certificate?.id === crt.id
      );

      if (isCert) filterData.push(crt);
    });

    filterCertificateData.value = filterData;
  }
});
</script>

<style lang="scss" scoped>
.modal_content {
  margin: 0;
  padding: 0;
}

.modal_content {
  .title {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    h4 {
      @include fluidFont(20, 20, 24px);
      font-weight: 700;
      color: rgba($blueDark, 1);
    }
  }
  .modal_form {
    .certificate__content {
      h5 {
        @include fluidFont(18, 18, 24px);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
      .members__list {
        margin-top: 16px;
        .members__list__status {
          display: flex;
          gap: 8px;
          flex-direction: column;
          li {
            display: flex;
            align-items: center;
            gap: 8px;
            p {
              @include fluidFont(12, 12, 17.57px);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 24px;
    border-top: 1px dashed rgba($bordercolor, 1);
    gap: 8px;
  }
}
.v-checkbox {
  :deep(.v-input__control) {
    .v-selection-control {
      min-height: auto;
      .v-selection-control__wrapper {
        height: auto;
        width: auto;
        .v-selection-control__input {
          width: auto;
          height: auto;
          .v-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
