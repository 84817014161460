<template>
  <div
    class="tw-text-left tw-max-w-full rsm:tw-max-w-[342px] tw-w-full tw-mx-auto tw-px-4 md:!tw-px-0"
  >
    <div class="tw-text-[24px] tw-font-semibold tw-mb-10">
      <p>{{ heading }}</p>
    </div>

    <v-text-field
      v-model="model"
      ref="inputField"
      density="compact"
      :label="label"
      variant="outlined"
      class="c-input-border rounded-8px tw-mb-2"
      :rules="computedRules"
      :disabled="isDisable"
      @input="onFieldBlur"
    ></v-text-field>

    <Button
      class="w-full"
      variant="accent"
      label="NEXT"
      :loading="isLoading"
      :disabled="!model || isLoading"
      @click="nextStep"
    ></Button>

    <Text
      v-if="isShowLoginBtn"
      class="tw-mt-6 tw-block"
      textAlign="center"
      textWeight="500"
      textColor="rgba(12, 15, 74, 0.5)"
      fontSize="14px"
      >Already have an account?
      <a
        @click="onClickGotoLogin"
        class="tw-font-bold tw-inline-flex tw-ml-2 tw-text-[#FFA500] tw-underline tw-hover:no-underline tw-group tw-cursor-pointer"
        >Login Here
        <div
          class="tw-transition-all tw-duration-300 tw-ease-in-out tw-text-[#FFA500] tw-group-hover:translate-x-1 tw-group-hover:text-secondary"
        >
          <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
        </div>
      </a>
    </Text>
  </div>
</template>

<script setup>
import { computed, defineModel } from "vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/uni-components/Text.vue";
import { useRouter } from "vue-router";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { ref } from "vue";

const props = defineProps([
  "heading",
  "label",
  "rules",
  "isLoading",
  "isShowLoginBtn",
  "isDisable",
]);
const emits = defineEmits(["onNext"]);
const model = defineModel();
const router = useRouter();
const inputField = ref(null);
const touched = ref(false);
const nextStep = async () => {
  const validation = await inputField.value?.validate();
  if (model.value && !validation.length) {
    touched.value = false;
    emits("onNext");
  }
};
const computedRules = computed(() => {
  if (!touched.value) return [];
  return props.rules || [];
});

const onFieldBlur = () => {
  touched.value = true;
};

const onClickGotoLogin = () => {
  router.push({ name: LOGIN_ROUTE });
};
</script>

<style scoped>
.v-btn {
  width: 100%;
}

:deep(.v-text-field .v-field) {
  height: 42px;
  background-color: #fff;
  color: #0c0f4a;
  border-radius: 8px;
  font-weight: 500;
}

:deep(.v-text-field .v-field .v-field__outline) {
  --v-field-border-width: 2px;
  --v-field-border-opacity: 0.51;
}

:deep(
    .v-text-field .v-field--variant-outlined .v-label.v-field-label--floating
  ) {
  background: linear-gradient(360deg, #ffffff 0%, #e7f3ff 95%);
}
</style>
