import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4161c278"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title !tw-flex tw-flex-col tw-gap-2" }
const _hoisted_2 = { class: "custom_text_editor" }
const _hoisted_3 = {
  id: "quill-cursor-box",
  class: "temp-box desktop__cursor"
}
const _hoisted_4 = { class: "tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3 tw-pt-4 tw-box-border tw-border-t-dashed-custom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_Text = _resolveComponent("Text")!
  const _component_GeminiModel = _resolveComponent("GeminiModel")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_quill_editor = _resolveComponent("quill-editor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_commonDialog = _resolveComponent("commonDialog")!

  return (_openBlock(), _createBlock(_component_commonDialog, {
    isShowBackButton: false,
    isShowHeader: false,
    className: ['des_editor_modal'],
    width: "600px",
    "max-width": "100%",
    height: "auto"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", {
        class: "dashboard-welcome__close !tw-top-8 !tw-right-8",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onClickCloseTab && $setup.onClickCloseTab(...args)))
      }, [
        _createVNode(_component_v_icon, { icon: "mdi-close" })
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Text, {
          class: "!tw-text-[20px]",
          textWeight: "400"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.HeaderTittle) + " About", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Text, {
          variant: "span",
          textWeight: "400",
          textColor: "rgba(12, 15, 74, 0.5)",
          lineHeight: "18px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Describe your work, achievements and little bit about yourself ")
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_Text, {
        variant: "span",
        textWeight: "400"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Description")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
          [_vShow, $setup.showButtonForGeminiModel]
        ]),
        _createVNode(_component_v_menu, {
          modelValue: $setup.geminiModalMenu,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.geminiModalMenu) = $event)),
          activator: "#quill-cursor-box",
          transition: "scale-transition",
          target: "cursor",
          location: "bottom",
          "close-on-content-click": false,
          "close-on-back": false,
          class: "desktop__cursor"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GeminiModel, {
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
              onAppendJobDescription: $setup.appendJobDescription,
              onOnClose: $setup.onCloseHandler
            }, null, 8, ["onAppendJobDescription", "onOnClose"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_quill_editor, {
          value: $setup.state.content,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.state.content) = $event)),
          options: $setup.state.editorOption,
          disabled: $setup.state.disabled,
          onBlur: _cache[4] || (_cache[4] = ($event: any) => ($setup.onEditorBlur($event))),
          onFocus: _cache[5] || (_cache[5] = ($event: any) => ($setup.onEditorFocus($event))),
          onReady: _cache[6] || (_cache[6] = ($event: any) => ($setup.onEditorReady($event))),
          onChange: _cache[7] || (_cache[7] = ($event: any) => ($setup.onEditorChange($event))),
          class: "editor_ql ql-editor"
        }, null, 8, ["value", "options", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          class: "!tw-w-auto",
          label: "Cancel",
          variant: "secondary",
          isCapitalize: true,
          onClick: $setup.onClickCloseTab
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          class: "!tw-w-auto",
          label: "Save",
          variant: "accent",
          isCapitalize: true,
          onClick: $setup.onClickSaveDescription
        }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}