<template>
  <GeneralDialog
    :isShowCloseButton="true"
    :isShowHeader="true"
    :isTitleStrong="true"
    headerTitle="Guidance For Evidence"
    headerDescription="Follow key guidelines to ensure clarity, accuracy, and compliance when submitting evidence or preparing your report."
    headerDescriptionTextSize="h6"
    headerDescriptionLineHeight="20px"
    width="655px"
    maxWidth="100%"
    @on-close="onClickBackButton"
  >
    <template #body>
      <section>
        <v-carousel
          height="456px"
          class="!tw-max-h-[456px]"
          style="overflow: initial"
          hide-delimiter-background
          delimiter-icon="mdi-square"
          :show-arrows="false"
          :continuous="false"
          v-model="model"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i">
            <v-sheet>
              <div
                class="!tw-flex !tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-pt-4 tw-box-border md:!tw-gap-[.5rem]"
              >
                <div
                  class="tw-flex tw-items-center tw-justify-center tw-m-auto tw-relative !tw-w-[226px] !tw-h-[216px]"
                >
                  <img
                    class="tw-relative tw-w-full tw-h-full tw-z-10 !tw-object-contain"
                    :src="item.src"
                  />
                  <!-- <img
                  class="tw-w-full !tw-h-auto tw-absolute tw-z-0 tw-top-[-16px] tw-right-0 tw-object-contain rmd:!tw-top-[-27px]"
                  :src="require('@/assets/images/benefit-blured-bg-1.png')"
                /> -->
                </div>
                <Text class="!tw-text-[34px] !tw-mb-4">
                  {{ item?.title }}
                </Text>
                <Text
                  variant="h6"
                  textColor="rgba(12, 15, 74, 0.5)"
                  textWeight="400"
                  textAlign="center"
                >
                  {{ item?.description }}
                </Text>
              </div>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </section>
    </template>
    <template #footer>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pt-6 tw-box-border tw-border-t-dashed-custom tw-gap-4"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          label="Previous"
          :disabled="model === 0"
          @click="prevSlide"
        />
        <Button
          class="!tw-w-auto"
          variant="accent"
          :label="model === items.length - 1 ? 'Continue' : 'Next'"
          @click="handleNextClick"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";

const emits = defineEmits(["on-close"]);

const model = ref(0);

const items = ref([
  {
    src: require("@/assets/images/guidance-img-1.png"),
    title: "Images and Files",
    description:
      "Upload a limited number of images (max 10) in common formats (JPG, PNG, PDF, DOCX), ensuring clear, high-resolution files under X MB with clear labels for easy identification.",
  },
  {
    src: require("@/assets/images/guidance-img-2.png"),
    title: "Ensure Your Statement is Clear and Specific",
    description:
      "Be clear and specific when describing evidence, ensuring it's relevant and aligned with your claim. Provide context to support your argument and avoid inconsistencies.",
  },
  {
    src: require("@/assets/images/guidance-img-3.png"),
    title: "View and Example",
    description:
      "Review all evidence carefully before submission, ensuring it's well-referenced and clear. Provide summaries of documents, their sources, and their contribution to the case.",
  },
]);

const handleNextClick = () => {
  if (model.value === items.value.length - 1) {
    emits("on-close");
  } else {
    model.value++;
  }
};
const prevSlide = () => {
  if (model.value > 0) {
    model.value--;
  }
};

const onClickBackButton = () => {
  return emits("on-close");
};
</script>
<style lang="scss" scoped>
.v-carousel {
  :deep(.v-carousel__controls) {
    gap: 0;
    .v-carousel__controls__item {
      margin: 0;
      width: 20px;
      height: 20px;

      .v-btn__content {
        .v-icon {
          opacity: 1;
          font-size: 14px;
        }
      }
    }
    .v-btn--active {
      .v-btn__content {
        .v-icon {
          color: #3b67e9;
          opacity: 1;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
