<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['course_enroll_modal']"
    width="464px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="title">
          <h4>Congratulations 🥳</h4>
        </div>
        <div class="modal_body__content">
          <div class="modal_img">
            <img src="../../assets/images/course-modal.png" alt="" />
          </div>
          <div class="modal_text">
            <p>
              You've successfully enrolled on {{ courseName }}. Start your
              learning journey on {{ courseStartData }}!
            </p>
          </div>
        </div>

        <div class="modal_footer">
          <v-btn class="button button-purple" @click="onClickContinue"
            >continue</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import moment from "moment";
import CommonDialog from "./CommonDialog.vue";
import { ref, onMounted } from "vue";
const emits = defineEmits(["onContinue"]);
const props = defineProps({
  courseDetails: {
    type: Object,
  },
});
const courseName = ref("");
const courseStartData = ref("");
const onClickContinue = () => {
  emits("onContinue");
};
onMounted(() => {
  courseName.value = props.courseDetails.name;
  const courseDate = props.courseDetails.courseSchedules.length
    ? props.courseDetails.courseSchedules[0]?.startDate
    : "";
  courseStartData.value = courseDate
    ? moment(courseDate).format("DD/MM/YYYY")
    : "";
});
</script>
<style lang="scss">
.modal_content {
  .title {
    margin-top: 56px;
    text-align: center;
    h4 {
      @include fluidFont(24, 24, 30px);
      font-weight: 400;
      color: rgba($blueDark, 1);
    }
  }
  .modal_body__content {
    text-align: center;
    margin-top: 70px;
    .modal_img {
      margin: 0 auto;
      width: 330px;
      max-width: 100%;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
    .modal_text {
      margin-top: 24px;
      p {
        @include fluidFont(16, 16, 17.57px);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.25px;
      }
    }
  }
  .modal_footer {
    margin-top: 40px;

    .button {
      width: 100%;
      max-width: 100%;
      text-transform: capitalize;
    }
  }
}
</style>
