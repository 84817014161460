<template>
  <GeneralDialog
    class="dispute-evidence-modal"
    @on-close="onClickBackButton"
    maxWidth="100%"
    headerTitle="Evidence"
    width="588px"
    :isTitleStrong="true"
    borderRadius="20px"
  >
    <template #body>
      <div class="tw-w-full">
        <div
          class="tw-w-full tw-bg-[#F8FBFD] tw-border-[#DFDFDF] tw-border-solid tw-border-[1px] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-5"
        >
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start">
            <Text variant="h6" lineHeight="18px" textWeight="400"
              >Project Details</Text
            >
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px"
                >Project:</Text
              >
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >{{ projectName }}</Text
              >
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px"
                >Contractor Name:</Text
              >
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                :isCapitalize="true"
                >{{ contractorNames }}</Text
              >
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start">
            <Text variant="h6" lineHeight="18px" textWeight="400"
              >Submission Details</Text
            >
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px">Date:</Text>
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >{{ date }}</Text
              >
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px">Time:</Text>
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >{{ time }}</Text
              >
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px"
                >Address:</Text
              >
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >--</Text
              >
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px"
                >Coordinates:</Text
              >
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >--</Text
              >
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-gap-2"
            >
              <Text variant="h6" textAlign="left" lineHeight="18px"
                >Creator:</Text
              >
              <Text
                variant="h6"
                textWeight="400"
                textAlign="left"
                lineHeight="18px"
                >--</Text
              >
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start">
            <Text variant="h6" lineHeight="18px" textWeight="400"
              >Statement</Text
            >
            <Text
              variant="h6"
              textWeight="400"
              textAlign="left"
              lineHeight="18px"
              >{{ disputeProject?.description }}</Text
            >
          </div>
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start">
            <Text variant="h6" textWeight="400">Attachments</Text>
            <div class="offer-attachments tw-grid tw-w-full">
              <div
                class="tw-rounded-[8.72px] tw-overflow-hidden tw-w-full tw-h-[94px]"
                v-for="(attachment, index) in attachments"
                :key="index"
              >
                <img
                  v-if="isImageType(attachment)"
                  :src="getAttachmentPath(attachment)"
                  alt="Image"
                  class="tw-w-full tw-h-full tw-object-cover"
                />

                <img
                  v-else
                  class="tw-w-full tw-h-full tw-object-cover"
                  :src="require('@/assets/icons/sidebar-icons/documents.svg')"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer v-if="!props.isSenderUser">
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pt-6 tw-box-border tw-border-t-dashed-custom tw-gap-4"
      >
        <Button
          class="!tw-w-auto"
          variant="secondary"
          label="Close"
          @click="onClickBackButton"
        />
      </section>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import moment from "moment";
import { computed, ref, onMounted } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage, imageExtensions } from "@/core/utils/common";
import Button from "./ui/general/Button.vue";
import Text from "./ui/general/Text.vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import appFilters from "@/filters";
import { setDirectStoragePathToImage } from "@/core/utils/common";
const emits = defineEmits(["on-close", "on-submit"]);

const props = defineProps({
  projectData: {
    type: Object,
    default: null,
  },
});

const store = useStore();
const internalInstance = getCurrentInstance();
const isUploadImg = ref<boolean>(false);

const disputeProject = computed(() => {
  return store.getters[`${PROJECT_STORE}/getDisputeProject`];
});

const attachments = computed(() => {
  return disputeProject.value?.attachment;
});
const getAttachmentPath = (attachment: any): any => {
  return setDirectStoragePathToImage(attachment);
};
const time = computed(() => {
  return moment(disputeProject.value?.erp_raised_date).local().format("h A");
});

const date = computed(() => {
  return appFilters.formatToDate(disputeProject.value?.erp_raised_date);
});

const projectName = computed(() => {
  if (!disputeProject.value?.estimatePhases.length) {
    return props.projectData?.name;
  }
  return disputeProject.value?.estimatePhases
    .map((phase: any) => phase.name)
    .join(", ");
});

const contractorNames = computed(() => {
  if (disputeProject.value?.subcontractors.length) {
    return disputeProject.value?.subcontractors
      .map((contractor: any) => contractor.name)
      .join(", ");
  }
  return "--";
});

const isImageType = (attachment: any) => {
  return imageExtensions.some((ext) => attachment?.toLowerCase().endsWith(ext));
};

const onAcceptOffer = async () => {
  emits("on-close");
  return;
};

const onClickBackButton = () => {
  return emits("on-close");
};

onMounted(() => {
  console.log(props.projectData, "project data");
});
</script>

<style lang="scss" scoped>
.offer-attachments {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
}
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}
</style>
