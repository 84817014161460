<template>
  <GeneralDialog
    class="dispute-evidence-modal"
    @on-close="onClickBackButton"
    maxWidth="100%"
    headerTitle="View Evidence"
    width="588px"
    :isTitleStrong="true"
    borderRadius="20px"
  >
    <template #body>
      <div class="tw-w-full">
        <div
          class="tw-w-full tw-bg-[#F8FBFD] tw-border-[#DFDFDF] tw-border-solid tw-border-[1px] tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-5"
        >
          <div
            class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start"
          ></div>
          <div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-items-start">
            <Text variant="h6">Attachment </Text>
            <div class="offer-attachments tw-grid tw-w-full">
              <div
                class="tw-rounded-[8.72px] tw-overflow-hidden tw-w-full tw-h-[94px]"
              >
                <a
                  v-if="isImageType(props?.evidenceAttachment?.attachment)"
                  :href="props.evidenceAttachment?.publicURL"
                  target="_blank"
                >
                  <img
                    :src="props.evidenceAttachment?.publicURL || ''"
                    alt="Image"
                    class="tw-w-full tw-h-full tw-object-cover"
                  />
                </a>
                <a
                  v-else
                  :href="props.evidenceAttachment?.publicURL"
                  target="_blank"
                >
                  <img
                    v-if="ispdf(props.evidenceAttachment?.publicURL)"
                    class="tw-w-full tw-h-full tw-object-cover"
                    :src="require('@/assets/icons/pdf-document-icon.svg')"
                    alt="icon"
                  />
                  <img
                    v-else
                    class="tw-w-full tw-h-full tw-object-cover"
                    :src="require('@/assets/icons/documents.svg')"
                    alt="icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- <div
            class="modal-footer"
            v-if="
              !props.isSenderUser &&
              props.disputeOffer?.status === DisputeRequestStatusEnums.PENDING
            "
          >
            <v-btn
              class="button button-purple-border"
              @click="onRejectOffer"
              :disabled="isLoading"
            >
              Reject
            </v-btn>
            <v-btn
              class="button button-purple"
              @click="onAcceptOffer"
              :loading="isLoading"
            >
              Accept
            </v-btn>
          </div> -->
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage, imageExtensions } from "@/core/utils/common";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { DisputeRequestStatusEnums } from "@/core/enums/ProjectsEnum";
import Button from "./ui/general/Button.vue";
import Text from "./ui/general/Text.vue";

const store = useStore();
const emits = defineEmits([
  "on-close",
  "on-submit",
  "on-accept-offer",
  "on-reject-offer",
]);
const props = defineProps({
  evidenceAttachment: {
    type: Object,
  },
});
const internalInstance = getCurrentInstance();
const isLoading = ref<boolean>(false);

const isImageType = (publicURL: any) => {
  return imageExtensions.some((ext) => publicURL?.toLowerCase().endsWith(ext));
};

const ispdf = (attachments: any) => {
  return attachments?.publicURL?.toLowerCase().endsWith(".pdf");
};

const onClickBackButton = () => {
  return emits("on-close");
};
</script>

<style lang="scss" scoped>
.offer-attachments {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
}
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}
</style>
