/**
 * Trades Passport route page
 */
import TradePassportRoute from "@/modules/trade-passport/pages/TradePassport.vue";
import TradePassportDetailsRoute from "@/modules/trade-passport/pages/TradePasspotDetailsPage.vue";

export const TRADE_PASSPOSRT_ROUTE = "trade-passport";
export const TRADE_PASSPOSRT_DETAILS_ROUTE = "trade-passport-details";

export default [
  {
    path: "/trade-passport",
    name: TRADE_PASSPOSRT_ROUTE,
    component: TradePassportRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "Trade Passport",
    },
  },
  {
    path: "/trade-passport-details/:type",
    name: TRADE_PASSPOSRT_DETAILS_ROUTE,
    component: TradePassportDetailsRoute,
    meta: {
      validateAuth: true,
      requiresAuth: true,
      title: "",
    },
  },
];
