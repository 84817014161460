<template>
  <TradePassportCardSlot
    class="trade-passport-services"
    title="Services"
    buttonLabel="+Add Services"
    :hideFooter="true"
    emptyStateImage="specialisms-empty"
    emptyStateLabel="Your Services will appear here"
    :isEmpty="!props.filterUserSpecialisms.length"
    :isCustomActionHeader="
      props.filterUserSpecialisms && props.filterUserSpecialisms.length
    "
    @on-handle-footer-click="onRouteToServicesOverview"
    @on-handle-click="onRouteToServicesOverview"
  >
    <template #custom-action-header>
      <div class="tw-flex tw-gap-3 tw-items-center">
        <Button
          label="+Add"
          variant="secondary"
          class="!tw-w-auto md:!tw-min-h-[unset] md:!tw-px-2"
          @click="onRouteToServicesOverview"
        />
        <BlueCircularPencilIcon @click="onRouteToServicesOverview" />
      </div>
    </template>
    <template #content>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
        <v-expansion-panels
          v-for="(skill, index) in filterUserSpecialisms"
          :key="index"
        >
          <v-expansion-panel
            class="collapsible-component !tw-rounded-lg tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid"
          >
            <v-expansion-panel-title
              ><div
                class="tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-lg tw-bg-white tw-box-border"
              >
                <div
                  class="tw-rounded-[4px] tw-border-[1px] tw-border-solid tw-border-[#DFDFDF] tw-min-w-[48px] tw-w-[48px] tw-h-[48px] tw-flex tw-items-center tw-justify-center tw-bg-white"
                >
                  <div
                    class="tw-rounded-full tw-w-[34px] tw-min-w-[34px] tw-h-[34px] tw-bg-[#E7F4FE] tw-flex tw-items-center tw-justify-center"
                  >
                    <img
                      :src="
                        require(`@/assets/category-icons/${skill.iconName}.svg`)
                      "
                      class="tw-w-[15px] tw-min-w-[15px] tw-h-[15px] tw-object-contain"
                    />
                  </div>
                </div>
                <div class="tw-w-full tw-items-center tw-flex tw-justify-start">
                  <Text variant="h6" textAlign="left">{{ skill.label }}</Text>
                </div>
              </div>
              <template v-slot:actions="{ expanded }">
                <div
                  class="tw-min-w-[26px] tw-w-[26px] tw-h-[26px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-[1.3px] tw-border-solid tw-border-[#DFDFDF] tw-bg-[#F8FBFD] tw-cursor-pointer"
                >
                  <v-icon
                    icon="mdi-chevron-right"
                    class="!tw-text-[14px]"
                    v-if="!expanded"
                  ></v-icon>
                  <v-icon
                    icon="mdi-chevron-down"
                    class="!tw-text-[14px]"
                    v-else
                  ></v-icon>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="tw-pb-4 tw-pr-4 tw-pl-[5rem] tw-pt-2"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-1"
              >
                <!-- <Text
                  variant="h6"
                  textAlign="left"
                  class="md:!tw-text-[.8rem]"
                  >{{ skill.label }}</Text
                > -->
                <div class="tw-w-full tw-flex tw-flex-wrap tw-h-auto tw-gap-2">
                  <div
                    v-for="(subCategory, index) in skill.subCategories"
                    :key="index"
                    class="tw-w-auto tw-py-[.1rem] tw-px-2 tw-rounded-[5.33px] tw-box-border tw-bg-[#E7F4FE] tw-flex tw-items-center tw-justify-center"
                  >
                    <Text variant="xsmall" textAlign="left">
                      {{ subCategory.label }}</Text
                    >
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </TradePassportCardSlot>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TradePassportCardSlot from "../slots/TradePassportCardSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import BlueCircularPencilIcon from "@/core/components/icons/BlueCircularPencilIcon.vue";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { TRADE_PASSPOSRT_DETAILS_ROUTE } from "../../routes";

const store = useStore();
const router = useRouter();

const props = defineProps(["filterUserSpecialisms", "isLoading"]);

const collapsedItems = ref([]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const onRouteToServicesOverview = () => {
  router.push({
    name: TRADE_PASSPOSRT_DETAILS_ROUTE,
    params: { type: "services" },
  });
};

const onToggleCollapsedState = (index) => {
  if (collapsedItems.value.includes(index)) {
    // Remove the index from the array
    collapsedItems.value = collapsedItems.value.filter(
      (item) => item !== index
    );
  } else {
    // Add the index to the array
    collapsedItems.value.push(index);
  }
};

onMounted(() => {
  console.log(props.filterUserSpecialisms, "filterUserSpecialisms props");
});
</script>
<style lang="scss" scoped>
.collapsible-component {
  :deep(.v-expansion-panel-title) {
    min-height: 62px;
    font-weight: 700;
  }
  :deep(.v-expansion-panel__shadow) {
    // box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    box-shadow: none;
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}
</style>
