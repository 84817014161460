import { ChatMessages } from "@/core/models/chat-messages";

export default {
  setNetworkDirectMessage(state: ChatMessages, payload: object) {
    state.NetworkDirectMessage = payload;
  },
  setCurrentMessageType(state: ChatMessages, payload: string) {
    state.currentMessageType = payload;
  },
  setProjectChatRequests(state: ChatMessages, payload: Array<any>) {
    state.projectChatRequests = payload;
  },
  setKrystalConversation(state: ChatMessages, payload: Array<any>) {
    state.krystalConversation = payload;
  },
  popKrystalConversation(state: ChatMessages) {
    state.krystalConversation.pop();
  },
  pushKrystalConversation(state: ChatMessages, payload: any) {
    state.krystalConversation.push(payload);
  },
  setUserRoomMessages(state: ChatMessages, payload: any) {
    state.chatMessagesRooms = [...payload];
  },
  setChatBoxRooms(state: ChatMessages, payload: any) {
    state.chatBoxRooms = [...payload];
  },
  addChatBoxRoom(state: ChatMessages, payload: any) {
    state.chatBoxRooms.unshift(payload);
  },
  addUserRoomMessages(state: ChatMessages, payload: any) {
    const isExits = state.chatMessagesRooms.find(
      (room: any) => room.id === payload.id
    );
    if (!isExits) state.chatMessagesRooms.unshift(payload);
  },
  addFilterMessagesRooms(state: ChatMessages, payload: any) {
    const isExits = state.filterMessagesRooms.find(
      (room: any) => room.id === payload.id
    );
    if (!isExits) {
      state.filterMessagesRooms.unshift(payload);
    }
  },

  updateUserRoomMessage(state: ChatMessages, payload: any) {
    const roomId = payload.id;
    state.chatMessagesRooms = state.chatMessagesRooms.map((room: any) =>
      room.id === roomId ? payload : room
    );

    state.filterMessagesRooms = state.filterMessagesRooms.filter(
      (room: any) => room.id !== roomId
    );
  },
  setFilterMessageRooms(state: ChatMessages, payload: any) {
    state.filterMessagesRooms = [...payload];
  },
  modifyChatMessageRoomAfterNewMesage(state: ChatMessages, payload: any) {
    state.filterMessagesRooms = state.chatMessagesRooms.map((room: any) => {
      if (room.id === payload.id) {
        return {
          ...room,
          userMessageStatus: payload.userMessageStatus,
        };
      }
      return room;
    });
  },

  updateDisputeChatMessageRoom(state: ChatMessages, payload: any) {
    const roomId = payload.id;
    const dispute = payload.dispute;

    state.chatMessagesRooms = state.chatMessagesRooms.map((room: any) => {
      if (room.id === roomId) {
        return {
          ...room,
          dispute: dispute,
        };
      }
      return room;
    });
    state.filterMessagesRooms = state.chatMessagesRooms;
  },
};
