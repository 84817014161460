<template>
  <GeneralDialog
    class="dispute-process-modal"
    :isShowCloseButton="false"
    width="570px"
    maxWidth="100%"
    :isShowHeader="false"
    gap="0"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <div class="title">
              <h2 class="modal-title">Reason(s) for dispute</h2>
              <v-icon
                class="cursor-pointer"
                icon="mdi-close"
                :size="15"
                @click="onClickCloseButton"
              ></v-icon>
            </div>
            <p>
              Please help us understand the reason for your dispute by selecting
              all that apply
            </p>
          </div>
          <div class="modal-body">
            <!-- <p class="modal-body-text">
              Please help us understand the reason for your dispute by selecting
              all that apply
            </p> -->
            <div class="steps-list">
              <div v-if="isPropertyOwner" class="steps-list-wrapper">
                <div
                  v-for="reason in reasonsPO"
                  :key="reason.value"
                  class="checkbox-group step-item"
                >
                  <div class="step-icon">
                    <input
                      type="checkbox"
                      :value="reason.value"
                      v-model="selectedReason"
                      class=""
                    />
                  </div>
                  <div class="step-details">
                    <label> {{ reason.label }} </label>
                    <p>{{ reason.description }}</p>
                  </div>
                  <!-- <div class="step-details">
                  <div class="step-description">
                    <p>{{ step.description }}</p>
                  </div>
                </div> -->
                </div>
              </div>
              <div v-else class="steps-list-wrapper">
                <div
                  v-for="reason in reasonsTP"
                  :key="reason.value"
                  class="checkbox-group step-item"
                >
                  <div class="step-icon">
                    <input
                      type="checkbox"
                      :value="reason.value"
                      v-model="selectedReason"
                      class=""
                    />
                  </div>
                  <div class="step-details">
                    <label> {{ reason.label }} </label>
                    <p>{{ reason.description }}</p>
                  </div>
                  <!-- <div class="step-details">
                  <div class="step-description">
                    <p>{{ step.description }}</p>
                  </div>
                </div> -->
                </div>
              </div>

              <div class="step-item">
                <div class="step-icon">
                  <input type="radio" v-model="isOtherSelected" value="Other" />
                </div>
                <div class="step-details">
                  <label for=""> Other </label>
                  <v-textarea
                    class="input_field"
                    v-if="isOtherSelected"
                    v-model="otherReason"
                    placeholder="Please write your reason here"
                    variant="solo"
                    rows="5"
                    row-height="100"
                    bg-color="#f8fbfd"
                    autogrow
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="button button-purple-border"
              @click="onClickCloseButton"
            >
              Cancel
            </button>
            <v-btn
              class="button button-purple"
              :disabled="selectedReasonsWithDescription.length == 0"
              @click="onProceedWithDispute"
            >
              Continue
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, computed, onMounted } from "vue";
import {
  VTextField,
  VBtn,
  VRadioGroup,
  VRadio,
  VSelect,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCol,
  VRow,
} from "vuetify/lib/components";
import CommonDialog from "@/core/components/CommonDialog.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { useStore } from "vuex";
import MemberService from "@/core/services/manage-member.service";
import { USER_STORE } from "@/store/modules/user";
import { UserRolesEnum } from "@/core/enums/RolesEnum";

const store = useStore();
const emits = defineEmits(["close"]);

const getProjectEstimateDataWithEscrow = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
);

const filterEstimatePhases = computed(() => {
  return getProjectEstimateDataWithEscrow.value?.estimationPhase || [];
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const teamMemberList = ref([]);

const filterMemberList = ref([]);

const isPropertyOwner = computed(() => {
  return user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER;
});

// Dispute reasons and selected reason management
const reasonsPO = [
  {
    label: "Poor Quality Work",
    value: "Poor Quality Work",
    description: "Work not meeting quality standards.",
  },
  {
    label: "Missed Deadlines",
    value: "Missed Deadlines",
    description: "Project delays beyond the agreed timeline.",
  },
  {
    label: "Overcharging",
    value: "Overcharging",
    description: "Additional charges requested without prior notice.",
  },
  {
    label: "Incomplete Work",
    value: "Incomplete Work",
    description: "Work left unfinished despite payment.",
  },
  {
    label: "Damage to Property",
    value: "Damage to Property",
    description: "Contractor caused damage during the project.",
  },
  {
    label: "Unprofessional Conduct",
    value: "Unprofessional Conduct",
    description: "Rude or unprofessional behaviour by the contractor.",
  },
  {
    label: "Health & Safety Concerns",
    value: "Health & Safety Concerns",
    description: "Violations of safety protocols on-site.",
  },
  {
    label: "Non-compliance",
    value: "Non-compliance",
    description: "Failure to follow agreed plans or legal regulations.",
  },
];

const reasonsTP = [
  {
    label: "Non-payment",
    value: "Non-payment",
    description: "Payment not received for completed work.",
  },
  {
    label: "Partial Payment",
    value: "Partial Payment",
    description: "Only a portion of the agreed amount has been paid.",
  },
  {
    label: "Scope Changes",
    value: "Scope Changes",
    description:
      "Unagreed requested changes or additions to the project scope.",
  },
  {
    label: "Delays by Owner",
    value: "Delays by Owner",
    description: "Delays in approvals, materials, or site readiness.",
  },
  {
    label: "Unrealistic Expectations",
    value: "Unrealistic Expectations",
    description: "Demands exceeding agreed project terms.",
  },
  {
    label: "Site Access Issues",
    value: "Site Access Issues",
    description: "Restricted or denied access to the worksite.",
  },
  {
    label: "Cancellation",
    value: "Cancellation",
    description: "Project canceled without proper notice.",
  },
  {
    label: "Breach of Contract",
    value: "Breach of Contract",
    description: "Owner violating terms of the signed agreement.",
  },
];

const selectedReason = ref([]);
const isOtherSelected = ref(false);
const otherReason = ref("");
const selectedIssue = ref("wholeProject");

const phases = computed(() => {
  return filterEstimatePhases?.value?.map((phase) => ({
    name: phase?.name,
    refId: phase?.refId,
    tasks: phase?.estimationPhaseTasks?.length,
    days: Math.floor(
      (new Date(phase?.phaseEndDate) - new Date(phase?.phaseStartDate)) /
        (1000 * 60 * 60 * 24)
    ),
    cost: phase?.subTotal,
    disabled: !phase?.phaseCompletionStatus,
  }));
});

const selectedReasonsWithDescription = computed(() => {
  if (isPropertyOwner.value) {
    return reasonsPO
      .filter((reason) => selectedReason.value.includes(reason.value))
      .map((reason) => ({
        key: reason.value,
        value: reason.description,
      }))
      .concat(
        isOtherSelected.value && otherReason.value
          ? [{ key: "Other", value: otherReason.value }]
          : []
      );
  }
  return reasonsTP
    .filter((reason) => selectedReason.value.includes(reason.value))
    .map((reason) => ({
      key: reason.value,
      value: reason.description,
    }))
    .concat(
      isOtherSelected.value && otherReason.value
        ? [{ key: "Other", value: otherReason.value }]
        : []
    );
});

watch(selectedReason, (newValue) => {
  if (newValue.length > 0) {
    isOtherSelected.value = false;
  }
});

watch(isOtherSelected, (newValue) => {
  if (newValue) {
    selectedReason.value = [];
  }
});
const onProceedWithDispute = () => {
  if (selectedIssue.value === "phases") {
    emits("sendSelectedPhase");
  } else {
    if (selectedReasonsWithDescription.value.length > 0) {
      emits("sendSelectedReasons", selectedReasonsWithDescription.value);
    }
  }
};

const selectedPhases = ref([]);
const selectContractors = ref([]);

const removeSelectedPhase = (index, refId) => {
  const selectedIndex = selectedPhases.value.findIndex(
    (phase, i) => i === index && phase.refId === refId
  );
  if (selectedIndex !== -1) {
    selectedPhases.value.splice(selectedIndex, 1);
  }
};
const removeSelectedContractor = (index, email) => {
  const selectedIndex = selectedPhases.value.findIndex(
    (contractor, i) => i === index && contractor.email === email
  );
  if (selectedIndex !== -1) {
    selectedPhases.value.splice(selectedIndex, 1);
  }
};

onMounted(async () => {
  teamMemberList.value = await MemberService.getTeamMember(user.value.id);
  filterMemberList.value = teamMemberList.value;
});

const onClickCloseButton = () => {
  emits("close");
};
</script>

<style lang="scss" scoped>
.dispute-process-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            padding-bottom: 24px;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              margin-top: 8px;
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
            .modal-body-text {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
            .steps-list {
              margin: 24px 0;
              display: flex;
              flex-direction: column;
              gap: 16px;
              .steps-list-wrapper {
                display: flex;
                flex-direction: column;
                gap: 16px;
                .step-item {
                  display: flex;
                  gap: 8px;
                  align-items: flex-start;
                  .step-icon {
                    margin-top: 5px;
                    input[type="checkbox"] {
                      width: 16px;
                      height: 16px;
                      accent-color: rgba(75, 75, 255, 1);
                    }
                  }
                  .step-details {
                    width: 100%;
                    max-width: 100%;
                    label {
                      @include fluidFont(14, 14, 16px);
                      font-weight: 700;
                      color: rgba($blueDark, 1);
                    }

                    p {
                      @include fluidFont(12, 12, 16px);
                      font-weight: 400;
                      color: rgba($blueDark, 0.5);
                    }
                  }
                }
              }
              .step-item {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                .step-icon {
                  margin-top: 5px;
                  input[type="radio"] {
                    width: 16px;
                    height: 16px;
                    accent-color: rgba(75, 75, 255, 1);
                  }
                }
                .step-details {
                  width: 100%;
                  max-width: 100%;
                  label {
                    @include fluidFont(14, 14, 16px);
                    font-weight: 700;
                    color: rgba($blueDark, 1);
                  }
                  .input_field {
                    margin: 0;
                    :deep(.v-input__control) {
                      .v-field {
                        border: 1px solid #dfdfdf;
                        border-radius: 8px;
                        // background-color: #f8fbfd;
                        box-shadow: none;
                        .v-field__field {
                          .v-field__input {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                            padding: 12px 16px;
                            top: 0;
                          }
                          textarea {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                            min-height: 42px;
                            padding: 12px 16px;
                            mask-image: unset;
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }

                  p {
                    @include fluidFont(12, 12, 16px);
                    font-weight: 400;
                    color: rgba($blueDark, 0.5);
                  }
                }
              }
            }

            .next-steps {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
              a {
                @include fluidFont(14, 14, 22px);
                font-weight: 700;
                color: rgba(51, 102, 255, 1);
                text-decoration: underline;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
</style>
