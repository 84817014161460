<template>
  <div
    :class="{ shadow: hasShadow, dense: isDense }"
    class="chip-state tw-px-2 tw-py-[2px] tw-box-border tw-rounded-md tw-flex tw-gap-1 tw-items-center tw-justify-center tw-capitalize"
  >
    <slot name="prefix"></slot>
    <Text
      variant="span"
      textWeight="700"
      :textColor="textColor"
      :whiteSpace="whiteSpace"
      :lineHeight="lineHeight"
      v-if="!hasCustomLabel"
      class="sm:!tw-text-[10px]"
      :class="{ '!tw-uppercase': uppercase }"
    >
      {{ label }}
    </Text>
    <slot name="custom-label" v-else></slot>
    <slot name="postfix"></slot>
  </div>
</template>
<script setup>
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  label: String,
  textColor: {
    type: String,
    default: "#0C0F4A",
  },
  bgColor: String,
  hasShadow: Boolean,
  hasCustomLabel: Boolean,
  uppercase: Boolean,
  isDense: Boolean,
  whiteSpace: {
    type: String,
    default: "nowrap",
  },
  lineHeight: {
    type: String,
    default: "24px",
  },
});
</script>
<script>
export default {
  name: "ChipState",
};
</script>
<style lang="scss" scoped>
.chip-state {
  background: v-bind("props.bgColor");
}

.chip-state.shadow {
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
}

.chip-state.dense {
  padding: 0px 8px !important;
}
</style>
