<template>
  <div class="tw-w-full tw-min-h-[292px] tw-relative sm:!tw-min-h-[232px]">
    <div
      class="tw-min-h-[150px] tw-w-full tw-p-4 tw-box-border tw-rounded-lg tw-bg-[#F8FBFD] tw-border-[1px] tw-border-[#DFDFDF] tw-border-solid tw-relative sm:!tw-min-h-[140px]"
    >
      <div class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-right-0">
        <img
          src="@/assets/images/trade-banner.png"
          class="tw-w-full tw-h-full tw-object-cover"
        />
      </div>
      <div
        class="tw-w-full tw-h-auto tw-flex tw-items-start tw-justify-between"
      >
        <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">
          <VerifiedBadge
            label="Verified Member Since 2024"
            v-if="
              activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED
            "
          />
          <UnverifiedBadge v-else />
          <IdNotVerifiedBadge
            class="tw-hidden s540:!tw-flex"
            v-if="
              (activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.NOT_ADDED &&
                userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
                activeUserWorkstation?.verificationStatus !==
                  UserVerificationStatusEnum.PENDING_VERIFICATION) ||
              (!WorkStationSiltVerificationStatus &&
                userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
                userWorkStationStatus !==
                  UserVerificationStatusEnum.PENDING_VERIFICATION)
            "
          />
          <VerifiedBadge
            v-else
            label="ID Verified"
            class="tw-hidden s540:!tw-flex"
          />
        </div>

        <!-- <Button
          class="!tw-w-auto !tw-hidden rmd:!tw-inline"
          variant="accent"
          :isCapitalize="true"
          label="+ Add Background"
        />
        <BlueCircularPlusIcon class="tw-inline rmd:!tw-hidden" /> -->
      </div>
    </div>
    <div
      class="tw-min-h-[182px] tw-bg-transparent tw-w-full tw-p-4 tw-box-border tw-flex tw-gap-4 tw-items-end tw-absolute tw-bottom-0 sm:!tw-min-h-[unset] sm:!tw-gap-[.6rem]"
    >
      <ProfileThumbnail
        v-if="props.contractorworkStationImage || props.userWorkStationImage"
        :isRounded="false"
        :desktopFontSizeBig="true"
        size="xlllg"
        mobilSize="xlg"
        :isOnline="true"
        :src="
          isUserProprtyOwner
            ? props.contractorworkStationImage
            : props.userWorkStationImage
        "
      />
      <div
        v-else
        class="tw-min-w-[150px] tw-h-[150px] tw-overflow-hidden tw-rounded-[10.67px] sm:!tw-min-w-[98px] sm:!tw-w-[98px] sm:!tw-h-[98px]"
      >
        <UserProfileLogo
          :isRounded="false"
          :desktopFontSizeBig="true"
          :mobileFontSizeBig="true"
        />
      </div>
      <div
        class="tw-w-full tw-h-full tw-flex tw-items-end tw-pb-4 tw-box-border md:!tw-pb-0"
      >
        <div
          class="tw-w-full tw-flex tw-items-start tw-justify-between tw-gap-2"
        >
          <div
            class="tw-flex tw-flex-col tw-gap-3 tw-items-start sm:!tw-gap-0 md:!tw-gap-[.3rem]"
          >
            <Text
              variant="p"
              textWeight="400"
              lineHeight="18px"
              class="rmd:!tw-text-[26px] !tw-capitalize"
              >{{ activeUserWorkstation?.name }}</Text
            >
            <div class="view-rating-box md:tw-gap-[.3rem]">
              <v-rating
                active-color="orange"
                v-model="getAverageOfAllProjectReview"
                color="rgba(12, 15, 74, 0.26)"
                empty-icon="mdi-star"
                readonly
              ></v-rating>
              <Text
                variant="xsmall"
                textWeight="600"
                lineHeight="18px"
                class="rmd:!tw-text-[14px]"
                >{{ getAverageOfAllProjectReview }} out of 5</Text
              >
              <Button
                label="view Ratings"
                :isCapitalize="true"
                variant="accent"
                class="!tw-w-auto !tw-h-[32px] !tw-min-h-[32px] sm:!tw-hidden"
                @click="onClickViewRatings"
              />
            </div>
            <Text
              variant="xsmall"
              class="rmd:!tw-text-[14px]"
              lineHeight="14px"
              textColor="rgba(12, 15, 74, 0.7)"
              textAlign="left"
              >{{
                workstationAddress(
                  activeUserWorkstation?.user?.userBusinessDetails?.type
                ) !== "null null"
                  ? workstationAddress(
                      activeUserWorkstation?.user?.userBusinessDetails?.type
                    )
                  : "N/A"
              }}
              {{
                workstationPostcode(
                  activeUserWorkstation?.user?.userBusinessDetails?.type
                )
              }}</Text
            >
          </div>
          <IdNotVerifiedBadge
            class="s540:!tw-hidden"
            v-if="
              (activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.NOT_ADDED &&
                userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
                activeUserWorkstation?.verificationStatus !==
                  UserVerificationStatusEnum.PENDING_VERIFICATION) ||
              (!WorkStationSiltVerificationStatus &&
                userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
                userWorkStationStatus !==
                  UserVerificationStatusEnum.PENDING_VERIFICATION)
            "
          />
          <VerifiedBadge v-else label="ID Verified" class="s540:!tw-hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import UnverifiedBadge from "@/core/components/common/UnverifiedBadge.vue";
import VerifiedBadge from "@/core/components/common/VerifiedBadge.vue";
import IdNotVerifiedBadge from "@/core/components/common/IdNotVerifiedBadge.vue";
import { USER_STORE } from "@/store/modules/user";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { WORKSTATION } from "@/store/modules/workstation";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { getCombineSameProjectReview } from "@/core/utils/common";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { reviewRatingState } from "@/core/models/project-rating";
import { TRADE_PASSPOSRT_ROUTE } from "../../routes";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import BlueCircularPlusIcon from "@/core/components/icons/BlueCircularPlusIcon.vue";

const props = defineProps([
  "contractorworkStationImage",
  "userWorkStationImage",
]);

const store = useStore();
const router = useRouter();

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const getIsOpenTradePassportasViewMode = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
);

const isUserProprtyOwner = computed(
  () =>
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
    getIsOpenTradePassportasViewMode.value
);

const activeUserWorkstation = computed(() => {
  if (isUserProprtyOwner.value) {
    return store.getters[
      `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
    ];
  } else {
    return store.getters[`${WORKSTATION}/activeUserWorkstation`];
  }
});

const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});

const userBusinessType = computed(() => user.value?.userBusinessDetails?.type);

const WorkStationSiltVerificationStatus = computed(
  () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
);

const userWorkStationStatus = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.verificationStatus
);

const workstationPostcode = (type: string) => {
  if (activeUserWorkstation.value?.user) {
    return type === BusinessDetailTypeEnum.LIMITED_COMPANY
      ? activeUserWorkstation.value?.user?.userBusinessDetails?.businessPostcode
      : activeUserWorkstation.value?.user?.userBusinessDetails
          ?.residencyPostcode;
  } else {
    return "N/A";
  }
};

const workstationAddress = (type: string) => {
  if (activeUserWorkstation.value?.user) {
    let address;
    if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
      const businessAddress =
        activeUserWorkstation.value?.user?.userBusinessDetails?.businessAddress?.replace(
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.businessPostcode,
          ""
        );
      address = businessAddress?.replace(/,([^,]*)$/, ".$1");
    } else if (
      activeUserWorkstation.value?.user?.userBusinessDetails
        ?.residencyAddressLookup
    ) {
      const residencyAddressLookup =
        activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLookup?.replace(
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.residencyPostcode,
          ""
        );
      address = residencyAddressLookup?.replace(/,([^,]*)$/, ".$1");
    } else {
      address = `${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLine} ${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyTown}`;
    }
    return address;
  } else {
    return "N/A";
  }
};

const onClickViewRatings = () => {
  store.dispatch(`${REVIEW_RATING_STORE}/setTradePassportRedirect`, "Reviews");
  router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

onMounted(async () => {
  await store.dispatch(
    `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
    ProjectRatingTypeEnum.TRADER_OWNER
  );

  console.log(
    WorkStationSiltVerificationStatus.value,
    "WorkStationSiltVerificationStatus"
  );
  console.log(userWorkStationStatus.value, "userWorkStationStatus");
});
</script>
<style lang="scss" scoped>
.view-rating-box {
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;

  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }
}
</style>
