import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import LoginRoute, { LOGIN_ROUTE } from "@/modules/login/routes";
import WorkStationLinkTreeRoute, {
  WORKSTATION_LINK_TREE_ROUTE,
} from "@/modules/workStation-link-tree/routes";
import ForgotPasswordRoute, {
  FORGOT_PASSWORD_ROUTE,
} from "@/modules/forgot-password/routes";
import RegistrationRoute, {
  REGISTRATION_LANDING_ROUTE,
  REGISTRATION_ROUTE,
} from "@/modules/registration/routes";
import ChangePasswordRoute from "@/modules/change-password/routes";
import DashboardRoute, { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import OnboardingRoute, { ONBOARDING_ROUTE } from "@/modules/onboarding/routes";
import CoursesRoute, { COURSE_ROUTE } from "@/modules/courses/routes";
import DocumentsRoute, { DOCUMENTS_ROUTE } from "@/modules/documents/routes";
import JobsRoute, {
  JOBS_ROUTE,
  JOB_DETAILS_ROUTE,
} from "@/modules/jobs/routes";
import TradePassportRoute, {
  TRADE_PASSPOSRT_ROUTE,
  TRADE_PASSPOSRT_DETAILS_ROUTE,
} from "@/modules/trade-passport/routes";
import { getLocalStorageWithExpiry } from "@/core/utils/common";
import { AUTH_TOKEN } from "@/core/constants";
import OwnerOnboardingRoute from "@/modules/owner-onboarding/routes";
import ProfileRoute from "@/modules/profile/routes";
import ProjectRoute, {
  PROJECT_DETAILS_ROUTE,
  PROJECT_ROUTE,
  TRADE_ESTIMATES_ROUTE,
  TRADE_ESTIMATES_TRADE_PASSPORT,
} from "@/modules/project/routes";
import PropertyOwnerWorkstation from "@/modules/workstation/propertyowner/routes";
import CreateWorkStation, {
  CREATE_WORKSTATION_TRADE_OWNER_ROUTE,
} from "@/modules/workstation/create-workstation/routes";
import PropertiesRoute, {
  ADD_PROPERTY_ROUTE,
  PROPERTIES_ROUTE,
} from "@/modules/properties/routes";
import CalendarRoute, { CALENDAR_ROUTE } from "@/modules/calendar/routes";
import TradeNetwork, {
  TRADE_NETWORK_ROUTE,
} from "@/modules/trade-network/routes";
import PreferredContractorsRoute, {
  PREFERRED_CONTRACTORS_ROUTE,
} from "@/modules/preferred-contractors/routes";
import WorkstationSettingRoute from "@/modules/workstation-setting/routes";
import RasieIssueRoute, { ISSUE_ROUTE } from "@/modules/issues/routes";
import EmergencyOutOfHoursRoute, {
  EMERGENCY_OUT_OF_HOURS,
} from "@/modules/emergency-out-of-hours/routes";
import EWalletRoute, { E_WALLET } from "@/modules/e-wallet/routes";
import AliaseEmailRoute, {
  ALIASE_EMAIL_ROUTE,
} from "@/modules/aliase-email/routes";
import { USER_STORE } from "@/store/modules/user";
import IssuePropertyRoute from "@/modules/issues/routes";
import ConnectionRoute, {
  CONNECTIONS_ROUTE,
} from "@/modules/connections/routes";
import VerifyJobsRoute, {
  VERIFY_JOBS_ROUTE,
} from "@/modules/verify-historical-projects/routes";
import ChatRoute, { CHAT_ROUTE } from "@/modules/chat/routes";
import ClientsRoute, { CLIENTS_ROUTE } from "@/modules/clients/routes";
import ViewRatingRoute, {
  VIEW_RATING_ROUTE,
} from "@/modules/view-rating/routes";
import { useRoute } from "vue-router";
import AnalyticsRoute, { ANALYTICS_ROUTE } from "@/modules/analytics/routes";
import store from "@/store";
import { WORKSTATION } from "@/store/modules/workstation";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import GetStartBoarding, {
  GET_START_BOARDING_ROUTE,
} from "@/modules/get-start-boarding/routes";
import RaiseEstimateDetailRoute, {
  RAISE_ESTIMATE_DETAIL_ROUTE,
} from "@/modules/client-raise-estimate/routes";

const routes: Array<RouteRecordRaw> = [
  LoginRoute,
  ...RegistrationRoute,
  ChangePasswordRoute,
  ...DashboardRoute,
  ForgotPasswordRoute,
  ...OnboardingRoute,
  ...CoursesRoute,
  ...JobsRoute,
  ...TradePassportRoute,
  OwnerOnboardingRoute,
  ProfileRoute,
  ...ProjectRoute,
  PropertyOwnerWorkstation,
  ...CreateWorkStation,
  ...PropertiesRoute,
  TradeNetwork,
  PreferredContractorsRoute,

  ...DocumentsRoute,
  ...CalendarRoute,
  WorkstationSettingRoute,
  RasieIssueRoute,
  EWalletRoute,
  EmergencyOutOfHoursRoute,
  AliaseEmailRoute,
  ConnectionRoute,
  VerifyJobsRoute,
  ChatRoute,
  ClientsRoute,
  ViewRatingRoute,
  ...AnalyticsRoute,
  GetStartBoarding,
  WorkStationLinkTreeRoute,
  ...RaiseEstimateDetailRoute,
  {
    path: "/:catchAll(.*)",
    redirect: { path: "/" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const propertyOwnerRoutes = [
  PROPERTIES_ROUTE,
  ADD_PROPERTY_ROUTE,
  PROJECT_DETAILS_ROUTE,
  PROJECT_ROUTE,
  PREFERRED_CONTRACTORS_ROUTE,
  ISSUE_ROUTE,
  EMERGENCY_OUT_OF_HOURS,
  CONNECTIONS_ROUTE,
  VIEW_RATING_ROUTE,
  TRADE_ESTIMATES_ROUTE,
];

const tradeOwnerRoutes = [
  TRADE_PASSPOSRT_ROUTE,
  JOBS_ROUTE,
  JOB_DETAILS_ROUTE,
  TRADE_NETWORK_ROUTE,
  COURSE_ROUTE,
  E_WALLET,
  CLIENTS_ROUTE,
  VERIFY_JOBS_ROUTE,
  TRADE_PASSPOSRT_DETAILS_ROUTE,
];
// Add meta information to specify the layout for routes that require authentication
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const authToken = getLocalStorageWithExpiry(AUTH_TOKEN);
    const isRequireAuth = to.matched.some(
      (route: any) => route.meta.requiresAuth
    );
    if (to.meta.layout) {
      to.meta.layout = "AuthLayout";
    }
    if (!authToken && isRequireAuth) {
      localStorage.clear();
      return next({ path: "/" });
    }
    if (
      to?.name === GET_START_BOARDING_ROUTE ||
      to?.name === WORKSTATION_LINK_TREE_ROUTE ||
      to?.name === RAISE_ESTIMATE_DETAIL_ROUTE
    ) {
      return next();
    }
    let user = store.getters[`${USER_STORE}/user`];
    const activeWorkstationVerificationstatus =
      store.getters[`${WORKSTATION}/isUserWorkStationVerified`];
    const isInsuranceExpired =
      store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`];
    if (!user) {
      user = await store.dispatch(`${USER_STORE}/initializeUserState`);
    }
    if (
      (user?.role?.name === UserRolesEnum.TRADESPERSON &&
        propertyOwnerRoutes.includes(to?.name as any)) ||
      (user?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        tradeOwnerRoutes.includes(to?.name as any))
    ) {
      return next({ name: DASHBOARD_ROUTE });
    }
    if (
      !isRequireAuth &&
      authToken &&
      FORGOT_PASSWORD_ROUTE !== to?.name &&
      ALIASE_EMAIL_ROUTE !== to?.name
    ) {
      // Handle courseReservation if present in query parameters
      let courseReservation = null;
      if (to.query.courseReservation) {
        try {
          courseReservation = JSON.parse(
            String(to.query.courseReservation) || "{}"
          );
        } catch (error) {
          console.error(
            "Error parsing courseReservation query parameter:",
            error
          );
        }
      }

      // If courseReservation exists, redirect to the dashboard with the query
      if (courseReservation) {
        return next({
          name: DASHBOARD_ROUTE,
          query: {
            courseReservation: JSON.stringify(courseReservation),
          },
        });
      }
      return next({ name: DASHBOARD_ROUTE });
    }
    if (
      (to?.name === E_WALLET || to?.name === CLIENTS_ROUTE) &&
      (!activeWorkstationVerificationstatus || isInsuranceExpired)
    ) {
      return next({ name: DASHBOARD_ROUTE });
    }

    const activeUserWorkstation =
      store.getters[`${WORKSTATION}/activeUserWorkstation`];
    if (
      (to?.name === TRADE_PASSPOSRT_ROUTE ||
        to?.name === JOBS_ROUTE ||
        to?.name === TRADE_NETWORK_ROUTE ||
        to?.name === E_WALLET ||
        to?.name === CLIENTS_ROUTE) &&
      (!activeUserWorkstation ||
        activeUserWorkstation?.verificationStatus ===
          UserVerificationStatusEnum.PENDING_VERIFICATION)
    ) {
      return next({ name: DASHBOARD_ROUTE });
    }
    next();
  }
);

export default router;
