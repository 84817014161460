import { ProjectState } from "@/core/models/project";

export default {
  setProjectDetails(state: ProjectState, payload: any) {
    state.projectDetails = { ...state.projectDetails, ...payload };
  },
  resetProjectDetails(state: ProjectState, payload: any) {
    state.projectDetails = payload;
  },

  setProjectAttachments(state: any, payload: any) {
    state.projectAttachments = payload;
  },

  setProjectSpecialisms(state: any, payload: any) {
    state.projectSpecialisms = payload;
  },

  addProjectSpecialism(state: any, payload: any) {
    // state.projectSpecialisms = payload;

    const isExist = state.projectSpecialisms.find(
      (specialism: any) => specialism.id === payload.id
    );

    if (!isExist) {
      state.projectSpecialisms.push(payload);
    }
  },
  removeProjectSpecialism(state: any, payload: any) {
    // const data = Object.values(state.projectSpecialisms) as any;
    state.projectSpecialisms = state.projectSpecialisms.filter(
      (specialism: any) => specialism.id !== payload.id
    );
  },
  setInvitedContractors(state: any, payload: any) {
    state.invitedContractors = payload;
  },

  setDraftProjectList(state: ProjectState, payload: any) {
    state.draftProjectList = payload;
  },
  setSelectedPropertiesList(state: ProjectState, payload: any) {
    state.selectedProperties = payload;
  },
  setPropertyListState(
    state: ProjectState,
    payload: { projectId: string; data: boolean }
  ) {
    state.propertyListState = payload;
  },
  setProjectRedirectTabName(state: ProjectState, payload: any) {
    state.projectRedirectTabName = payload;
  },
  setProjectEstimateDataWithEscrowStatus(state: any, payload: any) {
    state.projectEstimateWithEscrow = payload;
  },
  setHistoricalProjectForm(state: any, payload: any) {
    state.historicalProjectForm = payload;
  },

  setHistoricalProjectFormSpecialism(state: any, payload: any) {
    state.historicalProjectForm.specialisms = payload;
  },

  setProjectEstimateEscrowBalance(state: any, payload: any) {
    state.projectEstimateEscrowBalance = payload;
  },

  setProjectRequestedList(state: any, payload: any) {
    state.projectRequestedList = payload;
  },
  setProjectStatusCountsList(state: any, payload: any) {
    state.projectStatusCountsList = payload;
  },
  resetestimatedata(state: any) {
    state.projectEstimateWithEscrow = null;
  },
  setRateCategories(state: any, payload: any) {
    state.rateCategories = payload;
  },
  setAllProjects(state: any, payload: any) {
    state.allProjects = payload;
  },
  appendAllProjects(state: any, payload: any) {
    state.allProjects.push(...payload);
  },
  setTotalProjects(state: any, payload: any) {
    state.totalProjects = payload;
  },
  setTotalPages(state: any, payload: any) {
    state.totalPages = payload;
  },
  setTotalDraftProjects(state: any, payload: any) {
    state.totalDraftProjects = payload;
  },
  setTotalCompletedProjects(state: any, payload: any) {
    state.totalCompletedProjects = payload;
  },
  setTotalDraftProjectPages(state: any, payload: any) {
    state.totalDraftProjectPages = payload;
  },
  setTotalCompletedProjectPages(state: any, payload: any) {
    state.totalCompletedProjectPages = payload;
  },
  setDraftProjects(state: any, payload: any) {
    state.draftProjects = payload;
  },
  appendDraftProjects(state: any, payload: any) {
    state.draftProjects.push(...payload);
  },
  setCompletedProjects(state: any, payload: any) {
    state.completedProjects = payload;
  },
  appendCompletedProjects(state: any, payload: any) {
    state.completedProjects.push(...payload);
  },
  setProjectDispute(state: any, payload: any) {
    state.projectDispute = payload;
  },
  updateProjectDispute(state: any, payload: any) {
    state.projectDispute = {
      ...state.projectDispute,
      extension_request: payload.extensionRequest,
    };
  },
};
