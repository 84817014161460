<template>
  <div>
    <CommonDialog
      :isShowHeader="false"
      :isShowBackButton="false"
      width="420px"
      max-width="100%"
      height="auto"
      :className="['restricted__modal_box']"
    >
      <template v-slot:body>
        <div
          class="dashboard-welcome__close !tw-top-8 !tw-right-8"
          @click="onClickCloseButton($event)"
        >
          <v-icon
            icon="mdi-close"
            class="!tw-text-[12px] tw-cursor-pointer"
            color="#0C0F4A"
          ></v-icon>
        </div>
        <div
          class="tw-w-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-4"
        >
          <Text variant="h4" textWeight="400" lineHeight="30px"
            >Your invitation has<br />
            been sent</Text
          >

          <Text variant="h6" textWeight="400"
            >We've sent your invitation to:</Text
          >
        </div>

        <ul class="header-sub-title tw-mt-1 tw-flex tw-flex-col tw-gap-[.3rem]">
          <li v-for="(member, index) in members" :key="index">
            <b>{{ member?.email }}</b>
          </li>
        </ul>
        <div class="welcome__image !tw-mt-[30px]">
          <img src="../../assets/images/invite-member-sent.png" alt="" />
        </div>
        <div class="modal__footer !tw-mt-4">
          <Button
            label="Back to trade network"
            variant="accent"
            @click="onClickCloseButton($event)"
          />
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup lang="ts">
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import CommonDialog from "./CommonDialog.vue";
import { useStore } from "vuex";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";

const store = useStore();
const props = defineProps({
  members: {
    type: Array,
    required: true,
  },
  isExternalTradeNetworkRequest: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onClose"]);

const onClickCloseButton = (event: any) => {
  if (props.isExternalTradeNetworkRequest) {
    store.commit(`${TRADE_NETWORK}/setIsExternalTradeRequestSuccess`, true);
  }
  emit("onClose", true, event);
};
</script>

<style lang="scss" scoped></style>
